import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { useConnection } from '../../hooks/useConnection';
import { Player } from '../../types/matchmaking';
import PlayerAvatar from './card/PlayerAvatar';
import PlayerInfo from './card/PlayerInfo';
import SportsGrid from './card/SportsGrid';
import ActionButtons from './card/ActionButtons';
import RequestMatchModal from './RequestMatchModal';
import MutualConnectionsModal from '../members/modals/MutualConnectionsModal';

interface PlayerCardProps {
  player: Player;
  sports: string[];
  dates: string[];
  timeSlots: string[];
  distance: number;
  onMatchSelect: (match: any) => void;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  player,
  sports,
  dates,
  timeSlots,
  distance,
  onMatchSelect,
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showMutuals, setShowMutuals] = useState(false);
  const { connect } = useConnection();

  const handleProfileClick = () => {
    navigate(`/dashboard/members/${player.id}`);
  };

  const handleDisconnect = async () => {
    await new Promise(resolve => setTimeout(resolve, 1500));
  };

  const handleMessageClick = () => {
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: player.id,
        initialMessage: `Hi ${player.name}!`,
        showMobileList: false
      }
    });
  };

  const handlePlayClick = () => {
    setShowMatchModal(true);
  };

  return (
    <>
      <div className={cn(
        "rounded-xl p-6 transition-all duration-300",
        "hover:transform hover:scale-[1.02]",
        isDarkMode 
          ? "bg-dark-200/95 backdrop-blur-sm border-dark-400 hover:border-gold-400" 
          : "bg-white/95 backdrop-blur-sm border-gray-200 hover:border-gold-600",
        "border shadow-lg hover:shadow-xl"
      )}>
        <div className="flex flex-col sm:flex-row gap-4">
          {/* Profile Image & Rating */}
          <PlayerAvatar
            image={player.image}
            name={player.name}
            rating={player.rating}
            onClick={handleProfileClick}
            invitedBy={player.invitedBy}
          />

          {/* Member Info */}
          <div className="flex-1 min-w-0">
            <PlayerInfo
              name={player.name}
              location={player.location}
              distance={player.distance}
              company={player.company}
              matchPercentage={player.matchPercentage}
              mutualConnections={player.mutualConnections}
              onClick={handleProfileClick}
              onShowMutuals={() => setShowMutuals(true)}
              linkedinUrl={player.linkedinUrl}
              facebookUrl={player.facebookUrl}
              instagramUrl={player.instagramUrl}
            />

            {/* Sports Grid */}
            <SportsGrid sports={player.sports} />

            {/* Action Buttons */}
            <ActionButtons
              isConnected={player.isConnected || false}
              memberId={player.id}
              memberName={player.name}
              memberImage={player.image}
              skillLevel={player.skillLevel}
              onDisconnect={handleDisconnect}
              onMessage={handleMessageClick}
              onPlay={handlePlayClick}
            />
          </div>
        </div>
      </div>

      {/* Modals */}
      {showMatchModal && (
        <RequestMatchModal
          isOpen={showMatchModal}
          onClose={() => setShowMatchModal(false)}
          member={{
            id: player.id,
            name: player.name,
            city: player.location,
            sports: player.sports
          }}
          dates={[new Date().toISOString().split('T')[0]]}
          timeSlots={['morning', 'afternoon', 'evening']}
          distance={player.distance}
          onMatchSelect={() => onMatchSelect(player)}
        />
      )}

      {showMutuals && (
        <MutualConnectionsModal
          isOpen={true}
          onClose={() => setShowMutuals(false)}
          connectionName={player.name}
          mutualCount={player.mutualConnections || 0}
        />
      )}
    </>
  );
};

export default PlayerCard;