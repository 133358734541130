import React, { useState, useEffect } from "react";
import { Star } from "lucide-react";
import { supabase } from "@/lib/supabase";
import CitiesPlayedModal from "@/components/members/CitiesPlayedModal";
import { Member } from "../../../types/member";

interface ProfileStatsProps {
  member: Member;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowConnections,
  onShowReviews
}) => {
  const [matchesPlayed, setMatchesPlayed] = useState<number>(0);
  const [citiesPlayed, setCitiesPlayed] = useState<number>(0);
  const [citiesList, setCitiesList] = useState<string[]>([]);
  const [isLoadingMatches, setIsLoadingMatches] = useState<boolean>(true);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchStats = async () => {
      if (!member.id) return;

      try {
        setIsLoadingMatches(true);
        setIsLoadingCities(true);

        console.log("Fetching stats for member:", member.id);

        // Step 1: Get the internal user ID from Supabase
        const { data: userData, error: userError } = await supabase
          .from("users")
          .select("id")
          .eq("auth_id", member.id)
          .single();

        if (userError || !userData) {
          console.error("Error fetching user ID:", userError);
          return;
        }

        const internalUserId = userData.id;
        console.log("Internal user ID:", internalUserId);

        // Step 2: Query the activities table for past confirmed matches
        const { count, error: matchError } = await supabase
          .from("activities")
          .select("*", { count: "exact", head: true })
          .or(`player_1_id.eq.${internalUserId},player_2_id.eq.${internalUserId}`)
          .lt("date", new Date().toISOString().split("T")[0])
          .eq("status", "confirmed");

        if (matchError) {
          console.error("Error fetching matches played:", matchError);
          return;
        }

        console.log("Match count:", count);
        setMatchesPlayed(count || 0);

        // Step 3: Query the unique cities from venue_city column
        const { data: cityData, error: cityError } = await supabase
          .from("activities")
          .select("venue_city")
          .or(`player_1_id.eq.${internalUserId},player_2_id.eq.${internalUserId}`)
          .lt("date", new Date().toISOString().split("T")[0])
          .eq("status", "confirmed")
          .neq("venue_city", null);

        if (cityError) {
          console.error("Error fetching cities played:", cityError);
          return;
        }

        console.log("Raw city data response:", cityData);

        // Extract unique cities (first word before comma)
        const uniqueCities = new Set(
          cityData
            .map((activity) => activity.venue_city?.split(",")[0].trim())
            .filter((city) => city)
        );

        console.log("Unique Cities Played:", Array.from(uniqueCities));
        setCitiesPlayed(uniqueCities.size);
        setCitiesList(Array.from(uniqueCities));

      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setIsLoadingMatches(false);
        setIsLoadingCities(false);
      }
    };

    fetchStats();
  }, [member.id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {/* Matches Played */}
      <div className="bg-dark-300 rounded-xl p-4 text-center">
        <div className="text-2xl font-bold text-gold-400">
          {isLoadingMatches ? "..." : matchesPlayed}
        </div>
        <div className="text-dark-800 text-sm mt-1">Matches Played</div>
      </div>

      {/* Cities Played */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="text-2xl font-bold text-gold-400">
          {isLoadingCities ? "..." : citiesPlayed}
        </div>
        <div className="text-dark-800 text-sm mt-1">Cities Played</div>
      </button>

      {/* Connections */}
      <button
        onClick={onShowConnections}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="text-2xl font-bold text-gold-400">{member.connections}</div>
        <div className="text-dark-800 text-sm mt-1">Connections</div>
      </button>

      {/* Reviews & Ratings */}
      <button
        onClick={onShowReviews}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="flex items-center justify-center text-2xl font-bold text-gold-400">
          {member.rating}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className="text-dark-800 text-sm mt-1">
          {member.isTrainer ? `${member.trainerInfo?.reviews || 0} Reviews` : "Rating"}
        </div>
      </button>

      {/* Cities Played Modal */}
      <CitiesPlayedModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cities={citiesList}
        memberName={member.name}
      />
    </div>
  );
};

export default ProfileStats;
