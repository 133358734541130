import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { UserCircle, Camera } from 'lucide-react';
import { OnboardingStepProps } from '../types';
import { useProfileForm } from './profile/hooks/useProfileForm';
import { cn } from '@/lib/utils';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { Input } from '../../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { CitySearchCombobox } from '../../ui/combobox/CitySearchCombobox';
import { ProfileImageUpload } from './profile/components/ProfileImageUpload';

const ProfileStep: React.FC<OnboardingStepProps> = ({
  data,
  onUpdate,
  onNext,
  onBack
}) => {
  const {
    formData,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setTouched,
  } = useProfileForm({
    initialData: data,
    onUpdate,
    onNext
  });

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form using the form handler
    await handleSubmit();
    // Parent form data is updated via onUpdate in useProfileForm
  };

  return (
    <div className="h-full flex flex-col">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="flex flex-col max-w-xl mx-auto w-full px-2 sm:px-4 pt-4 sm:pt-6 pb-20 sm:pb-24 overflow-y-auto relative"
      >
        {/* Centered Header */}
        <div className="text-center mb-8">
          <motion.h2 
            className={cn(
              "text-xl xs:text-2xl sm:text-3xl md:text-4xl font-bold",
              "text-transparent bg-clip-text",
              "bg-gradient-to-r from-white via-gold-200 to-gold-400",
              "mb-2"
            )}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Create Your Profile
          </motion.h2>
          <motion.p 
            className="text-dark-800 text-xs sm:text-sm md:text-base"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            Let's get to know you better
          </motion.p>
        </div>

        <form onSubmit={handleFormSubmit} className="space-y-6">
          <motion.div 
            className={cn(
              "bg-dark-300/30 backdrop-blur-sm rounded-2xl p-6 relative",
              "border border-dark-400/30 hover:border-gold-400/20",
              "transition-all duration-300 shadow-lg hover:shadow-xl",
              "flex flex-col sm:flex-row gap-6"
            )}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            {/* Profile Image */}
            <div 
              className={cn(
                "flex-shrink-0",
                "sm:w-40 sm:border-r sm:border-dark-400/30 sm:pr-6",
                "pb-6 sm:pb-0 border-b sm:border-b-0 border-dark-400/30",
                "flex justify-center"
              )}
            >
              <ProfileImageUpload
                value={formData.profilePic}
                onChange={(value) => handleChange('profilePic', value)}
                className="w-28 h-28 sm:w-32 sm:h-32 rounded-full"
              />
             </div>

            {/* Input Fields */}
            <div className="flex-1 space-y-4 min-w-0">
            <Input
              value={formData.fullName || ''}
              onChange={(e) => handleChange('fullName', e.target.value)}
              onBlur={() => handleBlur('fullName')}
              className={cn(
                "bg-dark-300/50 border-dark-400/30 text-white",
                "h-11 sm:h-12 text-sm sm:text-base",
                "rounded-xl",
                "placeholder:text-dark-800/50",
                "hover:border-gold-400/30 focus:border-gold-400",
                "transition-all duration-300",
                "shadow-sm hover:shadow-md",
                errors.fullName && touched.fullName && "border-red-400"
              )}
              placeholder="Full Name"
            />

            <Input
              type="email"
              value={formData.email || ''}
              onChange={(e) => handleChange('email', e.target.value)}
              onBlur={() => handleBlur('email')}
              className={cn(
                "bg-dark-300/50 border-dark-400/30 text-white",
                "h-11 sm:h-12 text-sm sm:text-base",
                "rounded-xl",
                "placeholder:text-dark-800/50",
                "hover:border-gold-400/30 focus:border-gold-400",
                "transition-all duration-300",
                "shadow-sm hover:shadow-md",
                errors.email && touched.email && "border-red-400"
              )}
              placeholder="Email Address"
            />

            <Input
              value={formData.company || ''}
              onChange={(e) => handleChange('company', e.target.value)}
              onBlur={() => handleBlur('company')}
              className={cn(
                "bg-dark-300/50 border-dark-400/30 text-white",
                "h-11 sm:h-12 text-sm sm:text-base",
                "rounded-xl",
                "placeholder:text-dark-800/50",
                "hover:border-gold-400/30 focus:border-gold-400",
                "transition-all duration-300",
                "shadow-sm hover:shadow-md",
                errors.company && touched.company && "border-red-400"
              )}
              placeholder="Company"
            />

            <Input
              value={formData.jobTitle || ''}
              onChange={(e) => handleChange('jobTitle', e.target.value)}
              onBlur={() => handleBlur('jobTitle')}
              className={cn(
                "bg-dark-300/50 border-dark-400/30 text-white",
                "h-11 sm:h-12 text-sm sm:text-base",
                "rounded-xl",
                "placeholder:text-dark-800/50",
                "hover:border-gold-400/30 focus:border-gold-400",
                "transition-all duration-300",
                "shadow-sm hover:shadow-md",
                errors.jobTitle && touched.jobTitle && "border-red-400"
              )}
              placeholder="Role"
            />
            <CitySearchCombobox
              value={formData.city || ''}
              onChange={(city) => handleChange('city', city)}
              error={errors.city && touched.city}
              placeholder="Your City"
              className={cn(
                "[&_input]:h-12 [&_input]:text-base",
                "[&_input]:h-11 [&_input]:sm:h-12 [&_input]:text-sm [&_input]:sm:text-base",
                "[&_input]:rounded-xl",
                "[&_input]:bg-dark-300/50 [&_input]:border-dark-400/50",
                "[&_input]:placeholder:text-dark-800/50",
                "[&_input:hover]:border-gold-400/30 [&_input:focus]:border-gold-400",
                "[&_input]:shadow-sm [&_input:hover]:shadow-md",
                "[&_input]:transition-all [&_input]:duration-300"
              )}
            />

            <Select
              value={formData.gender || ''}
              onValueChange={(value) => handleChange('gender', value)}
            >
              <SelectTrigger 
                className={cn(
                  "bg-dark-300/50 border-dark-400/30 text-white",
                  "h-11 sm:h-12 text-sm sm:text-base",
                  "rounded-xl",
                  "hover:border-gold-400/30 focus:border-gold-400",
                  "transition-all duration-300",
                  "shadow-sm hover:shadow-md",
                  errors.gender && touched.gender && "border-red-400"
                )}
              >
                <SelectValue placeholder="Select Gender" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
                <SelectItem value="rather_not_say">Rather not say</SelectItem>
              </SelectContent>
            </Select>
            </div>
          </motion.div>
        </form>
      </motion.div>
      <FloatingNavigation 
        onBack={onBack}
        onNext={handleSubmit}
        isNextDisabled={!formData.fullName || !formData.email || !formData.city || !formData.gender}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

export default ProfileStep;