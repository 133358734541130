import React from 'react';
import { Star, Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface RateActivityButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  className?: string;
}

const RateActivityButton: React.FC<RateActivityButtonProps> = ({ onClick, isLoading, className }) => (
  <button
    onClick={onClick}
    disabled={isLoading}
    className={cn(
      "flex items-center justify-center gap-2 h-10",
      "bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors",
      "text-sm font-medium whitespace-nowrap",
      "px-2 sm:px-4",
      "transform active:scale-95 transition-all",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      className
    )}
  >
    {isLoading ? (
      <>
        <Loader2 className="h-4 w-4 animate-spin mr-2" />
        <span>Rating...</span>
      </>
    ) : (
      <>
        <Star className="h-4 w-4" />
        <span className="hidden sm:inline">Rate Activity</span>
        <span className="sm:hidden">Rate</span>
      </>
    )}
  </button>
);

export default RateActivityButton;