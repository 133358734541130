// Activity emojis mapping
export const ACTIVITY_EMOJIS: Record<string, string> = {
  'tennis': '🎾',
  'golf': '⛳',
  'swimming': '🏊‍♂️',
  'fitness': '💪',
  'yoga': '🧘‍♀️',
  'pilates': '🧘‍♂️',
  'football': '⚽',
  'padel': '🎾',
  'basketball': '🏀',
  'badminton': '🏸',
  'volleyball': '🏐',
  'table tennis': '🏓',
  'squash': '🎾',
  'running': '🏃‍♂️',
  'cycling': '🚴‍♂️',
  'gym': '🏋️‍♂️',
  'bowling':'🎳',
  'training': '🎯',
  'martial arts': '🥋',
  'boxing': '🥊',
  'dance': '💃',
  'meditation': '🧘',
  'crossfit': '💪',
  'surfing': '🏄‍♂️',
  'pickleball': '🎾',
  'karate': '🥋',
  'taekwondo': '🥋',
  'aikido': '🥋',
  'horse riding': '🐎',
  'polo': '🏇',
  'athletic': '🏃',
  'futsal': '⚽',
  'billiard': '🎱',
  'billiards': '🎱',
  'sailing': '⛵',
  'wakeboarding': '🏄‍♂️',
  'wakesurfing': '🏄‍♂️', 
  'pool': '🎱',
  'aerobics': '💃',
  'zumba': '💃',
  'equestrian': '🐎',
  'climbing': '🧗‍♂️',
  'aquatics': '🏊‍♂️',
  'cricket': '🏏',
  'hockey': '🏑',
  'spa': '💆‍♂️',
  'netball': '🏐',
  'rugby': '🏉',
  'softball': '🥎',
  'trekking': '🥾',
  'sea sports': '🛥️',
  'weight training': '🏋️‍♀️',
  'baseball': '⚾',
  'american football': '🏈',
  'athletics': '🏃',
  'skiing': '⛷️',
  'snowboarding': '🏂',
  'horse racing': '🏇'
} as const;