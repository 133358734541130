import React, { useState } from 'react';
import { Check, Reply, Trash2, Edit2, CheckCheck } from 'lucide-react';
import type { Message } from '../../types/chat';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { useTheme } from '@/contexts/ThemeContext';

interface MessageGroupProps {
  date: string;
  messages: Message[];
  onDelete?: (messageId: string) => void;
  onEdit?: (messageId: string, newContent: string) => void;
  onReply?: (message: Message) => void;
  editingId?: string | null;
}

const MessageGroup: React.FC<MessageGroupProps> = ({
  date,
  messages,
  onDelete,
  onEdit,
  onReply,
  editingId
}) => {
  const { isDarkMode } = useTheme();
  const [editContent, setEditContent] = useState('');

  // Add console log when messages change
  React.useEffect(() => {
    console.log('MessageGroup:', {
      componentName: 'MessageGroup',
      date,
      messagesCount: messages?.length || 0,
      messages: messages?.map(m => ({
        id: m.id,
        content: m.content,
        time: m.time,
        isSender: m.isSender,
        status: m.status
      }))
    });
  }, [date, messages]);
  
  // Add console log after data is initialized
  React.useEffect(() => {
    console.log('MessageGroup:', {
      componentName: 'MessageGroup',
      date,
      messagesInGroup: messages.length,
      messages
    });
  }, [date, messages]);

  const handleStartEdit = (message: Message) => {
    setEditContent(message.content);
  };

  const handleSaveEdit = (messageId: string) => {
    if (editContent.trim() && onEdit) {
      onEdit(messageId, editContent);
    }
  };

  const getStatusIcon = (status?: 'sent' | 'delivered' | 'read') => {
    switch (status) {
      case 'sent':
        return <Check className="h-3 w-3 text-dark-800/70" />;
      case 'delivered':
        return (
          <CheckCheck className="h-3 w-3 text-dark-800/70" />
        );
      case 'read':
        return (
          <CheckCheck className="h-3 w-3 text-gold-400" />
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-3">
     <div className="flex items-center justify-center my-3">
  <div className={cn(
    "px-4 py-1.5 rounded-full text-xs font-medium",
    "shadow-sm backdrop-blur-sm",
    isDarkMode 
      ? "bg-dark-300/70 text-dark-800 border border-dark-400/30"  
      : "bg-light-200/70 text-light-900 border border-light-300/30"
  )}>
    {date}
  </div>
</div>


      {messages.map((message) => (
        <div
          key={message.id}
          className={cn(
            "flex items-end space-x-2",
            message.isSender ? 'justify-end' : 'justify-start',
            "mb-3 last:mb-0"
          )}
        >

          <div className="group relative max-w-[85%] sm:max-w-[70%] break-words">
            {message.replyTo && (
              <div className={cn(
                "mb-2 p-2 rounded-lg text-sm",
                message.isSender 
                  ? "bg-gold-500/20 text-gold-400" 
                  : "bg-dark-400/50 text-white backdrop-blur-sm"
              )}>
                <div className="flex items-center space-x-2 text-dark-800 mb-1">
                  <Reply className="h-3 w-3" />
                  <span className="text-xs">Reply to</span>
                </div>
                <div className="pl-5 break-words text-xs">{message.replyTo.content}</div>
              </div>
            )}

            <div className={cn(
              "px-4 py-2.5 max-w-prose",
              "transition-all duration-200",
              "shadow-sm hover:shadow-md",
              message.isSender
                ? [
                    "bg-gradient-to-br from-gold-400 to-gold-500 text-dark-50",
                    "rounded-t-2xl rounded-l-2xl",
                    "border border-gold-300/20"
                  ]
                : [
                    isDarkMode 
                      ? "bg-dark-300/95 text-white"
                      : "bg-light-200/95 text-light-900",
                    "rounded-t-2xl rounded-r-2xl",
                    isDarkMode
                      ? "border border-dark-400/30"
                      : "border border-light-300/30",
                    "backdrop-blur-sm"
                  ]
            )}>
              {editingId === message.id ? (
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    className="flex-1 bg-dark-400 rounded-lg px-2 py-1 text-white text-sm"
                    autoFocus
                  />
                  <button
                    onClick={() => handleSaveEdit(message.id)}
                    className="p-1 hover:bg-dark-500 rounded-lg"
                  >
                    <Check className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <>
                  <p className="break-words whitespace-pre-wrap text-sm leading-relaxed">
                    {message.content}
                  </p>
                  {message.image && (
                    <img
                      src={message.image}
                      alt="Message attachment"
                      className="mt-2 rounded-lg max-w-full"
                    />
                  )}
                </>
              )}
              <div className="flex items-center justify-end space-x-2 mt-1">
                <span className={cn(
                  "text-xs",
                  message.isSender
                    ? "text-dark-50/80"
                    : isDarkMode
                      ? "text-dark-800"
                      : "text-light-800",
                  "transition-colors"
                )}>
                  {format(typeof message.time === 'string' ? new Date(message.time) : message.time, 'HH:mm')}
                </span>
                {message.isSender && getStatusIcon(message.status)}
              </div>
            </div>

            <div className={cn(
              "absolute top-0 -translate-y-1/2",
              "opacity-0 group-hover:opacity-100 transition-all duration-200",
              message.isSender ? "-left-2" : "-right-2",
              "z-10"
            )}>
              <div className={cn(
                "flex items-center space-x-1",
                "bg-dark-300/95 rounded-lg p-1",
                "shadow-lg backdrop-blur-sm",
                "border border-dark-400/30"
              )}>
                {onReply && (
                  <button
                    onClick={() => onReply(message)}
                    className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-white transition-colors"
                  >
                    <Reply className="h-4 w-4" />
                  </button>
                )}
                {message.isSender && onEdit && (
                  <button
                    onClick={() => {
                      handleStartEdit(message);
                      onEdit(message.id, message.content);
                    }}
                    className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-white transition-colors"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                )}
                {message.isSender && onDelete && (
                  <button
                    onClick={() => onDelete(message.id)}
                    className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-red-400 transition-colors"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageGroup;