import React from 'react';
import * as Icons from 'lucide-react';
import { cn } from '@/lib/utils';

interface BottomNavItemProps {
  label: string;
  icon: string;
  isActive?: boolean;
  count?: number;
  onClick: () => void;
  isSpecial?: boolean;
  className?: string;
}

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  isActive,
  count,
  onClick,
  isSpecial,
  className
}) => {
  const Icon = Icons[icon as keyof typeof Icons];

  return (
    <button
      onClick={onClick}
      className={cn(
        "relative flex flex-col items-center justify-center w-16 py-1",
        isActive ? "text-gold-400" : "text-dark-800 hover:text-white",
        className
      )}
    >
      <div className="relative">
        {isSpecial ? (
          <div className="w-14 h-14 bg-gold-400 rounded-full shadow-lg flex items-center justify-center hover:bg-gold-500 transition-all duration-300 hover:scale-110">
            <Icon className="h-7 w-7 text-dark-50" />
          </div>
        ) : (
          <Icon className="h-6 w-6 mb-1" />
        )}
        {/* Main count indicator */}
        {typeof count === 'number' && count > 0 && !isSpecial && (
          <span className={cn(
            "absolute -top-1 -right-1 min-w-[18px] h-[18px]",
            "flex items-center justify-center",
            "bg-gold-400 text-dark-50 text-[10px] font-medium rounded-full",
            "px-1"
          )}>
            {count}
          </span>
        )}
      </div>
      <span className="text-[10px] leading-none mt-1">{label}</span>
    </button>
  );
};

export default BottomNavItem;