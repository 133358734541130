import React from 'react';
import { MessageCircle, Plus } from 'lucide-react';
import ConnectionButton from '../ConnectionButton';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface ActionButtonsProps {
  memberId: string;
  memberName: string;
  memberImage: string;
  skillLevel: string;
  onMessage: () => void;
  onPlay: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  memberId,
  memberName,
  memberImage,
  skillLevel,
  onMessage,
  onPlay,
}) => {
  const { isDarkMode } = useTheme();

  const buttonBaseClasses =
    'h-10 px-4 text-sm font-medium flex items-center justify-center space-x-2 rounded-lg transition-colors min-w-[100px]';

  return (
    <div
      className={cn(
        "flex flex-col xs:flex-row items-center gap-4 mt-6 pt-4",
        isDarkMode ? "border-t border-dark-300" : "border-t border-gray-200"
      )}
    >
      <div className="flex items-center gap-2 w-full xs:w-auto order-2 xs:order-1">
        <ConnectionButton
          memberId={memberId}
          memberName={memberName}
          memberImage={memberImage}
          className={cn(
            buttonBaseClasses,
            "text-black dark:text-black",
            "shadow-md hover:shadow-lg",
            "transform hover:scale-105 active:scale-95"
          )}
        />
        <button
          onClick={onPlay}
          className={cn(
            buttonBaseClasses,
            "bg-gold-400 hover:bg-gold-500 text-dark-50",
            "shadow-md hover:shadow-lg",
            "transform hover:scale-105 active:scale-95"
          )}
        >
          <Plus className="h-4 w-4" />
          <span>Play</span>
        </button>
        <button
          onClick={onMessage}
          className={cn(
            'h-10 w-10 flex items-center justify-center rounded-lg',
            'transition-all duration-200 flex-shrink-0',
            'shadow-md hover:shadow-lg',
            'transform hover:scale-105 active:scale-95',
            isDarkMode
              ? 'bg-dark-300 hover:bg-dark-400 text-white'
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
          )}
        >
          <MessageCircle className="h-5 w-5" />
        </button>
      </div>
      <div
        className={cn(
          "flex items-center order-1 xs:order-2 xs:ml-auto",
          isDarkMode ? "text-dark-800" : "text-gray-500"
        )}
      >
        <span className="text-sm font-medium">{skillLevel}</span>
      </div>
    </div>
  );
};

export default ActionButtons;