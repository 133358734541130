import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, Check, X, UserPlus2 } from 'lucide-react';
import { useConnectionStore } from '@/store/connectionStore';
import { cn } from '@/lib/utils';

const PendingConnectionsList = ({ connections }) => {
  const { acceptRequest, declineRequest, cancelRequest } = useConnectionStore();
  const navigate = useNavigate();
  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  if (!connections.length) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <UserPlus2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Pending Requests</h3>
        <p className="text-dark-800 max-w-sm mx-auto">
          You don't have any pending connection requests at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {connections.map((connection) => (
        <div
          key={connection.connection_id}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
        >
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
            {/* Profile Image */}
            <button
              onClick={() => {
                if (connection.other_user?.auth_id) {
                  navigate(`/dashboard/members/${connection.other_user.auth_id}`);
                }
              }}
              className="relative group"
            >
              <img
                src={connection.other_user?.avatar_url || defaultAvatar}
                alt={connection.other_user?.full_name || 'User'}
                className="w-20 h-20 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
              />
            </button>

            {/* Info and Actions */}
            <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
              <div className="text-center sm:text-left">
                <button
                  onClick={() => {
                    if (connection.other_user?.auth_id) {
                      navigate(`/dashboard/members/${connection.other_user.auth_id}`);
                    }
                  }}
                  className="font-medium hover:text-gold-400 transition-colors text-white"
                >
                  {connection.other_user?.full_name}
                </button>
                <div className="flex items-center justify-center sm:justify-start text-dark-800 mt-1">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>Request {connection.is_initiator ? 'sent' : 'received'} {new Date(connection.created_at).toLocaleDateString()}</span>
                </div>
              </div>

              <div className="flex items-center gap-2 sm:gap-3">
                {connection.is_initiator ? (
                  <button
                    onClick={() => cancelRequest(connection.connection_id)}
                    className={cn(
                      "p-2 rounded-lg transition-all duration-200",
                      "bg-red-500/10 hover:bg-red-500 text-red-500 hover:text-white",
                      "border border-red-500/20 hover:border-red-500",
                      "hover:scale-105 active:scale-95",
                      "sm:px-4 sm:py-2 flex items-center gap-2"
                    )}
                  >
                    <X className="h-5 w-5" />
                    Cancel Request
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => acceptRequest(connection.connection_id)}
                      className={cn(
                        "p-2 rounded-lg transition-all duration-200",
                        "bg-green-500/10 hover:bg-green-500 text-green-500 hover:text-white",
                        "border border-green-500/20 hover:border-green-500",
                        "hover:scale-105 active:scale-95",
                        "sm:px-4 sm:py-2 flex items-center gap-2"
                      )}
                    >
                      <Check className="h-5 w-5 sm:mr-2" />
                      <span className="hidden sm:inline">Accept</span>
                    </button>
                    <button
                      onClick={() => declineRequest(connection.connection_id)}
                      className={cn(
                        "p-2 rounded-lg transition-all duration-200",
                        "bg-red-500/10 hover:bg-red-500 text-red-500 hover:text-white",
                        "border border-red-500/20 hover:border-red-500",
                        "hover:scale-105 active:scale-95",
                        "sm:px-4 sm:py-2 flex items-center gap-2"
                      )}
                    >
                      <X className="h-5 w-5 sm:mr-2" />
                      <span className="hidden sm:inline">Decline</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PendingConnectionsList;