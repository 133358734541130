import { create } from 'zustand';
import { Activity } from '../types/activity';
import { getActivities, updateActivityStatus, acceptActivity, declineActivity, rateActivity } from '../lib/supabase/activities';

interface ActivityState {
  activities: Activity[];
  isLoading: boolean;
  error: string | null;
  lastFetched: number | null;
  fetchActivities: () => Promise<void>;
  updateActivity: (activityId: string, updates: Partial<Activity>) => Promise<void>;
  acceptActivityRequest: (activityId: string) => Promise<void>;
  declineActivityRequest: (activityId: string, reason?: string) => Promise<void>;
  rateActivityRequest: (
    activityId: string,
    rating: number,
    feedback: string,
    categories: any
  ) => Promise<void>;
}

export const useActivityStore = create<ActivityState>((set, get) => ({
  activities: [],
  isLoading: false,
  error: null,
  lastFetched: null,

  fetchActivities: async () => {
    const CACHE_DURATION = 30000; // 30 seconds
    const now = Date.now();
    const lastFetched = get().lastFetched;

    // Use cache if available and fresh
    if (lastFetched && now - lastFetched < CACHE_DURATION) {
      return;
    }

    set({ isLoading: true, error: null });

    try {
      const activities = await getActivities();
      set({ 
        activities, 
        lastFetched: now,
        isLoading: false 
      });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to fetch activities',
        isLoading: false 
      });
    }
  },

  updateActivity: async (activityId, updates) => {
    try {
      // Optimistically update local state
      set(state => ({
        activities: state.activities.map(activity =>
          activity.id === activityId
            ? { ...activity, ...updates }
            : activity
        )
      }));
      await updateActivityStatus(activityId, updates);

      // Refresh activities to ensure consistency
      get().fetchActivities();
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to update activity' });
      // Revert optimistic update on error
      get().fetchActivities();
    }
  },

  acceptActivityRequest: async (activityId) => {
    try {
      // Clear lastFetched to force a fresh fetch
      set({ lastFetched: null });

      // Optimistically update local state first
      set(state => ({
        activities: state.activities.map(activity =>
          activity.id === activityId
            ? { 
                ...activity, 
                status: 'confirmed',
                direction: undefined // Clear direction after confirmation
              }
            : activity
        )
      }));

      // Make API call
      await acceptActivity(activityId);
      
      // Force immediate refresh
      await get().fetchActivities();

    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to accept activity' });
      // Revert optimistic update on error
      get().fetchActivities();
    }
  },

  declineActivityRequest: async (activityId, reason) => {
    try {
      await declineActivity(activityId, reason);
      
      // Optimistically update local state
      set(state => ({
        activities: state.activities.map(activity =>
          activity.id === activityId
            ? { ...activity, status: 'declined' }
            : activity
        )
      }));

      // Refresh activities to ensure consistency
      get().fetchActivities();
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to decline activity' });
      // Revert optimistic update on error
      get().fetchActivities();
    }
  },

  rateActivityRequest: async (activityId, rating, feedback, categories) => {
    try {
      await rateActivity(activityId, rating, feedback, categories);
      
      // Optimistically update local state
      set(state => ({
        activities: state.activities.map(activity =>
          activity.id === activityId
            ? {
                ...activity,
                isRated: true,
                rating_overall: rating,
                rating_feedback: feedback,
                rating_categories: categories
              }
            : activity
        )
      }));

      // Refresh activities to ensure consistency
      get().fetchActivities();
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to rate activity' });
      // Revert optimistic update on error
      get().fetchActivities();
    }
  }
}));