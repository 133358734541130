// Cache configuration
export const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache
export const STALE_WHILE_REVALIDATE = 10 * 60 * 1000; // 10 minutes stale data

// Memory cache for activities
export const activitiesCache = new Map<string, {
  data: any[];
  timestamp: number;
  isStale: boolean;
}>();

// Cache management functions
export function getCachedActivities(userId: string): any[] | null {
  const cached = activitiesCache.get(userId);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  if (age < STALE_WHILE_REVALIDATE) {
    cached.isStale = true;
    return cached.data;
  }

  activitiesCache.delete(userId);
  return null;
}

export function setCachedActivities(userId: string, data: any[]) {
  activitiesCache.set(userId, {
    data,
    timestamp: Date.now(),
    isStale: false
  });

  // Broadcast cache invalidation to other tabs
  try {
    window.localStorage.setItem('activities-cache-invalidated', Date.now().toString());
  } catch (err) {
    console.warn('Failed to broadcast cache invalidation:', err);
  }
}

export function clearActivitiesCache() {
  activitiesCache.clear();
  // Broadcast cache invalidation
  try {
    window.localStorage.setItem('activities-cache-invalidated', Date.now().toString());
  } catch (err) {
    console.warn('Failed to broadcast cache invalidation:', err);
  }
}

// Helper function to check if cache is stale
export function isCacheStale(userId: string): boolean {
  const cached = activitiesCache.get(userId);
  if (!cached) return true;

  const now = Date.now();
  const age = now - cached.timestamp;
  return age >= CACHE_DURATION;
}