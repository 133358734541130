import { create } from 'zustand';

interface ActivityLoadingState {
  loadingStates: Record<string, {
    isAccepting: boolean;
    isDeclining: boolean;
    isRating: boolean;
  }>;
  setLoadingState: (activityId: string, type: 'accepting' | 'declining' | 'rating', isLoading: boolean) => void;
  resetLoadingState: (activityId: string) => void;
}

export const useActivityLoadingStore = create<ActivityLoadingState>((set) => ({
  loadingStates: {},
  
  setLoadingState: (activityId, type, isLoading) => 
    set((state) => ({
      loadingStates: {
        ...state.loadingStates,
        [activityId]: {
          ...state.loadingStates[activityId],
          [`is${type.charAt(0).toUpperCase() + type.slice(1)}`]: isLoading
        }
      }
    })),
    
  resetLoadingState: (activityId) =>
    set((state) => {
      const { [activityId]: _, ...rest } = state.loadingStates;
      return { loadingStates: rest };
    })
}));