import { supabase } from '../supabase';
import { Post } from '../../types/post';
import { storage } from './storage';
import { postsCache } from '../cache';
import { compressImage } from '../utils/image';
import { CACHE_CONFIG } from '../cache';

// Export all functions
export { getPosts, createPost, subscribeToNewPosts };

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const INITIAL_BATCH_SIZE = 5;
const SUBSEQUENT_BATCH_SIZE = 10;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Separate fields for initial and subsequent loads
const INITIAL_FIELDS = `
  id,
  content,
  media_url,
  likes_count,
  comments_count,
  shares_count,
  created_at,
  users (
    auth_id,
    full_name,
    avatar_url,
    city
  )
`;

async function loadPosts(lastPostId?: string, isInitialLoad = false): Promise<Post[]> {
  // Validate post ID format
  const isValidUUID = (id: string): boolean => {
    const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return UUID_REGEX.test(id);
  };

  // Return early if invalid ID
  if (lastPostId && !isValidUUID(lastPostId)) {
    console.warn('Invalid post ID format in loadPosts:', lastPostId);
    return [];
  }

  let query = supabase
    .from('posts')
    .select(INITIAL_FIELDS)
    .order('created_at', { ascending: false })
    .limit(isInitialLoad ? INITIAL_BATCH_SIZE : SUBSEQUENT_BATCH_SIZE);

  if (lastPostId) {
    const { data: lastPost } = await supabase
      .from('posts')
      .select('id, created_at')
      .eq('id', lastPostId)
      .single();
      
    if (lastPost?.created_at) {
      query = query.lt('created_at', lastPost.created_at);
    } else {
      console.warn('Last post not found:', lastPostId);
      return [];
    }
  }

  const { data: posts, error } = await query;

  if (error) throw error;
  
  // Deduplicate posts by ID
  const uniquePosts = posts ? Array.from(
    new Map(posts.map(post => [post.id, post])).values()
  ) : [];
  
  if (!uniquePosts.length) return [];
  
  console.log(`Loaded ${uniquePosts.length} posts (${isInitialLoad ? 'initial' : 'subsequent'} batch)`);
  
  return posts.map(post => ({
    id: post.id,
    author: {
      id: post.users.auth_id,
      name: post.users.full_name,
      image: post.users.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
      location: post.users.city
    },
    content: post.content,
    media: post.media_url,
    likes: post.likes_count,
    comments: post.comments_count,
    shares: post.shares_count,
    timeAgo: new Date(post.created_at).toISOString()
  }));
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    const isNetworkError = error instanceof Error && 
      error.message.toLowerCase().includes('failed to fetch');
    
    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }
    throw error;
  }
}

async function getPosts(lastPostId?: string): Promise<Post[]> {
  const handleError = (error: unknown, context: string) => {
    if (error instanceof Error && (
      error.message === 'Failed to fetch' ||
      error.message.toLowerCase().includes('network') ||
      error.message.toLowerCase().includes('timeout')
    )) {
      // Return cached data if available on network error
      const cached = postsCache.get('feed');
      if (cached) {
        return cached;
      }
      return []; // Return empty array for network errors
    }
    console.error(`${context}:`, { error, message: error instanceof Error ? error.message : 'Unknown error' });
    return []; // Return empty array for all errors to prevent UI disruption
  };

  try {
    // Check cache first
    const cacheKey = lastPostId ? `feed-${lastPostId}` : 'feed-initial';
    
    // Validate post ID format
    const isValidUUID = (id: string): boolean => {
      const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return UUID_REGEX.test(id);
    };

    // Return early if invalid ID
    if (lastPostId && !isValidUUID(lastPostId)) {
      console.warn('Invalid post ID format, skipping query:', lastPostId);
      return handleError(new Error('Invalid post ID format'), 'Post ID validation');
    }

    const cached = !lastPostId && postsCache.get(cacheKey);
    if (cached && cached.length > 0) {
      // Use cache while fetching fresh data
      setTimeout(() => {
        loadPosts(true).then(freshPosts => {
          if (freshPosts) {
            postsCache.set(cacheKey, freshPosts);
          }
        });
      }, 0);
      return cached; 
    }

    // Get current user session
    const { data: { user }, error: userError } = await fetchWithRetry(() => 
      supabase.auth.getUser()
    );

    if (userError) {
      return handleError(userError, 'Auth error');
    }
    if (!user) throw new Error('Not authenticated');

    // Get user's database record
    const { data: userData, error: userDataError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single()
    );

    if (userDataError) {
      return handleError(userDataError, 'User data error');
    }
    if (!userData) throw new Error('User profile not found');

    // Fetch initial batch of posts
    const transformedPosts = await loadPosts(lastPostId);

    // Cache the results
    if (!lastPostId) {
      postsCache.set('feed-initial', transformedPosts);
    }

    return transformedPosts;
  } catch (error) {
    return handleError(error, 'Error fetching posts');
  }
}

async function createPost(content: string, image?: File): Promise<Post> {
  try {
    // Get current user
    const { data: { session }, error: userError } = await supabase.auth.getSession();
    if (userError) throw userError;
    if (!session?.user) throw new Error('Not authenticated');

    const user = session.user;

    // Get user's database record first
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id, full_name, avatar_url, city')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User profile not found');

    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    let mediaUrl;
    if (image) {
      try {
        // Validate file size and type
        if (image.size > 5 * 1024 * 1024) {
          throw new Error('Image size must be less than 5MB');
        }

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(image.type)) {
          throw new Error('Only JPEG, PNG and GIF images are allowed');
        }

        // Compress image
        const compressedFile = await compressImage(image, {
          maxWidth: 2048,
          maxHeight: 2048,
          quality: 0.8
        });

        // Generate unique filename with user directory structure
        const timestamp = Date.now();
        const extension = image.name.split('.').pop()?.toLowerCase() || 'jpg';
        const sanitizedName = image.name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
        const filePath = `user_directory/${user.id}/${timestamp}-${sanitizedName}.${extension}`;

        console.log('Uploading file:', {
          bucket: 'social-feed',
          path: filePath,
          type: image.type,
          size: compressedFile.size
        });

        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('social-feed')
          .upload(filePath, compressedFile, {
            cacheControl: '3600',
            upsert: false,
            contentType: image.type,
            duplex: 'half',
            owner: user.id
          });

        if (uploadError) {
          console.error('Upload error:', uploadError);
          if (uploadError.message?.includes('Invalid file path')) {
            throw new Error('Storage path error - please try again');
          }
          throw uploadError;
        }

        // Get public URL
        const { data: { publicUrl } } = supabase.storage
          .from('social-feed')
          .getPublicUrl(filePath);

        mediaUrl = publicUrl;
      } catch (uploadError) {
        console.error('Error uploading image:', uploadError);
        throw new Error(
          uploadError instanceof Error 
            ? uploadError.message 
            : 'Failed to upload image'
        );
      }
    }

    // Create post with initial counts
    const { data, error } = await supabase
      .from('posts')
      .insert({
        user_id: userData.id,
        content: content.trim(),
        media_url: mediaUrl,
        likes_count: 0,
        comments_count: 0,
        shares_count: 0
      })
      .select(`
        id,
        content,
        media_url,
        likes_count,
        comments_count,
        shares_count,
        created_at,
        users (
          id,
          auth_id,
          full_name,
          avatar_url,
          city
        )
      `)
      .single();

    if (error) {
      // Clean up uploaded image if post creation fails
      if (mediaUrl) {
        try {
          // Extract file path from URL
          const filePath = `user_directory/${mediaUrl.split('/').slice(-2).join('/')}`;
          if (filePath) {
            await supabase.storage
              .from('social-feed')
              .remove([filePath]);
          }
        } catch (deleteError) {
          console.error('Error cleaning up image:', deleteError);
        }
      }
      throw error;
    }

    // Clear posts cache when new post is created
    postsCache.delete('feed');
    return {
      id: data.id,
      author: {
        id: data.users.auth_id,
        name: data.users.full_name,
        image: data.users.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        location: data.users.city
      },
      content: data.content,
      media: data.media_url,
      likes: data.likes_count,
      comments: data.comments_count,
      shares: data.shares_count,
      timeAgo: new Date(data.created_at).toISOString()
    };
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Failed to create post';
    console.error('Failed to create post:', message);
    throw new Error(message);
  }
}

function subscribeToNewPosts(callback: (post: Post) => void) {
  const subscription = supabase
    .channel('public:posts')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'posts'
      },
      async (payload) => {
        try {
          // Only process inserts and updates
          if (payload.eventType !== 'INSERT' && payload.eventType !== 'UPDATE') {
            return;
          }

          // Fetch complete post data
          const { data, error } = await supabase
            .from('posts')
            .select(`
              id,
              content,
              media_url,
              likes_count,
              comments_count,
              shares_count,
              created_at,
              users (
                id,
                auth_id,
                full_name,
                avatar_url,
                city
              )
            `)
            .eq('id', payload.new.id)
            .single();

          if (error) throw error;
          if (!data) throw new Error('No post data found');

          const post: Post = {
            id: data.id,
            author: {
              id: data.users.auth_id,
              name: data.users.full_name,
              image: data.users.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/browser-7FxbPH2wjAjobBXAUmpZCLVNmTIwkm.png',
              location: data.users.city
            },
            content: data.content,
            media: data.media_url,
            likes: data.likes_count,
            comments: data.comments_count,
            shares: data.shares_count,
            timeAgo: new Date(data.created_at).toISOString()
          };

          callback(post);
        } catch (error) {
          console.error('Error processing post update:', error);
        }
      }
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}