import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CitiesPlayedModal from '../profile/modal/CitiesPlayedModal';
import MemberProfileSkeleton from '../members/MemberProfileSkeleton';
import NetworkTab from '../profile/tabs/NetworkTab';
import PostsTab from '../profile/tabs/PostsTab';
//import TrainingTab from '../members/tabs/TrainingTab';
//import MyTripsTab from './trips/MyTripsTab';
//import PerformanceTracker from '../performance/PerformanceTracker';
//import ReviewsSection from '../members/ReviewsSection';
import ProfileTabs from './ProfileTabs';
import AboutTab from './AboutTab';
import ProfileHeader from '../profile/sections/ProfileHeader';
import ProfileStats from './ProfileStats';
import { cityLandmarks } from '../../data/cities';
import { useProfile } from '../../hooks/useProfile';
import { supabase } from '../../lib/supabase';
import { storage } from '../../lib/supabase/storage';
import { useConnectionStore } from '../../store/connectionStore';
import { Post } from '../../types/post';
import { Member } from '../../types/member';
import { useTheme } from '@/contexts/ThemeContext';

interface ProfileSectionProps {
  defaultTab?: string;
  hideDetails?: boolean;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  defaultTab = 'about',
  hideDetails = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<
    | 'about'
    | 'posts'
    | 'network'
    | 'trips'
    | 'training'
    | 'performance'
    | 'ratings'
  >(defaultTab as any);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [memberPosts, setMemberPosts] = useState<Post[]>([]);
  const isLoading = useConnectionStore((state) => state.isLoading);

  const {
    profile,
    isLoading: profileLoading,
    error: profileError,
    fetchProfile,
    updateProfile,
  } = useProfile();

  const { pendingRequests, fetchConnections } = useConnectionStore();
  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Extract Social Media Links
  const socialLinks = {
    linkedinUrl: profile?.linkedin_url || null,
    facebookUrl: profile?.facebook_url || null,
    instagramUrl: profile?.instagram_url || null,
  };

  //console.log("✅ Extracted Social Links in ProfileSection:", socialLinks);

  const currentUser: Member = {
    id: 'current-user',
    name: profile?.full_name || 'User',
    image: profile?.avatar_url?.startsWith('data:image')
      ? 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
      : profile?.avatar_url ||
        'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
    city: profile?.city || 'Location',
    jobTitle: profile?.job_type || 'Role',
    company: profile?.company || 'Company',
    skillLevel: 'Advanced',
    connections: 0,
    matchesPlayed: 0,
    citiesPlayed: ['Valencia', 'London', 'Paris'],
    achievements: ['Tournament Winner', 'Elite Status'],
    rating: 4.9,
    joinDate: '2024-10-01',
    industry: 'Technology',
    points: {
      matches: 6200,
      training: 4500,
      connections: 16200,
      cities: 1800,
      events: 3200,
      achievements: 5000,
      total: 36900,
    },
  };

  const cityImage = currentUser.city
    ? cityLandmarks[currentUser.city.toLowerCase()] ||
      'https://images.unsplash.com/photo-1575540203949-54ccd7a66d98?auto=format&fit=crop&w=1600&q=80'
    : 'https://images.unsplash.com/photo-1575540203949-54ccd7a66d98?auto=format&fit=crop&w=1600&q=80';

  if (isLoading || profileLoading) {
    return <MemberProfileSkeleton />;
  }

  if (profileError) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
        <p>
          {profileError === 'Failed to fetch'
            ? 'Unable to connect to server. Please check your connection.'
            : profileError}
        </p>
        <button
          onClick={() => fetchProfile()}
          className="text-sm underline mt-2 hover:text-red-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Pass Social Links and onImageChange callback to ProfileHeader */}
      <ProfileHeader
        member={currentUser}
        profile={profile}
        cityImage={cityImage}
        socialLinks={socialLinks}
        onImageChange={async (file) => {
          try {
            const uploadData = await storage.uploadFile({
              bucket: 'avatars',
              file,
              path: `avatars/${currentUser.id}/${file.name}`,
            });
              console.log('Upload Data: ', uploadData.url);
            if (uploadData?.url) {
              console.log('Inside upload data: ', uploadData.url);
      // Append a cache-busting query parameter
      const newUrl = `${uploadData.url}?t=${Date.now()}`;
      await updateProfile({ avatar_url: newUrl });
      // Force refresh of profile state
      await fetchProfile();
            }
          } catch (error) {
            console.error('Failed to update profile image:', error);
          }
        }}
      />

      <ProfileStats
        member={currentUser}
        onShowCities={() => setShowCitiesModal(true)}
      />

      <ProfileTabs
        activeTab={activeTab}
        onTabChange={setActiveTab}
        pendingCount={pendingRequests?.length || 0}
      />

      {activeTab === 'about' && <AboutTab member={currentUser} profile={profile} />}
      {activeTab === 'posts' && <PostsTab posts={memberPosts || []} memberName={currentUser.name} />}
      {activeTab === 'network' && <NetworkTab />}
      {/*activeTab === 'trips' && <MyTripsTab />*/}
      {/*activeTab === 'training' && <TrainingTab />*/}
      {/*activeTab === 'performance' && <PerformanceTracker />*/}
      {activeTab === 'ratings' && (
        <ReviewsSection memberId={currentUser.id} memberName={currentUser.name} />
      )}

      {/* Optionally, include CitiesPlayedModal if needed */}
      {/* {showCitiesModal && (
        <CitiesPlayedModal
          isOpen={true}
          cities={currentUser.citiesPlayed || []}
          memberName={currentUser.name}
          onClose={() => setShowCitiesModal(false)}
        />
      )} */}
    </div>
  );
};

export default ProfileSection;
