import React from 'react';
import { Building } from 'lucide-react';
import type { Conversation } from '../../../types/chat';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface ConversationItemProps {
  conversation: Conversation;
  isActive: boolean;
  onClick: () => void;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isActive,
  onClick
}) => {
  const { isDarkMode } = useTheme();
  
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-full px-4 py-3.5 transition-all text-left relative",
        "hover:bg-dark-300/30 dark:hover:bg-dark-300/30 light:hover:bg-light-50/80", // Lighter hover in light mode
        isActive && "dark:bg-dark-300/80 light:bg-light-50",
        "group"
      )}
      data-conversation-id={conversation.id}
    >
      <div className="flex items-start space-x-4">
        {/* Profile Image */}
        <div className="relative flex-shrink-0">
          <div className={cn(
            "relative w-16 h-16 transform transition-all duration-200",
            "group-hover:scale-105"
          )}>
            <img
              src={conversation.image}
              alt={conversation.name}
              className={cn(
                "w-full h-full rounded-full object-cover",
                "border-2 transition-all duration-200",
                "shadow-md group-hover:shadow-lg",
                "transform-gpu",
                isDarkMode 
                  ? [
                      "border-dark-400 group-hover:border-gold-400",
                      isActive && "border-gold-400"
                    ]
                  : [
                      "border-light-300 group-hover:border-gold-600",
                      isActive && "border-gold-600"
                    ]
              )}
            />
            {conversation.online && (
              <div className={cn(
                "absolute -bottom-0.5 -right-0.5 w-4 h-4",
                "bg-green-400 rounded-full",
                "border-2 border-dark-200",
                "transform transition-all duration-200",
                "group-hover:scale-110",
                "shadow-lg"
              )} />
            )}
          </div>
        </div>
        
        {/* Content */}
        <div className="flex-1 min-w-0 py-0.5">
          <div className="flex items-center justify-between">
            <div>
              <h4 className={cn(
                "font-medium truncate max-w-[150px]",
                "transition-colors duration-200",
                "text-base",
                isDarkMode 
                  ? [
                      "text-white group-hover:text-gold-400",
                      isActive && "text-gold-400"
                    ]
                  : [
                      "text-gray-900 group-hover:text-gold-600",
                      isActive && "text-gold-600"
                    ]
              )}>
                {conversation.name}
              </h4>
              <div className={cn(
                "flex items-center mt-1",
                "text-xs",
                isDarkMode ? "text-dark-800" : "text-gray-500"
              )}>
                <Building className="h-3 w-3 mr-1" />
                <span className="truncate">{conversation.city}</span>
              </div>
            </div>
            
          </div>

          <p className={cn(
            "text-sm mt-1.5 truncate",
            "transition-colors duration-200",
            isDarkMode 
              ? "text-dark-800 group-hover:text-white/80" 
              : "text-gray-500 group-hover:text-gray-700",
            "max-w-[200px]"
          )}>
            {conversation.lastMessage}
          </p>
        </div>

        {/* Notification Badge */}
        {conversation.unread > 0 && (
          <div className={cn(
            "absolute right-4 top-4",
            "transform transition-all duration-200",
            "group-hover:translate-x-0.5"
          )}>
            <span className={cn(
              "flex items-center justify-center",
              "min-w-[22px] h-[22px]",
              "bg-gold-400 text-dark-50",
              "text-xs font-medium",
              "rounded-full px-1.5",
              "shadow-lg",
              "ring-2 ring-dark-200"
            )}>
              {conversation.unread}
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default ConversationItem;
