import { useState, useCallback } from 'react';
import { useConnectionStore } from '@/store/connectionStore';
import { useNotificationStore } from '@/store/notificationStore';
import { supabase } from '../lib/supabase';

export function useConnection() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    sendRequest,
    acceptRequest,
    declineRequest,
    cancelRequest,
    blockUser,
    unblockUser,
  } = useConnectionStore();

  const { addNotification } = useNotificationStore();

  const handleSendRequest = useCallback(
    async (receiverId: string, receiverName: string) => {
      setIsLoading(true);
      setError(null);
      try {
        // Receiving authid from member getting userid from db
        console.log(
          'Fetching receiver user ID from users table...',
          receiverId
        );
        const { data, error } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', receiverId)
          .single();
        console.log('Fetched data from users table...', data);
        if (error || !data) {
          console.error('Error fetching receiver user ID:', error);
          setIsLoading(false);
          return false;
        }

        console.log('receiver ID: ', data.id);
        const success = await sendRequest(data.id); //using userid
        if (success) {
          addNotification({
            id: `connection_request_${Date.now()}`,
            type: 'connection_request',
            title: 'Connection Request Sent',
            message: `Your connection request to ${receiverName} was sent.`,
            timestamp: new Date().toISOString(),
            read: false,
          });
          return true;
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : 'Failed to send connection request'
        );
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [sendRequest, addNotification]
  );

  const handleAcceptRequest = useCallback(
    async (connectionId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const success = await acceptRequest(connectionId);
        if (success) {
          addNotification({
            id: `connection_accepted_${Date.now()}`,
            type: 'connection_accepted',
            title: 'Connection Accepted',
            message: 'You are now connected!',
            timestamp: new Date().toISOString(),
            read: false,
          });
          return true;
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : 'Failed to accept connection request'
        );
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [acceptRequest, addNotification]
  );

  const handleDeclineRequest = useCallback(
    async (connectionId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const success = await declineRequest(connectionId);
        if (success) {
          return true;
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : 'Failed to decline connection request'
        );
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [declineRequest]
  );

  const handleCancelRequest = useCallback(
    async (connectionId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const success = await cancelRequest(connectionId);
        if (success) {
          return true;
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : 'Failed to cancel connection request'
        );
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [cancelRequest]
  );

  const handleBlockUser = useCallback(
    async (connectionId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const success = await blockUser(connectionId);
        if (success) {
          return true;
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to block user');
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [blockUser]
  );

  const handleUnblockUser = useCallback(
    async (connectionId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const success = await unblockUser(connectionId);
        if (success) {
          return true;
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to unblock user');
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [unblockUser]
  );

  return {
    isLoading,
    error,
    handleSendRequest,
    handleAcceptRequest,
    handleDeclineRequest,
    handleCancelRequest,
    handleBlockUser,
    handleUnblockUser,
  };
}
