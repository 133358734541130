import { create } from 'zustand';
import { submitFeedback, getFeedback } from '../lib/supabase/feedback';

interface FeedbackState {
  isSubmitting: boolean;
  error: string | null;
  submitFeedback: (data: {
    type: 'feature_request' | 'improvement_suggestion' | 'bug_report' | 'general_feedback';
    rating: number;
    content: string;
  }) => Promise<void>;
}

export const useFeedbackStore = create<FeedbackState>((set) => ({
  isSubmitting: false,
  error: null,

  submitFeedback: async (data) => {
    set({ isSubmitting: true, error: null });
    try {
      await submitFeedback(data);
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to submit feedback'
      });
      throw error;
    } finally {
      set({ isSubmitting: false });
    }
  }
}));