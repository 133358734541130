import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Lock, Eye, EyeOff, ArrowLeft, CheckCircle } from 'lucide-react';
import { Input } from '../../ui/input';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { cn } from '@/lib/utils';

interface PasswordStepProps {
  onNext: (password: string) => Promise<void>;
  onBack: () => void;
  isSubmitting?: boolean;
  error?: string | null;
}

const PasswordStep: React.FC<PasswordStepProps> = ({
  onNext, 
  onBack,
  isSubmitting = false,
  error: serverError = null
}) => {
  //console.log('Rendering PasswordStep:', { isSubmitting, serverError });
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
    server?: string;
  }>({});

  const validatePassword = (pass: string) => {
    if (pass.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(pass)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(pass)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(pass)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(pass)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return '';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    console.log('Password submit attempt');
    
    // Reset errors
    setErrors({});
    
    // Validate password
    const passwordError = validatePassword(password);
    if (passwordError) {
      console.log('Password validation failed:', passwordError);
      setErrors(prev => ({ ...prev, password: passwordError }));
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      setErrors(prev => ({ ...prev, confirmPassword: 'Passwords do not match' }));
      return;
    }

    try {
      setIsLoading(true);
      console.log('Submitting password...');
      await onNext(password);
    } catch (error) {
      console.error('Error submitting password:', error);
      setErrors(prev => ({
        ...prev,
        server: error instanceof Error ? error.message : 'Failed to create user profile'
      }));
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full flex flex-col bg-dark-200 rounded-xl p-6 border border-dark-300 pt-16"
    >
      <div className="flex items-center space-x-2 mb-6">
        <div className="bg-gold-400/30 p-3 rounded-lg">
          <Lock className="h-5 w-5 text-gold-400" />
        </div>
        <h3 className="text-xl font-semibold text-white">Create Password</h3>
      </div>

      {/* Server Error Message */}
      {(serverError || errors.server) && (
        <div className="mb-6 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
          <p className="text-red-500 text-sm">{errors.server || serverError}</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="flex flex-col flex-1">
        <div className="flex-1 space-y-6 overflow-y-auto pr-2">
        <div className="space-y-4">
          {/* Password Field */}
          <div>
            <label className="block text-white/80 mb-2 font-medium">Password</label>
            <div className="relative">
              <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={cn(
                  "bg-dark-300/30 text-white pr-10",
                  "h-11 rounded-lg border border-dark-400/30",
                  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gold-400",
                  "focus-visible:border-gold-400",
                  "transition duration-200",
                  errors.password && "border-red-500"
                )}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-dark-800 hover:text-white"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          {/* Confirm Password Field */}
          <div>
            <label className="block text-white/80 mb-2 font-medium">Confirm Password</label>
            <div className="relative">
              <Input
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={cn(
                  "bg-dark-300/30 text-white pr-10",
                  "h-11 rounded-lg border border-dark-400/30",
                  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gold-400",
                  "focus-visible:border-gold-400",
                  "transition duration-200",
                  errors.confirmPassword && "border-red-500"
                )}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-dark-800 hover:text-white"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
            )}
          </div>
        </div>

      
        </div>

        <FloatingNavigation 
          onBack={onBack}
          onNext={handleSubmit}
          isNextDisabled={!password || !confirmPassword}
          isSubmitting={isSubmitting || isLoading}
        />
      </form>
    </motion.div>
  );
};

export default PasswordStep;