import { useState, useCallback, useEffect, useRef } from 'react';
import { Player } from '../../types/matchmaking';
import { DEFAULT_FILTERS } from '../../lib/utils/filters';
import { sortQuickPlayResults } from '../../lib/utils/sorting';
import { getUserLocation } from '../../lib/utils/location';
import { calculateLocationDistance } from '../../lib/utils/coordinates';
import { supabase } from '../../lib/supabase';
import { sportEmojis } from '../../lib/constants';
// NEW: Import connection store to determine connection state for the connect button
import { useConnectionStore } from '../../store/connectionStore';

// Cache configuration
const CACHE_DURATION = 60 * 1000; // 1 minute cache
const BATCH_SIZE = 20;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const STALE_WHILE_REVALIDATE = true;

// Maximum retries for fetch operations
const NETWORK_ERRORS = new Set([
  'failed to fetch',
  'network request failed',
  'networkerror',
  'network timeout',
  'failed to fetch resource'
]);

// Memory cache
const playersCache = new Map<string, {
  data: Player[];
  timestamp: number;
  isStale: boolean;
}>();

// Cache management
function getCachedPlayers(cacheKey: string): Player[] | null {
  const cached = playersCache.get(cacheKey);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  if (STALE_WHILE_REVALIDATE && age < CACHE_DURATION * 2) {
    cached.isStale = true;
    return cached.data;
  }

  playersCache.delete(cacheKey);
  return null;
}

function setCachedPlayers(cacheKey: string, data: Player[]) {
  playersCache.set(cacheKey, {
    data,
    timestamp: Date.now(),
    isStale: false
  });
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    const isNetworkError = error instanceof Error &&
      NETWORK_ERRORS.has(error.message.toLowerCase());

    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      console.log(`Network error, retrying in ${delay}ms... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }
    throw error;
  }
}

export function useQuickMatch() {
  // NEW: Get connection information from the connection store.
  const { connections } = useConnectionStore();

  const [potentialPlayers, setPotentialPlayers] = useState<Player[]>([]);
  const [searchState, setSearchState] = useState<'searching' | 'found' | 'request-sent' | 'not-found'>('searching');
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);

  const [searchTerm, setSearchTerm] = useState(DEFAULT_FILTERS.searchTerm);
  const [selectedSport, setSelectedSport] = useState(DEFAULT_FILTERS.sport);
  const [selectedGender, setSelectedGender] = useState(DEFAULT_FILTERS.gender);
  const [selectedRating, setSelectedRating] = useState(DEFAULT_FILTERS.rating);
  const [showConnectionsOnly, setShowConnectionsOnly] = useState(DEFAULT_FILTERS.connectionsOnly);
  const [sports, setSports] = useState<string[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const cacheKeyRef = useRef<string>(`${Date.now()}`);
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

  // Get user's location on mount
  useEffect(() => {
    let mounted = true;

    const getLocation = async () => {
      try {
        const location = await getUserLocation();
        if (mounted) {
          setUserLocation(location);
        }
      } catch (error) {
        console.warn('Failed to get user location:', error);
      }
    };

    getLocation();

    return () => {
      mounted = false;
    };
  }, []);

  // Cache for profile images
  const imageCache = useRef<Map<string, string>>(new Map());

  const fetchUsers = useCallback(async () => {
    if (!shouldFetch) return [];
    setIsLoading(true);
    setError(null);

    console.log('Fetching users with filters:', {
      selectedGender,
      selectedSport,
      selectedRating,
      showConnectionsOnly
    });

    // Generate cache key based on filters
    const cacheKey = `${selectedSport}_${selectedGender}_${selectedRating}_${showConnectionsOnly}_${cacheKeyRef.current}`;

    // Check cache first
    const cached = getCachedPlayers(cacheKey);
    if (cached) {
      console.log('Using cached players:', {
        count: cached.length,
        genders: cached.map(p => p.gender)
      });
      setPotentialPlayers(cached);
      setSearchState('found');
      setIsLoading(false);
      return cached;
    }

    try {
      // Get current session
      const { data: { session }, error: sessionError } = await fetchWithRetry(() =>
        supabase.auth.getSession(), 3
      );

      if (sessionError) throw sessionError;
      if (!session?.user) {
        throw new Error('Please sign in to continue');
      }

      // Get current user
      const { data: currentUser, error: userError } = await fetchWithRetry(() =>
        supabase
          .from('users')
          .select(`
            id,
            auth_id,
            city,
            gender,
            user_profiles (preferences)
          `)
          .eq('auth_id', session.user.id)
          .single()
        , 3);

      if (userError || !currentUser) {
        throw new Error('Unable to load user profile');
      }

      // Optionally, the following block was previously used to fetch connected user IDs if "Show Connections Only" is enabled.
      // With the connection store now in use, you might remove this if desired.
      let connectedUserIds: string[] = [];
      if (showConnectionsOnly) {
        const { data: connectionsData, error: connectionsError } = await supabase
          .from('connections')
          .select('initiator_id, receiver_id')
          .eq('status', 'accepted')
          .or(`initiator_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`);

        if (connectionsError) throw connectionsError;

        connectedUserIds = connectionsData.map(conn =>
          conn.initiator_id === currentUser.id ? conn.receiver_id : conn.initiator_id
        );
      }

      // Fetch users in batches with optimized query
      const users = [];
      let hasMore = true;
      let offset = 0;
      let retryCount = 0;
      const MAX_BATCH_RETRIES = 3;

      while (hasMore) {
        try {
          let userQuery = supabase
            .from('users')
            .select(`
              id,
              auth_id,
              full_name,
              avatar_url,
              city,
              skill_level,
              company,
              job_type,
              gender,
              user_profiles (preferences),
              user_sports (sports (name, emoji))
            `)
            .neq('id', currentUser.id) // Exclude the current user
            .range(offset, offset + BATCH_SIZE - 1);

          // If "Show Connections Only" is enabled, you can optionally filter via connectedUserIds
          if (showConnectionsOnly && connectedUserIds.length > 0) {
            userQuery = userQuery.in('id', connectedUserIds);
          }

          const { data, error } = await fetchWithRetry(() => userQuery, 3);

          if (error) {
            if (retryCount < MAX_BATCH_RETRIES) {
              retryCount++;
              await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              continue;
            }
            throw new Error('Unable to load players');
          }

          if (!data || data.length === 0) {
            hasMore = false;
            break;
          }

          console.log('Fetched users batch:', {
            count: data.length,
            genders: data.map(u => u.gender)
          });

          users.push(...data);
          offset += BATCH_SIZE;
          retryCount = 0;

          if (data.length < BATCH_SIZE) {
            hasMore = false;
          }
        } catch (error) {
          if (retryCount < MAX_BATCH_RETRIES) {
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
          throw error;
        }
      }

      // Process users and collect sports
      const allSports = new Set<string>();
      const processedUsers = users.map(user => {
        const userSports = user.user_sports
          ?.map(sportEntry => sportEntry.sports?.name)
          .filter((name): name is string => Boolean(name)) || [];

        userSports.forEach(sport => allSports.add(sport));

        // Map sports to emoji format using the sportEmojis mapping
        const formattedSports = userSports.map(sport =>
          sportEmojis[sport] ? `${sportEmojis[sport]} ${sport}` : sport
        );

        // Calculate distance using user location if available; otherwise use a city-based fallback
        let distance = -1;
        if (
          userLocation &&
          user.user_profiles?.preferences?.latitude &&
          user.user_profiles?.preferences?.longitude
        ) {
          try {
            distance = calculateLocationDistance(
              userLocation,
              {
                latitude: user.user_profiles.preferences.latitude,
                longitude: user.user_profiles.preferences.longitude
              }
            );
            if (isNaN(distance) || distance === null) distance = -1;
          } catch (e) {
            console.error(`Error calculating distance for ${user.full_name}:`, e);
          }
        } else {
          distance = calculateLocationDistance(
            currentUser.city || 'Unknown',
            user.city || 'Unknown'
          );
        }

        // NEW: Determine connection status using the connection store.
        // This finds if there is a connection for this user and sets flags accordingly.
        const connection = connections.find(conn => conn.other_user?.auth_id === user.auth_id);
        const isConnected = connection?.status === 'accepted';
        const isPending = connection?.status === 'pending';

        return {
          id: user.auth_id,
          name: user.full_name,
          gender: user.gender || 'rather_not_say',
          age: user.user_profiles?.preferences?.age || 0,
          location: user.city,
          sports: formattedSports,
          rating: user.user_profiles?.preferences?.rating || 4.5,
          matchPercentage: Math.floor(Math.random() * 15 + 85),
          availability: ['morning', 'afternoon', 'evening'],
          image:
            imageCache.current.get(user.auth_id) ||
            user.avatar_url ||
            'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          company: {
            name: user.company || 'Company',
            position: user.job_type || 'Role'
          },
          distance: Math.round(distance),
          isConnected, // Use the connection status from the store
          isPending,   // Also include pending connection status
          recentActivity: 'Recently active'
        };
      });

      console.log('Processed users:', {
        total: processedUsers.length,
        genderBreakdown: processedUsers.reduce((acc, user) => {
          acc[user.gender] = (acc[user.gender] || 0) + 1;
          return acc;
        }, {} as Record<string, number>)
      });

      // Cache profile images for future use
      processedUsers.forEach(user => {
        if (user.image && !imageCache.current.has(user.id)) {
          imageCache.current.set(user.id, user.image);
        }
      });

      setSports(['all', ...Array.from(allSports)].sort());

      // Sort processed users by distance (users with no valid distance will be pushed last)
      processedUsers.sort((a, b) => {
        if (a.distance === -1) return 1;
        if (b.distance === -1) return -1;
        return a.distance - b.distance;
      });

      // Cache the results for subsequent calls
      setCachedPlayers(cacheKey, processedUsers);

      return processedUsers;

    } catch (error) {
      console.error('Error fetching users:', error);
      setError({ message: 'Failed to fetch users. Please try again.' });
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [
    shouldFetch,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    connections,
    userLocation
  ]);

  const filterPlayers = useCallback(async () => {
    if (!shouldFetch) return;
    try {
      setIsLoading(true);
      setError(null);

      const users = await fetchUsers();
      if (!users || users.length === 0) {
        console.log('No users found');
        setPotentialPlayers([]);
        setSearchState('not-found');
        return;
      }

      console.log('Filtering players:', {
        totalUsers: users.length,
        selectedGender,
        genderBreakdown: users.reduce((acc, user) => {
          acc[user.gender] = (acc[user.gender] || 0) + 1;
          return acc;
        }, {} as Record<string, number>)
      });

      const filteredPlayers = users.filter(user => {
        const searchTermLower = searchTerm.toLowerCase().trim();
        const matchesSearch =
          !searchTermLower ||
          user.name.toLowerCase().includes(searchTermLower) ||
          user.location.toLowerCase().includes(searchTermLower) ||
          user.sports.some(sport => sport.toLowerCase().includes(searchTermLower)) ||
          user.company.name.toLowerCase().includes(searchTermLower) ||
          user.company.position.toLowerCase().includes(searchTermLower);

        const matchesSport =
          selectedSport === 'all' ||
          user.sports.some(sport =>
            sport.toLowerCase().replace(/[^a-z]/gi, '') ===
            selectedSport.toLowerCase().replace(/[^a-z]/gi, '')
          );

        const matchesGender = selectedGender === 'all' || user.gender === selectedGender;

        console.log('Gender match check:', {
          name: user.name,
          userGender: user.gender,
          selectedGender,
          matches: matchesGender
        });

        const matchesRating =
          selectedRating === 'all' || user.rating >= parseFloat(selectedRating);

        // Use the connection status from the store for filtering
        const matchesConnections = !showConnectionsOnly || user.isConnected;

        const shouldInclude =
          matchesSearch &&
          matchesSport &&
          matchesGender &&
          matchesRating &&
          matchesConnections;

        if (!shouldInclude) {
          console.log('Player filtered out:', {
            name: user.name,
            gender: user.gender,
            matchesGender,
            matchesSearch,
            matchesSport,
            matchesRating,
            matchesConnections
          });
        }

        return shouldInclude;
      });

      console.log('Filter results:', {
        totalPlayers: users.length,
        filteredCount: filteredPlayers.length,
        selectedGender,
        genderBreakdown: filteredPlayers.reduce((acc, player) => {
          acc[player.gender] = (acc[player.gender] || 0) + 1;
          return acc;
        }, {} as Record<string, number>)
      });

      // Sort filtered players by distance (users with no valid distance are pushed last)
      filteredPlayers.sort((a, b) => {
        if (a.distance === -1) return 1;
        if (b.distance === -1) return -1;
        return a.distance - b.distance;
      });

      setPotentialPlayers(filteredPlayers);
      setSearchState(filteredPlayers.length > 0 ? 'found' : 'not-found');
      setError(null);
    } catch (error) {
      console.error('Error in filterPlayers:', error);
      if (error instanceof Error) {
        setError({
          message:
            error.message === 'Failed to fetch'
              ? 'Unable to connect to the server. Please check your internet connection.'
              : error.message
        });
      } else {
        setError({ message: 'Unable to find players. Please try again.' });
      }
      setPotentialPlayers([]);
      setSearchState('not-found');
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, selectedSport, selectedGender, selectedRating, fetchUsers, shouldFetch, showConnectionsOnly]);

  const resetSearch = useCallback(() => {
    // Update cache key to force a new fetch
    cacheKeyRef.current = `${Date.now()}`;

    // Clear caches
    playersCache.clear();

    // Reset all filters (image cache is preserved)
    setSearchTerm(DEFAULT_FILTERS.searchTerm);
    setSelectedSport(DEFAULT_FILTERS.sport);
    setSelectedGender(DEFAULT_FILTERS.gender);
    setSelectedRating(DEFAULT_FILTERS.rating);
    setShowConnectionsOnly(DEFAULT_FILTERS.connectionsOnly);
    setIsInitialSearch(true);
    setPotentialPlayers([]);
    setSearchState('searching');
    setError(null);
    setShouldFetch(true);
    playersCache.clear();

    // Trigger an immediate search
    setTimeout(() => {
      filterPlayers();
      setIsInitialSearch(false);
    }, 100);
  }, [filterPlayers]);

  useEffect(() => {
    if (shouldFetch && !isInitialSearch) {
      filterPlayers();
    }
  }, [searchTerm, selectedSport, selectedGender, selectedRating, showConnectionsOnly, isInitialSearch, filterPlayers]);

  useEffect(() => {
    return () => {
      setShouldFetch(false);
      setPotentialPlayers([]);
    };
  }, []);

  return {
    potentialPlayers,
    searchState,
    isInitialSearch,
    isLoading,
    error,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    filterPlayers,
    resetSearch
  };
}
