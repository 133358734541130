import React, { useState, useEffect } from 'react';
import {
  UserPlus,
  UserMinus,
  Clock,
  Ban,
  XCircle,
  CheckCircle,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { useConnection } from '../../hooks/useConnection';
import { useConnectionStore } from '../../store/connectionStore';

interface ConnectionButtonProps {
  memberId: string; // auth_id
  memberName: string;
  memberImage: string;
  className?: string;
}

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  memberId,
  memberName,
  memberImage,
  className,
}) => {
  const { isDarkMode } = useTheme();
  const { handleSendRequest } = useConnection();
  const { connections } = useConnectionStore();
  const [connectionStatus, setConnectionStatus] = useState<
    'accepted' | 'pending' | 'declined' | 'blocked' | 'none'
  >('none');
  const [isProcessing, setIsProcessing] = useState(false);
  const [resolvedUserId, setResolvedUserId] = useState<string | null>(null);

  // Determine connection status and resolve user ID
  useEffect(() => {
    console.log('Connections array:', connections);
    const existingConnection = connections.find(
      (conn) => conn.other_user && conn.other_user.auth_id === memberId
    );

    if (existingConnection) {
      setConnectionStatus(
        existingConnection.status as
          | 'accepted'
          | 'pending'
          | 'declined'
          | 'blocked'
      );
      setResolvedUserId(existingConnection.other_user.id); // Use the user_id from users table
    } else {
      setConnectionStatus('none');
      setResolvedUserId(memberId); // For non-connected users, use auth_id to fetch user_id later
    }
  }, [connections, memberId]);

  const handleConnect = async () => {
    if (isProcessing || !resolvedUserId) return;
    setIsProcessing(true);

    try {
      console.log('Sending request for:', resolvedUserId);
      const success = await handleSendRequest(resolvedUserId, memberName);
      if (success) {
        setConnectionStatus('pending');
      }
    } catch (err) {
      console.error('Error sending connection request:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {connectionStatus === 'accepted' ? (
        <button
          className={cn(
            'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            isDarkMode ? 'bg-green-500 text-white' : 'bg-green-500 text-white',
            className
          )}
          disabled
        >
          <CheckCircle className="h-4 w-4" />
          <span>Connected</span>
        </button>
      ) : connectionStatus === 'pending' ? (
        <button
          className={cn(
            'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            isDarkMode
              ? 'bg-yellow-400 text-white'
              : 'bg-yellow-400 text-white',
            className
          )}
          disabled
        >
          <Clock className="h-4 w-4" />
          <span>Pending</span>
        </button>
      ) : connectionStatus === 'declined' ? (
        <button
          className={cn(
            'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            isDarkMode ? 'bg-red-500 text-white' : 'bg-red-500 text-white',
            className
          )}
          disabled
        >
          <XCircle className="h-4 w-4" />
          <span>Declined</span>
        </button>
      ) : connectionStatus === 'blocked' ? (
        <button
          className={cn(
            'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            isDarkMode ? 'bg-red-700 text-white' : 'bg-red-700 text-white',
            className
          )}
          disabled
        >
          <Ban className="h-4 w-4" />
          <span>Blocked</span>
        </button>
      ) : (
        <button
          onClick={handleConnect}
          className={cn(
            'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            isDarkMode
              ? 'bg-gold-400 hover:bg-gold-500 text-dark-50'
              : 'bg-gold-400 hover:bg-gold-500 text-dark-50',
            className
          )}
        >
          <UserPlus className="h-4 w-4" />
          <span>Connect</span>
        </button>
      )}
    </>
  );
};

export default ConnectionButton;