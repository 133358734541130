import React, { useMemo } from 'react';
import { Search, Filter } from 'lucide-react';
import { Input } from '../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import { Activity } from '../../types/activity';

interface ActivityFiltersProps {
  searchTerm: string;
  selectedSport: string;
  selectedCity: string;
  selectedStatus: string;
  activeTab: 'current' | 'past';
  sortOrder: 'recent' | 'oldest';
  showFilters: boolean;
  activities: Activity[];
  onSearchChange: (value: string) => void;
  onSportChange: (value: string) => void;
  onCityChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  onTabChange: (tab: 'current' | 'past') => void;
  onSortOrderChange: (value: 'recent' | 'oldest') => void;
  onToggleFilters: () => void;
}

const ActivityFilters: React.FC<ActivityFiltersProps> = ({
  searchTerm,
  selectedSport,
  selectedCity,
  selectedStatus,
  activeTab,
  sortOrder,
  showFilters,
  onSearchChange,
  onSportChange,
  onCityChange,
  onStatusChange,
  onTabChange,
  onSortOrderChange,
  onToggleFilters,
  activities
}) => {
  // Extract unique cities and sports from current activities
  // Extract unique cities and sports from current activities
  const activityCities = useMemo(() => 
    [...new Set(activities.map(activity => activity.venue.city))]
      .filter((city): city is string => Boolean(city?.trim()))
      .map(city => city.trim())
      .filter((city, index, self) => 
        self.indexOf(city) === index && 
        city.toLowerCase() === city.toLowerCase()
      )
      .sort()
  , [activities]);

  const activitySports = useMemo(() => {
    // Get unique sports by comparing the text part without emoji
    return activities
      .filter((activity): activity is Activity => Boolean(activity))
      .map(activity => activity.sport)
      .reduce((acc: string[], sport) => {
        // Extract the text part after the emoji (after the first space)
        const sportText = sport.split(' ').slice(1).join(' ').toLowerCase();
        
        // Check if we already have a sport with this text
        const exists = acc.some(existingSport => 
          existingSport.split(' ').slice(1).join(' ').toLowerCase() === sportText
        );
        
        if (!exists) {
          acc.push(sport);
        }
        return acc;
      }, []);
  }, [activities]);

  // Extract unique statuses and map them to display names
  const activityStatuses = useMemo(() => {
    const statusMap: Record<string, string> = {
      new_request: 'New Request',
      confirmed: 'Confirmed',
      played: 'Played',
      declined: 'Declined',
      cancelled: 'Cancelled',
      disputed: 'Disputed',
      update_requested: 'Update Requested',
      missed: 'Missed'
    };

    // Get all statuses from activities, including dynamically updated ones
    const allStatuses = activities.map(activity => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const activityDate = new Date(activity.date);
      const isPastActivity = activityDate.getTime() < today.getTime();

      // Return updated status for past activities
      if (isPastActivity && activity.status !== 'played' && activity.status !== 'missed' && activity.status !== 'declined') {
        return activity.status === 'confirmed' ? 'played' : 'missed';
      }

      return activity.status;
    });

    return [...new Set(allStatuses)]
      .filter(status => Boolean(status))
      .sort((a, b) => (statusMap[a] || a).localeCompare(statusMap[b] || b))
      .map(status => ({
        value: status,
        label: statusMap[status] || status.charAt(0).toUpperCase() + status.slice(1)
      }));
  }, [activities]);
  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      {/* Tabs */}
      <div className="flex space-x-4 mb-4 border-b border-dark-300">
        <button
          onClick={() => onTabChange('current')}
          className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
            activeTab === 'current'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-dark-800 hover:text-white'
          }`}
        >
          Upcoming Activities
        </button>
        <button
          onClick={() => onTabChange('past')}
          className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
            activeTab === 'past'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-dark-800 hover:text-white'
          }`}
        >
          Past Activities
        </button>
      </div>

      <div className="flex flex-col space-y-4">
        {/* Search Bar */}
        <div className="flex items-center gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <Input
              type="text"
              placeholder="Search activities..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full pl-10 bg-dark-300 border-dark-400 text-white"
            />
          </div>
          <button 
            onClick={onToggleFilters}
            className="flex items-center space-x-2 px-3 py-2 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors"
          >
            <Filter className="h-5 w-5 text-dark-800" />
            <span className="text-white text-sm">{showFilters ? 'Hide Filters' : 'Show Filters'}</span>
          </button>
        </div>

        {/* Filters */}
        {showFilters && (
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <Select value={selectedSport} onValueChange={onSportChange}>
              <SelectTrigger>
                <SelectValue placeholder="All Sports" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Sports</SelectItem>
                {activitySports.map(sport => (
                  <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={selectedCity} onValueChange={onCityChange}>
              <SelectTrigger>
                <SelectValue placeholder="All Cities" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Cities</SelectItem>
                {activityCities.map(city => (
                  <SelectItem key={city} value={city}>{city}</SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={selectedStatus} onValueChange={onStatusChange}>
              <SelectTrigger>
                <SelectValue placeholder="All Statuses" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                {activityStatuses.map(status => (
                  <SelectItem key={status.value} value={status.value}>
                    {status.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={sortOrder} onValueChange={onSortOrderChange}>
              <SelectTrigger>
                <SelectValue placeholder="Sort Order" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="recent">Most Recent</SelectItem>
                <SelectItem value="oldest">Oldest First</SelectItem>
              </SelectContent>
            </Select>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityFilters;