import { useState, useCallback } from 'react';
import { UserProfile } from '../types/profile';
import { getUserProfile, updateUserProfile } from '../lib/supabase/profile';
import { avatarCache } from '../lib/utils/avatarCache';
import { PROFILE_CACHE_DURATION, profileCache } from '../lib/cache/profile';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

// Retry configuration
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const fetchWithRetry = async <T,>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
};

export function useProfile() {
  const { user } = useAuth();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [matchesPlayed, setMatchesPlayed] = useState<number>(0);

  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    // Check if we already have profile data
    if (profile && !isLoading) {
      return;
    }
    let retries = MAX_RETRIES;
    const retryDelay = 1000;

    // Check cache first
    const cached = profileCache.get('current-user');
    if (cached && Date.now() - cached.timestamp < PROFILE_CACHE_DURATION) {
      console.log('Using cached profile');
      console.log('Cached profile data:', cached.data);
      setProfile(cached.data);
      setIsLoading(false);
      return;
    }

    try {
      const data = await fetchWithRetry(() => getUserProfile());
      if (!data) {
        console.log('No profile data found, using cached data if available');
        const cached = profileCache.get('current-user');
        if (cached) {
          setProfile(cached.data);
          return;
        }
        return;
      }


      // Fetch matches played count
      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user?.id)
        .single();

      if (!userData?.id) {
        console.warn('User not found, using cached data if available');
        const cached = profileCache.get('current-user');
        if (cached) {
          setProfile(cached.data);
          return;
        }
        return;
      }

      const today = new Date().toISOString().split('T')[0];
      const { count: playedCount, error: countError } = await supabase
        .from('activities')
        .select('*', { count: 'exact', head: true })
        .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
        .eq('status', 'confirmed')
        .lt('date', today);

      if (countError) {
        console.warn('Error fetching matches count:', countError);
        // Continue with profile update even if match count fails
      } else {
      setMatchesPlayed(playedCount || 0);
      }

      // Transform data to expected format
      const transformedData = {
        ...data,
        user_profiles: [{
          ...data.user_profiles?.[0],
          preferences: {
            ...data.user_profiles?.[0]?.preferences
          }
        }]
      };
      // Cache the profile data
      profileCache.set('current-user', {
        data: transformedData,
        timestamp: Date.now()
      });

      setProfile(transformedData);
      
      // Update avatar cache if needed
      if (user?.id && data.avatar_url) {
        avatarCache.setAvatar(user.id, data.avatar_url);
      }

    } catch (err) {
      console.error('Error fetching profile:', err);
      // Return cached data if available on network error
      if (err instanceof Error) {
        if (err.message.includes('Failed to fetch')) {
          console.log('Network error, attempting to use cached data');
          console.log('Network error, checking cache...');
          const cachedData = profileCache.get('current-user');
          if (cachedData) {
            console.log('Using cached profile data');
            setProfile(cachedData.data);
            if (cachedData.data.matchesPlayed) {
              setMatchesPlayed(cachedData.data.matchesPlayed);
            }
            // Don't show error to user if we have cached data
            return;
          }
          console.warn('No cached data available');
          return;
        } else if (err.message.includes('Not authenticated')) {
          setError('Please sign in to view your profile');
        } else {
          setError(err.message);
        }
      } else {
        setError('Failed to load profile');
      }
      setProfile(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateProfile = useCallback(async (updates: Partial<UserProfile>) => {
    setIsSaving(true);
    setSaveError(null);
    console.log('Updating profile with:', updates);
    let retries = MAX_RETRIES;

    try {
      while (retries > 0) {
        try {
          // If updating avatar_url, update cache first
          if (updates.avatar_url && user?.id) {
            avatarCache.setAvatar(user.id, updates.avatar_url);
          }

          await fetchWithRetry(() => updateUserProfile(updates));
          console.log('Profile update successful');
          
          // Clear cache to force refresh on next fetch
          profileCache.delete('current-user');
          
          await fetchProfile(); // Refresh profile data
          console.log('Profile data refreshed');
          return { success: true };
        } catch (err) {
          if (err instanceof Error && err.message.includes('Failed to fetch')) {
            retries--;
            if (retries > 0) {
              await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              continue;
            }
          }
          throw err;
        }
      }
      return { success: true };
    } catch (err) {
      console.error('Error updating profile:', err);
      // Handle network errors gracefully
      if (err instanceof Error && err.message.includes('Failed to fetch')) {
        setSaveError('Unable to save changes. Please check your connection.');
      } else {
        setSaveError(err instanceof Error ? err.message : 'Failed to update profile');
      }
      return { success: false, error: err instanceof Error ? err.message : 'Failed to update profile' };
    } finally {
      setIsSaving(false);
    }
  }, [fetchProfile]);

  return {
    profile,
    isLoading,
    error,
    isSaving,
    saveError,
    matchesPlayed,
    fetchProfile,
    updateProfile
  };
}