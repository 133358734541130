import { create } from 'zustand';
import { Invite, InviteFormData } from '../types/invite';
import { getRemainingInvites, sendInvite, getInvites } from '../lib/supabase/invites';
import { useNotificationStore } from './notificationStore';

interface InviteState {
  invites: Invite[];
  remainingInvites: number;
  isLoading: boolean;
  error: string | null;
  fetchInvites: () => Promise<void>;
  sendInvite: (data: InviteFormData) => Promise<void>;
  getRemainingInvites: () => Promise<void>;
}

export const useInviteStore = create<InviteState>((set, get) => ({
  invites: [],
  remainingInvites: 0,
  isLoading: false,
  error: null,

  fetchInvites: async () => {
    set({ isLoading: true, error: null });
    try {
      const invites = await getInvites();
      set({ invites, isLoading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to fetch invites',
        isLoading: false 
      });
    }
  },

  sendInvite: async (data: InviteFormData[]) => {
    set({ isLoading: true, error: null });
    try {
      // Validate input array
      if (!data?.length) {
        throw new Error('No invite data provided');
      }

      // Validate each invite
      data.forEach((invite, index) => {
        if (!invite.name?.trim()) {
          throw new Error(`Name is required for invite ${index + 1}`);
        }
        if (!invite.email?.trim()) {
          throw new Error(`Email is required for invite ${index + 1}`);
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(invite.email)) {
          throw new Error(`Invalid email format for invite ${index + 1}`);
        }
      });

      // Send all invites in parallel
      const invites = await sendInvite(data);
      
      set(state => ({
        invites: [...invites, ...state.invites],
        remainingInvites: state.remainingInvites - invites.length,
        isLoading: false
      }));

      // Add notification
      useNotificationStore.getState().addNotification({
        id: `invite_${Date.now()}`,
        type: 'new_message',
        title: 'Invites Sent',
        message: `${invites.length} invitation${invites.length === 1 ? '' : 's'} sent successfully`,
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/profile'
      });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to send invites',
        isLoading: false 
      });
      throw error;
    }
  },

  getRemainingInvites: async () => {
    try {
      const remaining = await getRemainingInvites();
      set({ remainingInvites: remaining });
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Failed to get remaining invites' });
    }
  }
}));