import React from 'react';
import { MapPin, UserX, Check } from 'lucide-react';
import { useConnectionStore } from '@/store/connectionStore';
import { cn } from '@/lib/utils';

const BlockedMembersList = () => {
  const { connections, unblockUser } = useConnectionStore();
  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  const blockedUsers = connections.filter((conn) => conn.status === 'blocked');

  if (blockedUsers.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <UserX className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <p className="text-dark-800">No blocked members</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {blockedUsers.map((connection) => (
        <div
          key={connection.connection_id}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
        >
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
            {/* Profile Image */}
            <div className="relative">
              <img
                src={connection.other_user?.avatar_url || defaultAvatar}
                alt={connection.other_user?.full_name || 'User'}
                className="w-20 h-20 rounded-full object-cover border-2 border-dark-400"
              />
            </div>

            {/* Info and Actions */}
            <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
              <div className="text-center sm:text-left">
                <div className="font-medium text-white">
                  {connection.other_user?.full_name}
                </div>
                <div className="flex items-center justify-center sm:justify-start text-dark-800 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  <span>{connection.other_user?.city}</span>
                </div>
                <div className="text-dark-800 mt-1">
                  Blocked on {new Date(connection.updated_at).toLocaleDateString()}
                </div>
              </div>

              <button
                onClick={() => unblockUser(connection.connection_id)}
                className={cn(
                  "p-2 rounded-lg transition-all duration-200",
                  "bg-green-500/10 hover:bg-green-500 text-green-500 hover:text-white",
                  "border border-green-500/20 hover:border-green-500",
                  "hover:scale-105 active:scale-95",
                  "sm:px-4 sm:py-2 flex items-center gap-2"
                )}
              >
                <Check className="h-5 w-5" />
                <span className="hidden sm:inline">Unblock</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlockedMembersList;