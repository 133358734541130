import { Message, Conversation } from '../types/chat';
import { useConnectionStore } from '../store/connectionStore'; // ✅ Corrected import
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { useState, useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook to fetch and cache the current user's database ID.
 */
function useUserDbId(user: { id: string } | null) {
  const [userDbId, setUserDbId] = useState<string | null>(null);

  useEffect(() => {
    if (!user?.id) return;

    (async () => {
      const { data, error } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();
      if (error) {
        console.error('Error fetching user DB id:', error);
      } else if (data) {
        setUserDbId(data.id);
      }
    })();
  }, [user?.id]);

  return userDbId;
}

function useChat(conversationId?: string) {
  // States for messages, conversations, and input
  const [messages, setMessages] = useState<Message[]>([]);
  // Separate loading states for conversations and messages
  const [isConversationsLoading, setIsConversationsLoading] = useState(true);
  const [isMessagesLoading, setIsMessagesLoading] = useState(true);
  // Combined loading state (for backward compatibility)
  const isLoading = isConversationsLoading || isMessagesLoading;

  const [error, setError] = useState<string | null>(null);
  const [messageInput, setMessageInput] = useState('');
  // The isTyping state is declared for future use
  const [isTyping, setIsTyping] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const { user } = useAuth();
  const { connections, fetchConnections } = useConnectionStore();
  const channelRef = useRef<ReturnType<typeof supabase.channel>>();
  const [isInitialized, setIsInitialized] = useState(false);

  // Fetch connections if they are not already loaded
  useEffect(() => {
    if (!connections || connections.length === 0) {
      console.warn("⚠️ No connections found, fetching now...");
      fetchConnections();
    }
  }, [connections, fetchConnections]);

  // Cache the current user's DB ID
  const userDbId = useUserDbId(user);

  // Debug logging (only during development)
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Chat state:', { conversations, isInitialized, messages, error });
    }
  }, [conversations, isInitialized, messages, error]);

  /**
   * Fetch conversations via an RPC call and transform the result to match our Conversation type.
   */
  const fetchConversations = useCallback(async () => {
    try {
      if (!user?.id) {
        console.log('No authenticated user');
        return;
      }
      setIsConversationsLoading(true);
      console.log('Fetching conversations...');

      const { data, error: fetchError } = await supabase.rpc('get_conversations', {
        user_auth_id: user.id,
      });
      console.log('Conversations response:', { data, error: fetchError });

      if (fetchError && Object.keys(fetchError).length > 0) throw fetchError;

      const transformedConversations = (data || []).map((conv: any) => ({
        id: conv.conversation_id,
        name: conv.other_user_name,
        image:
          conv.other_user_image ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        city: conv.other_user_city,
        lastMessage: conv.last_message,
        time: conv.last_message_at,
        unread: Number(conv.unread_count),
        online: false, // Placeholder – can be enhanced with realtime presence
        otherUserId: conv.other_user?.id,
      }));

      setConversations(transformedConversations);
      setIsInitialized(true);
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError(err instanceof Error ? err.message : 'Failed to load conversations');
    } finally {
      setIsConversationsLoading(false);
    }
  }, [user?.id]);

  /**
   * Fetch messages for a given conversation.
   */
  const fetchMessages = useCallback(async (convId?: string) => {
    if (!convId) return;
    console.log('Fetching messages for conversation:', convId);
    try {
      setIsMessagesLoading(true);
      setError(null);

      const { data, error: messagesError } = await supabase
        .from('messages')
        .select(
          `
            id,
            content,
            created_at,
            read_at,
            sender_id,
            users!messages_sender_id_fkey (
              id,
              auth_id,
              full_name,
              avatar_url
            )
          `
        )
        .eq('conversation_id', convId)
        .order('created_at', { ascending: true });

      if (messagesError) throw messagesError;
      console.log('Fetched messages:', data);

      const transformedMessages = (data || []).map((msg: any) => ({
        id: msg.id,
        content: msg.content,
        time: msg.created_at,
        isSender: userDbId ? msg.sender_id === userDbId : false,
        status: msg.read_at ? 'read' : 'delivered',
      }));

      console.log('Transformed messages:', transformedMessages);
      setMessages(transformedMessages);
      setError(null);
      return transformedMessages;
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(err instanceof Error ? err.message : 'Failed to load messages');
      return [];
    } finally {
      setIsMessagesLoading(false);
    }
  }, [userDbId]);

  // Automatically fetch messages whenever the conversationId changes.
  useEffect(() => {
    if (conversationId) {
      console.log('Conversation ID changed, fetching messages:', conversationId);
      fetchMessages(conversationId);
    }
  }, [conversationId, fetchMessages]);

  /**
   * Send a message with an optimistic UI update.
   * With the new design, we assume the conversation always exists.
   */
  const handleSendMessage = useCallback(async (convId: string) => {
    const trimmedMessage = messageInput.trim();
    if (!trimmedMessage) return;

    console.log('🚀 Sending message in conversation:', convId, 'Message:', trimmedMessage);

    if (!user?.id) {
      console.error('❌ No authenticated user');
      return;
    }

    try {
      if (!userDbId) throw new Error('❌ Failed to get user data');

      // Directly insert the message, assuming the conversation exists.
      console.log('🚀 Inserting message:', {
        conversation_id: convId,
        sender_id: userDbId,
        content: trimmedMessage,
      });

      const { data: message, error: sendError } = await supabase
        .from('messages')
        .insert({
          conversation_id: convId,
          sender_id: userDbId,
          content: trimmedMessage,
          created_at: new Date().toISOString(),
        })
        .select()
        .single();

      if (sendError) {
        console.error('❌ Error inserting message:', sendError);
        throw sendError;
      }

      console.log('✅ Message sent successfully:', message);

  // **Step 3: CLEAR THE INPUT FIELD AFTER SENDING MESSAGE**
    setMessageInput('');
      
      // Update last message in the conversation
      await supabase
        .from('conversations')
        .update({
          last_message: trimmedMessage,
          last_message_at: new Date().toISOString(),
        })
        .eq('id', convId);

      console.log('✅ Updated last message timestamp.');

      // Fetch updated messages after a short delay
      setTimeout(() => fetchMessages(convId), 500); //reduce timeout?
    } catch (err) {
      console.error('❌ handleSendMessage failed:', err);
      setError(err instanceof Error ? err.message : 'Failed to send message.');
    }
  }, [messageInput, user, userDbId, fetchMessages]);

  /**
   * Mark a message as read.
   */
  const handleMarkAsRead = useCallback(async (messageId: string) => {
    try {
      await supabase.rpc('mark_message_as_read', { message_id: messageId });
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === messageId ? { ...msg, status: 'read' } : msg
        )
      );
    } catch (err) {
      console.error('Error marking message as read:', err);
    }
  }, []);

  /**
   * Set up a realtime subscription for incoming messages.
   */
  useEffect(() => {
    console.log('Setting up realtime subscription for messages');
    if (channelRef.current) {
      console.log('Removing existing channel subscription');
      supabase.removeChannel(channelRef.current);
    }

    const getUserId = async () => {
      if (userDbId) return userDbId;
      if (user?.id) {
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();
        return userData?.id;
      }
      return null;
    };

    channelRef.current = supabase
      .channel('messages')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
          filter: conversationId ? `conversation_id=eq.${conversationId}` : undefined,
        },
        async (payload) => {
          console.log('New message received:', payload);
          const currentUserId = await getUserId();
          if (!currentUserId) return;

          // Only process messages not sent by the current user
          if (payload.new.sender_id !== currentUserId) {
            const newMessage = {
              id: payload.new.id,
              content: payload.new.content,
              time: new Date(payload.new.created_at).toISOString(),
              isSender: false,
              status: 'delivered',
            };

            setMessages((prev) => [...prev, newMessage]);
            handleMarkAsRead(newMessage.id);
          }
        }
      )
      .subscribe();

    console.log('Message subscription set up successfully');
    return () => {
      if (channelRef.current) {
        console.log('Cleaning up message subscription');
        supabase.removeChannel(channelRef.current);
      }
    };
  }, [conversationId, handleMarkAsRead, userDbId, user?.id]);

  // Fetch conversations on mount.
  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  return {
    messages,
    isLoading,
    error,
    messageInput,
    setMessageInput,
    isTyping, // Reserved for future enhancements (e.g., typing indicators)
    conversations,
    isInitialized,
    handleSendMessage,
    handleMarkAsRead,
    // The createConversation function is now less critical since a conversation is created upon connection acceptance.
    // createConversation,
  };
}

export { useChat };
