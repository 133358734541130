import React from 'react';
import { Loader2, X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DeclineButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isDeclined?: boolean;
  className?: string;
}

const DeclineButton: React.FC<DeclineButtonProps> = ({ 
  onClick, 
  isLoading,
  isDeclined,
  className
}) => (
  <button
    onClick={onClick}
    disabled={isLoading || isDeclined}
    className={cn(
      "px-4 py-2 rounded-lg transition-all duration-300",
      "flex items-center space-x-2",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      "transform active:scale-95",
      isDeclined
        ? "bg-red-500/20 text-red-500 border border-red-500/20"
        : "bg-red-500 hover:bg-red-600 text-white",
      className
    )}
  >
    {isLoading ? (
      <>
        <Loader2 className="h-4 w-4 animate-spin mr-2" />
        <span>Declining...</span>
      </>
    ) : isDeclined ? (
      <>
        <X className="h-4 w-4 mr-2" />
        <span>Declined</span>
      </>
    ) : (
      <span>Decline</span>
    )}
  </button>
);

export default DeclineButton;