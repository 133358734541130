import React from 'react';
import { Users, FileText } from 'lucide-react';
import { CommandItem } from '../ui/command';
import { cn } from '@/lib/utils';

interface SearchResultItemProps {
  item: {
    id: string;
    name: string;
    image?: string;
    jobTitle?: string;
    company?: string;
  };
  type: 'player' | 'post';
  onSelect: (type: string, id: string) => void;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  item,
  type,
  onSelect,
}) => {
  return (
    <CommandItem onSelect={() => onSelect(type, item.id)}>
      <div className="flex items-center space-x-3">
        {type === 'player' ? (
          <img
            src={item.image || '/default-avatar.png'}
            alt={item.name}
            className="w-8 h-8 rounded-full object-cover border border-dark-400"
          />
        ) : (
          <FileText className="h-4 w-4 text-gold-400" />
        )}
        <div>
          <p className="text-white font-medium">{item.name}</p>
          {type === 'player' && (
            <p className="text-sm text-gray-400">
              {item.jobTitle
                ? `${item.jobTitle} at ${item.company}`
                : 'No Job Info'}
            </p>
          )}
        </div>
      </div>
    </CommandItem>
  );
};

export default SearchResultItem;
