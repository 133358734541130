import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Menu, X, Dumbbell, Users,
  Settings, UserPlus, Plus, Sun, Moon, MapPin,
  ScrollText, Search, MessageSquareText,
  HelpCircle, LogOut, PanelLeftClose, PanelLeft
} from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { InviteModal } from './InviteModal';
import QuickMatchModal from './matchmaking/QuickMatchModal';
import NotificationsDropdown from './notifications/NotificationsDropdown';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';
import BlurOverlay from './ui/BlurOverlay';
import { useAuth } from '../contexts/AuthContext';
import FeedbackModal from './feedback/FeedbackModal';
import SupportModal from './support/SupportModal';
import { avatarCache } from '@/lib/utils/avatarCache';
import ProfileButton from './header/ProfileButton';
import { cn } from '@/lib/utils';
import BottomNavigation from './navigation/BottomNavigation';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => 
    window.innerWidth >= 1024
  );
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showQuickMatch, setShowQuickMatch] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const { logout } = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isTrainingSection = location.pathname === '/dashboard/training';

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.getElementById('sidebar');
      const toggleButton = document.querySelector('[aria-label="Open sidebar"]');
      
      if (
        sidebar && 
        !sidebar.contains(event.target as Node) && 
        !toggleButton?.contains(event.target as Node) &&
        window.innerWidth < 1024
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMenuToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSearchClick = () => {
    navigate('/dashboard/search');
  };

  const handleLogout = () => {
    // Immediately navigate to landing page and end session
    Promise.resolve(logout()).then(() => {
      navigate('/', { replace: true });
    });
  };

  const navigationItems = [
    { name: 'Players', icon: Users, path: '/dashboard/match' },
    { name: 'Invite', icon: UserPlus, path: '', onClick: () => setShowInviteModal(true) },
    { name: 'Feedback', icon: MessageSquareText, onClick: () => setShowFeedback(true) },
    { name: 'Support', icon: HelpCircle, onClick: () => setShowSupport(true) },
    { name: 'Settings', icon: Settings, onClick: () => navigate('/dashboard/settings') }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 h-16 bg-app/80 backdrop-blur-md border-b border-dark-300 z-50">
        <div className="h-full flex items-center">
          {/* Logo Section */}
          <div className={cn(
            "hidden lg:flex items-center px-6 w-64"
          )}>
            <ThemeAwareLogo
              height={32}
              className="cursor-pointer"
              onClick={() => navigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>

          {/* Mobile logo */}
          <div className="lg:hidden flex items-center px-4 flex-1">
            <ThemeAwareLogo
              height={28}
              className="cursor-pointer"
              onClick={() => navigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>

          {/* Right section */}
          <div className="flex items-center gap-2 px-4 ml-auto">
            <button
              onClick={handleSearchClick}
              className="p-1.5 hover:bg-app-darker rounded-full transition-colors focus:outline-none"
              aria-label="Search"
            >
              <Search className="h-5 w-5" />
            </button>

            <NotificationsDropdown />
            <ProfileButton />

            <button
              onClick={handleMenuToggle}
              className="lg:hidden p-1.5 text-app-muted hover:text-app"
            >
              {isSidebarOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
            </button>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <div
        id="sidebar"
        className={cn(
          "fixed inset-y-0 left-0 z-40 bg-app border-r border-dark-300 transform transition-all duration-300 ease-in-out mt-16",
          isSidebarOpen ? "w-64" : "w-20",
          "lg:translate-x-0",
          !isSidebarOpen && "lg:translate-x-0",
          !isSidebarOpen && !window.matchMedia('(min-width: 1024px)').matches && "-translate-x-full"
        )}
      >
        {/* Sidebar Toggle Button */}
        <button
          onClick={handleMenuToggle}
          className={cn(
            "absolute right-0 top-2 p-2 transform translate-x-1/2",
            "bg-app border border-dark-300 rounded-full",
            "hover:bg-app-darker transition-colors",
            "lg:flex hidden" // Only show on large screens
          )}
          aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
        >
          {isSidebarOpen ? (
            <PanelLeftClose className="h-4 w-4" />
          ) : (
            <PanelLeft className="h-4 w-4" />
          )}
        </button>

        <div className="h-full flex flex-col">
          {/* Navigation */}
          <nav className="flex-1 px-4 py-6 space-y-1">
            {navigationItems.map((item) => (
              <button
                key={item.name}
                onClick={item.onClick || (() => navigate(item.path))}
                className={cn(
                  "w-full flex items-center space-x-3 px-4 py-3 rounded-xl transition-colors",
                  location.pathname === item.path
                    ? "bg-dark-300 text-gold-400"
                    : "text-dark-800 hover:bg-dark-300 hover:text-white",
                  !isSidebarOpen && "justify-center"
                )}
              >
                <item.icon className="h-5 w-5" />
                {isSidebarOpen && <span>{item.name}</span>}
              </button>
            ))}

            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl text-dark-800 hover:bg-dark-300 hover:text-white transition-colors",
                !isSidebarOpen && "justify-center"
              )}
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
              {isSidebarOpen && <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>}
            </button>

            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl text-red-400 hover:bg-dark-300 transition-colors",
                !isSidebarOpen && "justify-center"
              )}
            >
              <LogOut className="h-5 w-5" />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className={cn(
        "pt-20 pb-24 transition-all duration-300",
        isSidebarOpen ? "lg:pl-64" : "lg:pl-20"
      )}>
        <main className="relative min-h-[calc(100vh-9rem)]">
          <div className="p-4 lg:p-8">
            {children}
          </div>
        </main>
      </div>

      {/* Bottom Navigation */}
      <BottomNavigation />

      {/* Modals */}
      <InviteModal 
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
      <QuickMatchModal
        isOpen={showQuickMatch}
        onClose={() => setShowQuickMatch(false)}
      />
      <FeedbackModal
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
      <SupportModal
        isOpen={showSupport}
        onClose={() => setShowSupport(false)}
      />
    </div>
  );
};

export default DashboardLayout;