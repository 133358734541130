// src/components/chat/layout/Sidebar.tsx

import React, { useState, useEffect } from 'react';
import { Search, Users2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import ConversationList from '../conversations/ConversationList';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '@/contexts/ThemeContext';

interface SidebarProps {
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
  conversations: any[];
  createConversation?: (connectionId: string) => Promise<{ id: string } | null>;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeChat,
  onChatSelect,
  conversations,
  createConversation,
}) => {
  const { isDarkMode } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  // Filter conversations based on search term
  const filteredConversations = conversations.filter(conv => {
    if (!searchTerm) return true;
    return (
      conv.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      conv.city?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className={cn(
      "flex flex-col h-full",
      isDarkMode 
        ? "bg-dark-200/95 border-dark-300" 
        : "bg-light-100/95 border-light-200",
      "backdrop-blur-sm border-r",
      "relative"
    )}>
      {/* Search Header */}
      <div className={cn(
        "flex-shrink-0 p-4",
        isDarkMode 
          ? "border-dark-300 bg-dark-200/80" 
          : "border-light-200 bg-light-100/80",
        "border-b backdrop-blur-md",
        "sticky top-0 z-10",
        "transition-all duration-300",
        isFocused && "shadow-lg shadow-dark-900/5"
      )}>
        <div className={cn(
          "relative group",
          "transition-all duration-300"
        )}>
          <Search className={cn(
            "absolute left-3 top-1/2 transform -translate-y-1/2",
            "h-4 w-4",
            "transition-colors duration-200",
            isFocused 
              ? "text-gold-400" 
              : isDarkMode ? "text-dark-800" : "text-light-600",
            "group-hover:text-gold-400/70"
          )} />
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={cn(
              "w-full pl-10 pr-4 py-3",
              isDarkMode 
                ? "bg-dark-300 border-dark-400" 
                : "bg-light-200 border-light-300",
              "rounded-xl",
              isDarkMode 
                ? "text-white placeholder-dark-800" 
                : "text-light-900 placeholder-light-600",
              "text-sm font-medium",
              "transition-all duration-200",
              "focus:outline-none focus:ring-2 focus:ring-gold-400/50",
              "hover:border-gold-400/30",
              "shadow-sm hover:shadow-md"
            )}
          />
          <div className={cn(
            "absolute inset-0 -m-1",
            "rounded-xl",
            "pointer-events-none",
            "transition-opacity duration-200",
            "opacity-0 group-focus-within:opacity-100",
            "bg-gradient-to-r from-gold-400/10 to-gold-500/10"
          )} />
        </div>
      </div>

      {/* Conversations List */}
      <AnimatePresence mode="wait">
        {filteredConversations.length > 0 ? (
          <motion.div 
            className="flex-1 overflow-y-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ConversationList
              conversations={filteredConversations}
              activeChat={activeChat}
              onChatSelect={onChatSelect}
            />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-col items-center justify-center flex-1 p-8 text-center"
          >
            <div className="w-16 h-16 mb-4 rounded-full bg-dark-300 flex items-center justify-center">
              <Users2 className="w-8 h-8 text-dark-800" />
            </div>
            <h3 className="text-lg font-medium text-white mb-2">
              No conversations found
            </h3>
            <p className="text-dark-800 text-sm max-w-[240px]">
              {searchTerm 
                ? "Try adjusting your search terms"
                : "Start a conversation with your connections"}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Sidebar;
