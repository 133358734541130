import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PostComposer from './PostComposer';
import { useNotificationStore } from '../../store/notificationStore';
import { postsCache } from '../../lib/cache';
import PostSkeleton from './PostSkeleton';
import FeedHeader from './FeedHeader';
import PostList from './PostList';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import WelcomeMessage from '../dashboard/WelcomeMessage';
import { getPosts, createPost, subscribeToNewPosts } from '../../lib/supabase/posts';
import { Post } from '../../types/post';
import { Loader2, RefreshCw, Filter, TrendingUp, Clock, Star, PenSquare } from 'lucide-react';
import { cn } from '@/lib/utils';

const SocialFeed: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState('recent');
  const [showComposer, setShowComposer] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [postError, setPostError] = useState<string | null>(null);
  const loadingMoreRef = useRef(false);
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [lastPostId, setLastPostId] = useState<string | undefined>();
  const postsRef = useRef<Set<string>>(new Set());
  const postTimeoutRef = useRef<NodeJS.Timeout>();
  const parentRef = useRef<HTMLDivElement>(null);
  const { addNotification } = useNotificationStore();

  const INITIAL_BATCH_SIZE = 5;
  const SUBSEQUENT_BATCH_SIZE = 10;
  
  const { page, loading, hasMore, setHasMore, lastElementRef, reset } = useInfiniteScroll({
    totalItems: posts.length,
    itemsPerPage: SUBSEQUENT_BATCH_SIZE,
    preloadThreshold: 0.5
  });

  const loadPosts = React.useCallback(async () => {
    const totalStartTime = Date.now();
    const handleError = (error: unknown, context: string) => {
      setError(error instanceof Error ? error.message : 'Failed to load posts');
      if (error instanceof Error && error.message === 'Failed to fetch') {
        const cached = postsCache.get('feed');
        if (cached) {
          return cached;
        }
      }
      return [];
    };

    try {
      if (!lastPostId) {
        setIsInitialLoading(true);
      } else {
        setIsFetchingMore(true);
      }

      const fetchedPosts = await getPosts(lastPostId);
      const isInitialLoad = !lastPostId;
      
      if (fetchedPosts.length > 0) {
        setLastPostId(fetchedPosts[fetchedPosts.length - 1].id);
        // Only set hasMore if we got a full batch
        const batchSize = isInitialLoad ? INITIAL_BATCH_SIZE : SUBSEQUENT_BATCH_SIZE;
        if (fetchedPosts.length < batchSize) {
          setHasMore(false);
        }
      }
      
      // Deduplicate posts when appending
      setPosts(prev => {
        if (lastPostId) {
          // Create a Set of existing post IDs for O(1) lookup
          const existingIds = new Set(prev.map(p => p.id));
          // Only append posts that don't already exist
          const newPosts = fetchedPosts.filter(p => !existingIds.has(p.id));
          return [...prev, ...newPosts];
        }
        return fetchedPosts;
      });
      
      setError(null);
      return fetchedPosts;
    } catch (error) {
      return handleError(error, 'Error loading posts');
    } finally {
      setIsInitialLoading(false);
      setIsFetchingMore(false);
    }
  }, [lastPostId]);

  const handleRefresh = useCallback(async () => {
    if (isRefreshing) return;
    
    setNetworkError(null);
    setIsRefreshing(true);
    setLastPostId(undefined); // Reset last post ID
    try {
      reset();
      const newPosts = await loadPosts();
      setPosts(newPosts || []);
    } catch (error) {
      console.error('Error refreshing feed:', error);
      if (error instanceof Error && error.message.includes('Failed to fetch')) {
        setNetworkError('Unable to connect to server. Please check your connection.');
      }
    } finally {
      setIsRefreshing(false);
    }
  }, [loadPosts, addNotification, isRefreshing]);

  const handleNewPost = async (content: string, image?: File) => {
    try {
      setIsPosting(true);
      setPostError(null);

      // Create post
      const newPost = await createPost(content, image);
      
      // Add to posts list
      setPosts(prev => [newPost, ...prev]);
      
      // Show success notification
      addNotification({
        id: `post_${Date.now()}`,
        type: 'new_message',
        title: 'Post Created',
        message: 'Your post has been published successfully!',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });

      // Reset composer state
      setShowComposer(false);
      
    } catch (error) {
      console.error('Failed to create post:', error);
      setPostError(error instanceof Error ? error.message : 'Failed to create post');
      
      // Show error notification
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to create post',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });
      
    } finally {
      setIsPosting(false);
    }
  };

  useEffect(() => {
    loadPosts();

    // Clear any existing timeout
    if (postTimeoutRef.current) {
      clearTimeout(postTimeoutRef.current);
    }

    // Subscribe to new posts
    const cleanup = subscribeToNewPosts((newPost) => {
      // Check if we've already seen this post
      if (postsRef.current.has(newPost.id)) {
        return;
      }
      
      // Add post with a delay to prevent duplicates
      postTimeoutRef.current = setTimeout(() => {
        postsRef.current.add(newPost.id);
        setPosts(prev => {
          // Double-check we haven't already added this post
          if (prev.some(p => p.id === newPost.id)) {
            return prev;
          }
          return [newPost, ...prev];
        });
      }, 500); // Half second delay
    });

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
      // Clear timeout on cleanup
      if (postTimeoutRef.current) {
        clearTimeout(postTimeoutRef.current);
      }
      // Clear post tracking set
      postsRef.current.clear();
    };
  }, [loadPosts]);

  // Get current page of posts
  const currentPosts = useMemo(() => {
    return posts.slice(0, page * SUBSEQUENT_BATCH_SIZE);
  }, [posts, page]);

  // Load more posts when reaching bottom
  useEffect(() => {
    if (loading && !loadingMoreRef.current && hasMore) {
      loadingMoreRef.current = true;
      loadPosts().finally(() => {
        loadingMoreRef.current = false;
      });
    }
  }, [loading, hasMore, loadPosts]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6 w-full"
    >
      {/* Welcome Message */}
      <WelcomeMessage />
      
      {/* Feed Header */}
      <motion.div 
        className="max-w-4xl mx-auto text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 mb-6">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
               
                
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setActiveFilter('trending')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'trending'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <TrendingUp className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Trending</span>
                </button>
                <button
                  onClick={() => setActiveFilter('recent')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'recent'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <Clock className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Recent</span>
                </button>
                <button
                  onClick={() => setActiveFilter('top')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'top'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <Star className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Top</span>
                </button>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <motion.button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={cn(
                  "p-2 rounded-lg transition-colors",
                  "hover:bg-dark-300 text-dark-800 hover:text-white",
                  "active:scale-95 transform",
                  isRefreshing && "animate-spin"
                )}
                title="Refresh feed"
              >
                <RefreshCw className="h-5 w-5" />
              </motion.button>
              <button
                onClick={() => setShowComposer(true)}
                className={cn(
                  "flex items-center justify-center h-10 w-10",
                  "bg-gold-400 hover:bg-gold-500 text-dark-50",
                  "rounded-lg transition-colors",
                  "hover:scale-105 active:scale-95"
                )}
              >
                <PenSquare className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Post Composer - Only show when expanded */}
      {showComposer && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <PostComposer 
            onPost={handleNewPost}
            onCancel={() => setShowComposer(false)}
            isSubmitting={isPosting}
          />
        </motion.div>
      )}

      {/* Error State */}
      {error && (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
          <p>{networkError || error}</p>
          <button
            onClick={handleRefresh}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      )}

      {/* Loading State */}
      {isInitialLoading && (
        <PostSkeleton count={3} />
      )}

      {/* Post List */}
      {!isInitialLoading && (
        <div 
          ref={parentRef}
          className="relative min-h-[200px]"
        >
          <PostList posts={currentPosts} activeFilter={activeFilter} />
          {/* Loading indicator */}
          {isFetchingMore && (
            <div className="mt-6">
              <PostSkeleton count={SUBSEQUENT_BATCH_SIZE} />
            </div>
          )}
          {/* Invisible element for intersection observer */}
          {hasMore && <div ref={lastElementRef} className="h-1" />}
        </div>
      )}
    </motion.div>
  );
};

export default SocialFeed;