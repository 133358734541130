import { supabase } from '../supabase';
import { OnboardingData } from '../../types/onboarding';

export async function signUpWithEmail(
  email: string,
  password: string,
  userData: OnboardingData
) {
  console.log('🔍 Starting signup process:', { email, userData });

  try {
    if (!email || !password || !userData.fullName || !userData.city) {
      throw new Error('Missing required user data');
    }

    // Check if user already exists
    const { data: { user: existingUser }, error: userError } = await supabase.auth.getUser();

    if (userError && userError.message !== 'Failed to fetch') {
      console.error('❌ Error checking user:', userError);
      throw userError;
    }

    let authData;
    if (existingUser) {
      const { data, error: updateError } = await supabase.auth.updateUser({ password });

      if (updateError) {
        console.error('❌ Error updating user:', updateError);
        throw updateError;
      }

      authData = { user: existingUser };

      // Ensure session is valid
      const { error: sessionError } = await supabase.auth.refreshSession();
      if (sessionError) {
        console.error('❌ Session refresh error:', sessionError);
        throw sessionError;
      }
    } else {
     // Create new user if not invited
const { data, error: signUpError } = await supabase.auth.signUp({
  email,
  password,
  options: {
    data: { 
      full_name: userData.fullName,
      inviter_name: inviterName // Add inviter's name to metadata
    },
    emailRedirectTo: window.location.origin,
  },
});


      if (signUpError) {
        console.error('❌ Signup error:', signUpError);
        throw signUpError;
      }

      authData = data;

      // Sign in immediately after signup
      const { error: signInError } = await supabase.auth.signInWithPassword({ email, password });

      if (signInError) {
        console.error('❌ Auto sign-in error:', signInError);
        throw signInError;
      }
    }

    if (!authData?.user) {
      console.error('❌ No user data returned from signup');
      throw new Error('Failed to create user account');
    }

    // Create user profile in `users` table
    const { data: profileData, error: profileError } = await supabase
      .from('users')
      .upsert([
        {
          auth_id: authData.user.id,
          full_name: userData.fullName,
          city: userData.city,
          company: userData.company,
          job_type: userData.jobTitle,
          avatar_url: userData.profilePic,
          skill_level: 'beginner',
          facebook_url: userData.facebookUrl || null,
          linkedin_url: userData.linkedinUrl || null,
          instagram_url: userData.instagramUrl || null,
        },
      ], { onConflict: 'auth_id' })
      .select()
      .single();

    if (profileError) {
      console.error('❌ Profile creation error:', profileError);
      if (!existingUser) await supabase.auth.admin.deleteUser(authData.user.id);
      throw new Error('Failed to create user profile');
    }

    console.log("✅ User profile created:", profileData);

    return authData;
  } catch (error) {
    console.error('❌ Error in signUpWithEmail:', error);
    throw error instanceof Error ? error : new Error('Unexpected error during signup');
  }
}

// **🚀 Updated fetchUserProfile Function (Ensures Social Links Are Fetched)**
export async function fetchUserProfile(userId: string) {
  console.log("🔍 Fetching profile for user_id:", userId);

  const { data: profile, error } = await supabase
    .from('users')
    .select(`
      id,
      auth_id,
      full_name,
      avatar_url,
      city,
      company,
      job_type,
      skill_level,
      facebook_url,
      linkedin_url,
      instagram_url
    `)
    .eq('id', userId)
    .maybeSingle();

  console.log("🔍 Raw API Response from Supabase:", profile); // 🔴 Check if social links exist in response

  if (error) {
    console.error("❌ Error fetching profile:", error);
    return null;
  }

  if (!profile) {
    console.warn("⚠️ No profile found for user_id:", userId);
    return null;
  }

  console.log("✅ Extracted Social Links:", {
    linkedinUrl: profile.linkedin_url,
    facebookUrl: profile.facebook_url,
    instagramUrl: profile.instagram_url
  });

  return profile;
}


export async function signInWithEmail(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });

  if (error) throw error;
  return data;
}
