import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { OnboardingStepProps } from '../types';
import { getSports, Sport } from '../../../lib/supabase/sports';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { cn } from '@/lib/utils';

// Define the category order to maintain UI consistency
const CATEGORY_ORDER = [
  'Racket Sports',
  'Fitness & Wellness',
  'Ball Sports',
  'Individual Sports',
  'Combat Sports',
  'Equestrian',
  'Water Sports',
];

const SportsSelectionStep: React.FC<OnboardingStepProps> = ({
  data,
  onUpdate,
  onNext,
  onBack,
}) => {
  // selectedSports now stores an array of sport IDs.
  const selectedSports: string[] = data?.sports || [];
  const [sports, setSports] = useState<Sport[]>([]);
  const [groupedSports, setGroupedSports] = useState<Record<string, Sport[]>>(
    {}
  );
  const [activeCategory, setActiveCategory] = useState(CATEGORY_ORDER[0]);

  useEffect(() => {
    async function fetchData() {
      const fetchedSports = await getSports();
      setSports(fetchedSports);

      // Group sports by their category.
      const groups = fetchedSports.reduce((acc, sport) => {
        const cat = sport.category || 'Others';
        if (!acc[cat]) acc[cat] = [];
        acc[cat].push(sport);
        return acc;
      }, {} as Record<string, Sport[]>);
      setGroupedSports(groups);

      // Ensure the active category is one that exists.
      if (!groups[activeCategory]) {
        const firstAvailable = CATEGORY_ORDER.find((cat) => groups[cat]);
        if (firstAvailable) setActiveCategory(firstAvailable);
      }
    }
    fetchData();
  }, [activeCategory]);

  const toggleSport = (sportId: string) => {
    const newSelection = selectedSports.includes(sportId)
      ? selectedSports.filter((id) => id !== sportId)
      : [...selectedSports, sportId];
    onUpdate?.({ sports: newSelection });
  };

  // In this step, we simply validate selection. Actual insertion is delayed.
  const handleNext = () => {
    if (selectedSports.length === 0) {
      console.error('Please select at least one sport');
      return;
    }
    onNext();
  };

  // Determine which categories (from our fixed order) have sports.
  const categories = CATEGORY_ORDER.filter(
    (cat) => groupedSports[cat] && groupedSports[cat].length > 0
  );
  const displayedSports = groupedSports[activeCategory] || [];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full flex flex-col bg-dark-200 overflow-hidden pt-4 sm:pt-6"
    >
      <div className="flex-shrink-0 text-center mb-4 sm:mb-6 px-2 sm:px-4">
        <motion.h2
          className={cn(
            'text-xl xs:text-2xl sm:text-3xl md:text-4xl font-bold',
            'text-transparent bg-clip-text',
            'bg-gradient-to-r from-white via-gold-200 to-gold-400',
            'mb-2'
          )}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What do you play?
        </motion.h2>
        <p className="text-dark-800 text-xs sm:text-sm md:text-base">
          {selectedSports.length}{' '}
          {selectedSports.length === 1 ? 'sport' : 'sports'} selected
        </p>
      </div>

      {/* Categories */}
      <div className="relative flex-shrink-0 mb-3 sm:mb-4">
        <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-dark-200 to-transparent pointer-events-none z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-dark-200 to-transparent pointer-events-none z-10" />
        <div className="flex overflow-x-auto pb-1 sm:pb-2 px-2 sm:px-4 scrollbar-none relative">
          <div className="flex space-x-2 mx-auto">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setActiveCategory(category)}
                className={cn(
                  'flex items-center space-x-1 sm:space-x-2',
                  'px-3 sm:px-4 py-1.5 sm:py-2',
                  'rounded-lg whitespace-nowrap',
                  'transition-all duration-300',
                  'text-xs sm:text-sm font-medium border-2',
                  'relative',
                  'after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2',
                  'after:w-1.5 after:h-1.5 after:rounded-full',
                  'after:transition-all after:duration-300',
                  activeCategory === category
                    ? [
                        'bg-gradient-to-br from-gold-400 to-gold-500',
                        'text-dark-50 border-gold-300',
                        'after:bg-gold-400',
                      ]
                    : [
                        'bg-dark-300/30 text-white',
                        'hover:bg-dark-400/50',
                        'border-dark-400/30 hover:border-gold-400/30',
                        'after:bg-transparent',
                      ]
                )}
              >
                {/* Use the first sport’s emoji for the category button */}
                <span className="text-base sm:text-xl">
                  {groupedSports[category] && groupedSports[category].length > 0
                    ? groupedSports[category][0].emoji
                    : ''}
                </span>
                <span>{category}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Sports Grid */}
      <div className="flex-1 overflow-y-auto min-h-0">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="grid grid-cols-3 sm:grid-cols-4 gap-3 px-4"
          >
            {displayedSports.map((sport) => (
              <motion.button
                key={sport.id}
                onClick={() => toggleSport(sport.id)}
                className={cn(
                  'relative group p-2 rounded-lg transition-colors duration-300',
                  'border-2',
                  selectedSports.includes(sport.id)
                    ? 'bg-gradient-to-br from-gold-400 to-gold-500 text-dark-50 border-gold-300'
                    : 'bg-dark-300/30 text-white hover:bg-dark-400/50 border-transparent hover:border-gold-400/30'
                )}
              >
                <div className="flex flex-col items-center space-y-2">
                  <motion.span
                    className="text-2xl block"
                    whileHover={{ scale: 1.2 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                  >
                    {sport.emoji}
                  </motion.span>
                  <span className="text-sm font-medium text-center">
                    {sport.name}
                  </span>
                </div>
              </motion.button>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>

      <FloatingNavigation
        onBack={onBack}
        onNext={handleNext}
        isNextDisabled={selectedSports.length === 0}
      />
    </motion.div>
  );
};

export default SportsSelectionStep;
