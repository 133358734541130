import { supabase } from '../supabase';
import { Connection, ConnectionStatus } from '@/types/connection';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (
      retries > 0 &&
      error instanceof Error &&
      error.message.includes('Failed to fetch')
    ) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

// 🟢 Fetch authenticated user's `user_id` instead of `auth_id`
async function getAuthenticatedUserId(): Promise<string | null> {
  const {
    data: { user },
    error: authError,
  } = await supabase.auth.getUser();

  if (authError) {
    console.error('❌ Error fetching authenticated user:', authError);
    return null;
  }

  if (!user?.id) {
    console.error('❌ No authenticated user found!');
    return null;
  }

  const { data: userData, error: userError } = await supabase
    .from('users')
    .select('id')
    .eq('auth_id', user.id)
    .single();

  if (userError) {
    console.error('❌ Error fetching user ID from database:', userError);
    return null;
  }

  console.log('✅ Resolved user_id:', userData.id);
  return userData.id;
}

// 🟢 Fetch user connections using `getuserconnections` RPC function
export async function getConnections(): Promise<Connection[]> {
  try {
    console.log('📡 Fetching user connections...');
    const userId = await getAuthenticatedUserId();
    if (!userId) return [];

    const { data, error } = await fetchWithRetry(
      () => supabase.rpc('getuserconnections', { _user_id: userId }) // ✅ Fix applied here
    );

    if (error) throw error;

    console.log('✅ Connections fetched successfully:', data.length);
    return data;
  } catch (error) {
    console.error('❌ Error fetching connections:', error);
    return [];
  }
}

// 🟢 Send connection request via `createConnectionRequest` RPC function
export async function sendConnectionRequest(
  receiverUserId: string
): Promise<Connection | null> {
  try {
    console.log('📡 Sending connection request to user_id:', receiverUserId);

    const userId = await getAuthenticatedUserId();
    if (!userId) throw new Error('Not authenticated');

    console.log('Initiator ID', userId);
    console.log('Receiver ID', receiverUserId);
    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('createconnectionrequest', {
        initiator_id: userId,
        receiver_id: receiverUserId,
      })
    );

    if (error) throw error;

    console.log('✅ Connection request sent successfully:', data);
    return data;
  } catch (error) {
    console.error('❌ Error sending connection request:', error);
    return null;
  }
}

// 🟢 Accept connection request via `acceptConnectionRequest` RPC function
export async function acceptConnectionRequest(
  connectionId: string
): Promise<boolean> {
  try {
    console.log('📡 Accepting connection request:', connectionId);

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('acceptconnectionrequest', { connection_id: connectionId })
    );

    if (error) throw error;

    console.log('✅ Connection request accepted successfully');
    return true;
  } catch (error) {
    console.error('❌ Error accepting connection request:', error);
    return false;
  }
}

// 🟢 Decline connection request via `declineConnectionRequest` RPC function
export async function declineConnectionRequest(
  connectionId: string
): Promise<boolean> {
  try {
    console.log('📡 Declining connection request:', connectionId);

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('declineconnectionrequest', { connection_id: connectionId })
    );

    if (error) throw error;

    console.log('✅ Connection request declined successfully');
    return true;
  } catch (error) {
    console.error('❌ Error declining connection request:', error);
    return false;
  }
}

// 🟢 Cancel a connection request via `cancelConnectionRequest` RPC function
export async function cancelConnectionRequest(
  connectionId: string
): Promise<boolean> {
  try {
    console.log('📡 Canceling connection request:', connectionId);

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('cancelconnectionrequest', { connection_id: connectionId })
    );

    if (error) throw error;

    console.log('✅ Connection request canceled successfully');
    return true;
  } catch (error) {
    console.error('❌ Error canceling connection request:', error);
    return false;
  }
}

// 🟢 Block a user via `blockConnection` RPC function
export async function blockConnection(connectionId: string): Promise<boolean> {
  try {
    console.log('📡 Blocking user for connection:', connectionId);

    const userId = await getAuthenticatedUserId();
    if (!userId) throw new Error('Not authenticated');

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('blockconnection', {
        connection_id: connectionId,
        _user_id: userId, // ✅ Changed from `user_id` to `_user_id`
      })
    );

    if (error) throw error;

    console.log('✅ User blocked successfully');
    return true;
  } catch (error) {
    console.error('❌ Error blocking user:', error);
    return false;
  }
}

// 🟢 Unblock a user via `unblockConnection` RPC function
export async function unblockConnection(
  connectionId: string
): Promise<boolean> {
  try {
    console.log('📡 Unblocking user for connection:', connectionId);

    const userId = await getAuthenticatedUserId();
    if (!userId) throw new Error('Not authenticated');

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('unblockconnection', {
        connection_id: connectionId,
        _user_id: userId, // ✅ Changed from `user_id` to `_user_id`
      })
    );

    if (error) throw error;

    console.log('✅ User unblocked successfully');
    return true;
  } catch (error) {
    console.error('❌ Error unblocking user:', error);
    return false;
  }
}
