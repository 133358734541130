import { supabase } from '../supabase';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (
      retries > 0 &&
      error instanceof Error &&
      error.message.includes('Failed to fetch')
    ) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export interface Sport {
  id: string;
  name: string;
  emoji: string;
  category?: string;
}

export async function getSports(): Promise<Sport[]> {
  try {
    const { data, error } = await fetchWithRetry(() =>
      supabase
        .from('sports')
        .select('id, name, emoji, category')
        .order('name')
    );

    if (error) {
      console.error('Error fetching sports:', error);
      throw error;
    }
    return (data || []) as Sport[];
  } catch (error) {
    console.error('Error fetching sports:', error);
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      return [];
    }
    throw error;
  }
}

export async function insertUserSports(authUserId: string, sportIds: string[]): Promise<void> {
  // Look up the user's profile from the "users" table using the auth ID.
  const { data: userProfile, error: userError } = await supabase
    .from('users')
    .select('id')
    .eq('auth_id', authUserId)
    .single();

  if (userError || !userProfile) {
    console.error('Error fetching user profile:', userError);
    throw new Error('User profile not found.');
  }

  console.log('User ID: ', userProfile.id);
  console.log('Sports: ', sportIds);
  const dbUserId = userProfile.id;
  const rows = sportIds.map(sportId => ({ user_id: dbUserId, sport_id: sportId }));
  
  const { error } = await supabase.from('user_sports').insert(rows);
  if (error) {
    console.error('Error inserting user sports:', error);
    throw error;
  }
}
