import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '../ui/dialog';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { useActivityCreation } from '../../hooks/useActivityCreation';
import SportSelectionStep from './steps/SportSelectionStep';
import DateSelectionStep from './steps/DateSelectionStep';
import TimeSelectionStep from './steps/TimeSelectionStep';
import LocationStep from './steps/LocationStep';
import SuccessStep from './steps/SuccessStep';
import { useNavigate } from 'react-router-dom';

interface RequestMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
  member: {
    id: string;
    name: string;
    city: string;
    sports: string[];
  };
}

const RequestMatchModal: React.FC<RequestMatchModalProps> = ({
  isOpen,
  onClose,
  member
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [step, setStep] = useState(1);
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [selectedVenue, setSelectedVenue] = useState('');
  const [letReceiverDecide, setLetReceiverDecide] = useState(false);
  const [showMoreSports, setShowMoreSports] = useState(false);
  const { isSubmitting, handleCreateActivity, isSuccess } = useActivityCreation();

  const isStepValid = () => {
    switch (step) {
      case 1: // Sport selection
        return selectedSport !== '';
      case 2: // Date selection
        return selectedDate !== '';
      case 3: // Time selection
        return selectedTimes.length > 0;
      case 4: // Location selection
        return letReceiverDecide || selectedVenue !== '';
      default:
        return false;
    }
  };

  const handleNext = async () => {
    if (step < 4 && isStepValid()) {
      setStep(step + 1);
    } else if (isStepValid()) {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!isStepValid()) return;

    await handleCreateActivity({
      opponent: {
        id: member.id,
        name: member.name,
        image: member.image
      },
      sport: selectedSport,
      date: selectedDate,
      time: selectedTimes[0],
      venue: {
        name: selectedVenue,
        address: selectedVenue,
        city: member.city
      },
      status: 'new_request',
      direction: 'sent'
    });
    setStep(5);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setStep(1);
      setSelectedSport('');
      setSelectedDate('');
      setSelectedTimes([]);
      setSelectedVenue('');
      setLetReceiverDecide(false);
      onClose();
    }
  };

  const handleViewActivities = () => {
    handleClose();
    navigate('/dashboard/activities');
  };

  const handleStartChat = () => {
    handleClose();
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: member.id,
        initialMessage: `Hi ${member.name}! I'm interested in playing ${selectedSport} on ${selectedDate}. Would you like to join me?`,
        showMobileList: false
      }
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className={cn(
        "sm:max-w-md border p-0",
        isDarkMode 
          ? "bg-dark-200 border-dark-300" 
          : "bg-white border-gray-200"
      )}>
        <DialogTitle className="sr-only">Request Match</DialogTitle>

        {step < 5 ? (
          <div className="space-y-6 p-6">
            {/* Progress Bar */}
            <div className="absolute top-0 left-0 right-0 h-1 overflow-hidden rounded-t-2xl">
              <div 
                className={cn(
                  "h-full transition-all duration-300",
                  isDarkMode ? "bg-dark-300" : "bg-gray-100"
                )}
              >
                <div 
                  className="h-full bg-gold-400 transition-all duration-300"
                  style={{ width: `${(step / 4) * 100}%` }}
                />
              </div>
            </div>

            {/* Steps */}
            {step === 1 && (
              <SportSelectionStep
                selectedSport={selectedSport}
                onSportSelect={(emoji, name) => setSelectedSport(`${emoji} ${name}`)}
                showMoreSports={showMoreSports}
                onToggleMoreSports={() => setShowMoreSports(!showMoreSports)}
                memberName={member.name}
                city={member.city}
                sports={member.sports} //added to show member sports in Sportselection
              />
            )}

            {step === 2 && (
              <DateSelectionStep
                selectedDate={selectedDate}
                onDateSelect={setSelectedDate}
              />
            )}

            {step === 3 && (
              <TimeSelectionStep
                selectedTimes={selectedTimes}
                onTimesChange={setSelectedTimes}
              />
            )}

            {step === 4 && (
              <LocationStep
                city={member.city}
                selectedVenue={selectedVenue}
                onVenueChange={setSelectedVenue}
                letReceiverDecide={letReceiverDecide}
                onLetReceiverDecideChange={setLetReceiverDecide}
                memberName={member.name}
              />
            )}

            {/* Navigation */}
            <div className="flex justify-between items-center mt-8">
              {step > 1 && (
                <button
                  onClick={() => setStep(step - 1)}
                  className={cn(
                    "transition-colors",
                    isDarkMode ? "text-dark-800 hover:text-white" : "text-gray-500 hover:text-gray-700"
                  )}
                >
                  Back
                </button>
              )}
              <div className="flex-1" />
              <div className="flex items-center space-x-4">
                <div className={cn(
                  "flex items-center space-x-2 text-sm",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )}>
                  <span className="font-medium text-gold-400">{step}</span>
                  <span>/</span>
                  <span>4</span>
                </div>
                {isStepValid() && (
                  <button
                    onClick={handleNext}
                    disabled={isSubmitting}
                    className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Sending...' : step === 4 ? 'Send Request' : 'Next'}
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <SuccessStep
            memberName={member.name}
            onClose={handleClose}
            onViewActivities={handleViewActivities}
            onStartChat={handleStartChat}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RequestMatchModal;