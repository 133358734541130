import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ScrollText, MapPin, Plus, Calendar, MessageSquare } from 'lucide-react';
import { cn } from '@/lib/utils';
import { getPendingRequestsCount } from '@/lib/utils/activities';
import { getUnreadMessagesCount } from '@/lib/utils/chat';
import { activities } from '@/data/activities';
import BottomNavItem from './BottomNavItem';
import { useEffect, useState, useMemo } from 'react';
import { supabase } from '../../lib/supabase';

const BottomNavigation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [upcomingActivities, setUpcomingActivities] = useState(0);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData) return;

        // Get today's date at midnight
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Get upcoming activities count (confirmed + new requests)
        const { data: activities } = await supabase
          .from('activities')
          .select('status, date')
          .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
          .gte('date', today.toISOString().split('T')[0])
          .in('status', ['confirmed', 'new_request']);

        if (activities) {
          const upcoming = activities.length;
          const pending = activities.filter(a => a.status === 'new_request').length;
          
          setUpcomingActivities(upcoming);
          setPendingRequests(pending);
        }

        // Get unread messages count
        const { count: messageCount } = await supabase.rpc(
          'get_unread_messages_count',
          { user_auth_id: user.id }
        );

        setUnreadMessages(messageCount || 0);
      } catch (error) {
        console.error('Error fetching counts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCounts();

    // Subscribe to activities changes
    const subscription = supabase
      .channel('activities_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'activities',
          filter: `status=in.(confirmed,new_request)`
        },
        () => {
          fetchCounts();
        }
      )
      .subscribe();

    // Subscribe to messages changes
    const messagesSubscription = supabase
      .channel('messages_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'messages'
        },
        () => {
          fetchCounts();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
      messagesSubscription.unsubscribe();
    };
  }, []);

  const upcomingActivitiesCount = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const upcoming = activities.filter(activity => {
      const activityDate = new Date(activity.date);
      return activityDate >= today && activity.status !== 'declined';
    }).length;
    
//    console.log('Activities Count:', {
//      total: activities.length,
//      upcoming,
//     pending: pendingRequests,
//      timestamp: new Date().toISOString()
//    });
    
    return upcoming;
  }, [activities]);

  const bottomNavItems = [
    {
      label: 'Feed',
      icon: 'FileText',
      path: '/dashboard',
      isActive: location.pathname === '/dashboard'
    },
    {
      label: 'Clubs',
      icon: 'MapPin',
      path: '/dashboard/facilities',
      isActive: location.pathname === '/dashboard/facilities'
    },
    {
      label: 'Play',
      icon: 'Plus',
      path: '/dashboard/quick-play',
      isActive: location.pathname === '/dashboard/quick-play',
      isSpecial: true
    },
    {
      label: 'My Activities',
      icon: 'Calendar',
      path: '/dashboard/activities',
      isActive: location.pathname === '/dashboard/activities',
      count: upcomingActivities
    },
    {
      label: 'Messages',
      icon: 'MessageSquare',
      path: '/dashboard/messages',
      isActive: location.pathname === '/dashboard/messages',
      count: unreadMessages
    }
  ];

  const handleNavigation = (path: string) => {
    if (path === '/dashboard/quick-play') {
      navigate(path, {
        replace: true,
        state: { searchKey: Date.now() }
      });
    } else {
      navigate(path);
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-app border-t border-dark-300 z-40">
      <div className="w-full px-2 py-2 pb-safe">
        <div className="grid grid-cols-5 gap-0 max-w-screen-lg mx-auto">
          {bottomNavItems.map((item) => (
            <BottomNavItem
              key={item.label}
              {...item}
              onClick={() => handleNavigation(item.path)}
              className={item.isSpecial ? "-mt-8" : ""}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomNavigation;