import React from 'react';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { CheckCircle } from 'lucide-react';

interface AcceptButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isAccepted?: boolean;
}

const AcceptButton: React.FC<AcceptButtonProps> = ({ 
  onClick, 
  isLoading,
  isAccepted 
}) => (
  <button
    onClick={onClick}
    disabled={isLoading || isAccepted}
    className={cn(
      "px-4 py-2 rounded-lg transition-all duration-300",
      "flex items-center space-x-2",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      "transform active:scale-95",
      isAccepted
        ? "bg-green-500/20 text-green-500 border border-green-500/20"
        : "bg-green-500 hover:bg-green-600 text-white"
    )}
  >
    {isLoading ? (
      <>
        <Loader2 className="h-4 w-4 animate-spin mr-2" />
        <span>Accepting...</span>
      </>
    ) : isAccepted ? (
      <>
        <CheckCircle className="h-4 w-4 mr-2" />
        <span>Accepted</span>
      </>
    ) : (
      <span>Accept</span>
    )}
  </button>
);

export default AcceptButton;