import { supabase } from '../supabase';
import { Member } from '@/types/member';
import { Post } from '@/types/post';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (
      retries > 0 &&
      error instanceof Error &&
      error.message.includes('Failed to fetch')
    ) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export async function search(
  query: string
): Promise<{ players: Member[]; posts: Post[] }> {
  try {
    console.log('📡 Performing search query:', query);

    // Get authenticated user
    const {
      data: { user },
      error: authError,
    } = await supabase.auth.getUser();

    if (authError) {
      console.error('❌ Error fetching authenticated user:', authError);
      throw authError;
    }

    const loggedInUserId = user?.id || null; // Authenticated user's ID

    const { data, error } = await fetchWithRetry(() =>
      supabase.rpc('search_global', { query })
    );

    if (error) throw error;

    console.log('🔍 Raw API Response:', data);

    // Transform results to match expected frontend structures
    const players: Member[] = data
      .filter(
        (item: any) => item.type === 'user' && item.user_id !== loggedInUserId
      ) // Exclude logged-in user
      .map((user: any) => ({
        id: user.user_id, // Ensure frontend links to auth_id
        name: user.name,
        city: user.city || '',
        jobTitle: user.job_type || '',
        company: user.company || '',
        image:
          user.avatar_url ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png', // Ensure image is provided
        sports: user.sports || [],
        connections: user.connections || 0, // Default to 0 if not provided
      }));

    const posts: Post[] = data
      .filter((item: any) => item.type === 'post')
      .map((post: any) => ({
        id: post.id,
        user_id: post.user_id,
        name: post.name, // Map content to name for UI consistency
        author: {
          id: post.user_id,
          name: post.author_name || 'Unknown',
          image:
            post.author_avatar ||
            'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        },
        created_at: post.created_at,
      }));

    console.log('✅ Transformed Search Results:', { players, posts });
    return { players, posts };
  } catch (error) {
    console.error('❌ Search API error:', error);
    return { players: [], posts: [] };
  }
}
