import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle } from 'lucide-react';
import { useTheme } from '@/contexts/ThemeContext';
import { useAuth } from '@/contexts/AuthContext';
import { cn } from '@/lib/utils';

interface ConnectionsListProps {
  connections: any[];
  isLoading?: boolean;
  onShowMutuals: (data: { id: string; name: string; count: number }) => void;
  onMessageClick: (connectionId: string, connectionName: string) => void;
  onAccept: (connectionId: string) => Promise<void>;
  onDecline: (connectionId: string) => Promise<void>;
  memberId?: string;
}

const ConnectionsList: React.FC<ConnectionsListProps> = ({
  connections = [],
  isLoading = false,
  onShowMutuals,
  onMessageClick,
  onAccept,
  onDecline,
  memberId,
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const isOwnProfile = !memberId || memberId === user?.id;
  const defaultAvatar =
    'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  useEffect(() => {
    console.log(
      '🔍 ConnectionsList mounted - received connections:',
      connections
    );
  }, [connections]);

  const handleProfileClick = (connection: any, event: React.MouseEvent) => {
    event.stopPropagation();

    // Ensure we're using the correct property name
    if (connection.other_user?.auth_id) {
      navigate(`/dashboard/members/${connection.other_user.auth_id}`);
    } else {
      console.warn('⚠️ Missing auth_id for connection:', connection);
    }
  };

  if (isLoading) {
    return (
      <div
        className={cn(
          'text-center py-12 rounded-xl border',
          isDarkMode
            ? 'bg-dark-300 border-dark-400'
            : 'bg-gray-50 border-gray-200'
        )}
      >
        <p
          className={cn(
            'text-lg font-medium',
            isDarkMode ? 'text-white' : 'text-gray-900'
          )}
        >
          Loading Connections...
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {connections
        .filter((c) => c.status === 'accepted')
        .map((connection, index) => {
          const otherUser = connection.other_user || {}; // ✅ Fix property name
          const avatarUrl = otherUser.avatar_url || defaultAvatar;
          const fullName = otherUser.full_name || 'Unknown User';
          const city = otherUser.city || 'Unknown City';

          return (
            <div
              key={connection.id || otherUser.auth_id || `connection-${index}`}
              className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
            >
              <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                {/* Profile Image */}
                <button
                  onClick={(e) => handleProfileClick(connection, e)}
                  className="relative group"
                >
                  <img
                    src={avatarUrl}
                    alt={fullName}
                    className="w-20 h-20 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                    onError={(e) => {
                      console.error(
                        '❌ Image failed to load:',
                        e.currentTarget.src
                      );
                      e.currentTarget.src = defaultAvatar;
                    }}
                  />
                </button>

                {/* Info and Actions */}
                <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
                  <div className="text-center sm:text-left">
                    <button
                      onClick={(e) => handleProfileClick(connection, e)}
                      className="font-medium hover:text-gold-400 transition-colors text-left truncate text-white"
                    >
                      {fullName}
                    </button>
                    <div className="text-sm text-gray-500">{city}</div>
                  </div>

                  <div className="flex items-center gap-2 w-full sm:w-auto justify-end">
                    {isOwnProfile && connection.status === 'accepted' && (
                      <button
                        onClick={() => onMessageClick(connection.id, fullName)}
                        className={cn(
                          'p-2 rounded-lg transition-colors flex-shrink-0 hover:scale-105',
                          isDarkMode
                            ? 'bg-dark-500 hover:bg-dark-600 text-white'
                            : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                        )}
                      >
                        <MessageCircle className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ConnectionsList;
