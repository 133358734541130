import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Check, X, Mail, Loader2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useInviteStore } from '../store/inviteStore';

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface InviteInput {
  name: string;
  email: string;
}

export const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const { remainingInvites, isLoading, error, sendInvite, getRemainingInvites } = useInviteStore();
  const [invites, setInvites] = useState<InviteInput[]>([{ name: '', email: '' }]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch remaining invites when modal opens
  useEffect(() => {
    if (isOpen) {
      getRemainingInvites();
    }
  }, [isOpen, getRemainingInvites]);

  const handleAddInvite = () => {
    if (remainingInvites !== null && invites.length < remainingInvites) {
      setInvites([...invites, { name: '', email: '' }]);
    }
  };

  const handleRemoveInvite = (index: number) => {
    setInvites(invites.filter((_, i) => i !== index));
  };

  const handleInviteChange = (index: number, field: keyof InviteInput, value: string) => {
    const newInvites = [...invites];
    newInvites[index][field] = value;
    setInvites(newInvites);
  };

  const handleSubmit = async () => {
    try {
      // Validate input
      const validInvites = invites.filter(invite => 
        invite.name.trim() && invite.email.trim() && invite.email.includes('@')
      );

      if (validInvites.length === 0) {
        throw new Error('Please provide at least one valid invite');
      }

      await sendInvite(validInvites.map(invite => ({
        name: invite.name,
        email: invite.email,
        inviteMethod: 'email'
      })));

      setSuccessMessage(`Successfully sent ${validInvites.length} invitation${validInvites.length === 1 ? '' : 's'}`);
      setShowSuccess(true);
      
      // Reset form after delay
      setTimeout(() => {
        setInvites([{ name: '', email: '' }]);
        setShowSuccess(false);
        onClose();
      }, 2000);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to send invites';
      console.error('Invite error:', message);
      return;
    }
  };

  if (showSuccess) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-green-400/20 p-3 rounded-full">
                <Check className="h-6 w-6 text-green-400" />
              </div>
            </div>
            <DialogTitle className="text-center">Invitations Sent!</DialogTitle>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="text-center text-dark-800">
              {successMessage}
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <Button
              onClick={onClose}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (remainingInvites === 0) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-dark-300 p-3 rounded-full">
                <X className="h-6 w-6 text-gold-400" />
              </div>
            </div>
            <DialogTitle className="text-center">No Invites Available</DialogTitle>
          </DialogHeader>
          <div className="flex justify-end space-x-3">
            <Button onClick={onClose} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <DialogTitle>Invite Members</DialogTitle>
        </DialogHeader>

        <div className="space-y-4 py-4">
          <div className="text-dark-800 text-center">
            You have {remainingInvites} invite{remainingInvites === 1 ? '' : 's'} remaining
          </div>

          {error && (
            <div className="bg-red-500/10 text-red-500 p-3 rounded-lg text-sm">
              {error}
            </div>
          )}

          {invites.map((invite, index) => (
            <div key={index} className="space-y-4">
              {index > 0 && (
                <div className="border-t border-dark-300 pt-4">
                  <button
                    type="button"
                    onClick={() => handleRemoveInvite(index)}
                    className="text-dark-800 hover:text-red-400 text-sm"
                  >
                    Remove
                  </button>
                </div>
              )}
              <Input
                id={`name-${index}`}
                value={invite.name}
                onChange={(e) => handleInviteChange(index, 'name', e.target.value)}
                className="w-full bg-dark-300 border-dark-400 text-white"
                placeholder="John Smith"
                required
              />
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
                <Input
                  id={`email-${index}`}
                  type="email"
                  value={invite.email}
                  onChange={(e) => handleInviteChange(index, 'email', e.target.value)}
                  className="pl-10 bg-dark-300 border-dark-400 text-white"
                  placeholder="john@company.com"
                  required
                />
              </div>
            </div>
          ))}

          <div className="flex justify-end space-x-3">
            <Button onClick={onClose} disabled={isLoading} variant="outline">
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isLoading} className="bg-gold-400 hover:bg-gold-500">
              {isLoading ? <Loader2 className="h-4 w-4 mr-2 animate-spin" /> : 'Send Invites'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};