import React from 'react';
import { motion } from 'framer-motion';
import { Activity } from '../../types/activity';
import ActivityCard from './ActivityCard';

interface ActivityCardListProps {
  activities: Activity[];
  isOwnProfile: boolean;
  isPastActivity?: boolean;
  onManageSession: (activity: Activity) => void;
  onAccept: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: (activity: Activity) => void;
  onRate?: (activity: Activity) => void;
  isAccepting?: Record<string, boolean>;
  isDeclining?: Record<string, boolean>;
  isRating?: Record<string, boolean>;
  lastElementRef?: (node: HTMLElement | null) => void;
}

const ActivityCardList: React.FC<ActivityCardListProps> = ({ 
  activities, 
  isOwnProfile, 
  isPastActivity = false,
  onManageSession, 
  onAccept, 
  onRematch, 
  onDeclineRequest,
  onRate = () => {},
  isAccepting = false,
  isDeclining = false,
  isRating = false,
  lastElementRef
}) => {
  return (
    <div className="space-y-4">
      {activities.map((activity, index) => (
        <motion.div
          key={activity.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.3,
            delay: index * 0.1, // Stagger the animations
            ease: [0.4, 0, 0.2, 1] // Smooth easing
          }}
        >
          <ActivityCard
            activity={activity}
            isOwnProfile={isOwnProfile}
            isPastActivity={isPastActivity}
            onManageSession={onManageSession}
            onAccept={onAccept}
            onRematch={onRematch}
            onDeclineRequest={onDeclineRequest}
            onRate={() => onRate(activity)}
            isAccepting={isAccepting?.[activity.id]}
            isDeclining={isDeclining?.[activity.id]}
            isRating={isRating?.[activity.id]}
            lastElementRef={lastElementRef}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default ActivityCardList;