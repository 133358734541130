import React, { useState, useEffect } from 'react';
import { Search, UserX } from 'lucide-react';
import { useBlockStore } from '@/store/blockStore';
import BlockedMembersList from '../connections/BlockedMembersList';
import { useAuth } from '@/contexts/AuthContext';

const BlockedMembersTab = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { blockedMembers, fetchBlockedMembers } = useBlockStore();
  const { user } = useAuth();

  useEffect(() => {
    fetchBlockedMembers();
  }, [fetchBlockedMembers]);

  const filteredMembers = blockedMembers.filter((member) =>
    member.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      {/* Search Input */}
      {blockedMembers.length > 0 && (
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500" />
          <input
            type="text"
            placeholder="Search blocked members..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 bg-gray-100 border border-gray-300 rounded-xl text-gray-900"
          />
        </div>
      )}

      {/* Blocked Members List */}
      <BlockedMembersList members={filteredMembers} />
    </div>
  );
};

export default BlockedMembersTab;
