import { supabase } from '../supabase';
import { UserProfile } from '../../types/profile';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

/**
 * Helper function to retry fetching data on network failures.
 */
async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`🌐 Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

/**
 * Fetch the authenticated user's profile.
 * - Fetches `facebook_url`, `linkedin_url`, `instagram_url` directly from `users`
 */
export async function getUserProfile() {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) {
      console.error('❌ Auth error:', authError);
      if (authError.message?.includes('Failed to fetch')) return null;
      throw authError;
    }
    if (!user) throw new Error('Not authenticated');

    // ✅ Fetch profile with social links
    const { data, error } = await fetchWithRetry(() => supabase
      .from('users')
      .select(`
        id,
        auth_id,
        full_name,
        avatar_url,
        city,
        company,
        job_type,
        skill_level,
        facebook_url,
        linkedin_url,
        instagram_url,
        user_sports (
          sports (
            name,
            emoji
          )
        )
      `)
      .eq('auth_id', user.id)
      .single(), 3);

    if (error) {
      console.error('❌ Profile fetch error:', error);
      if (error.message?.includes('Failed to fetch')) return null;
      throw error;
    }

    console.log("✅ Full Profile Data Fetched:", data);

    return data;
  } catch (error) {
    console.error('❌ Error fetching user profile:', error);
    if (error instanceof Error && error.message.includes('Failed to fetch')) return null;
    throw error;
  }
}

/**
 * Update the user's profile.
 * - Supports updating `facebook_url`, `linkedin_url`, `instagram_url`.
 */
export async function updateUserProfile(updates: Partial<UserProfile>) {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!user) throw new Error('Not authenticated');

    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userError) throw userError;
    if (!userData) throw new Error('User not found');

    // ✅ Include social links in the update
    const userUpdates = {
      full_name: updates.full_name,
      city: updates.city,
      avatar_url: updates.avatar_url,
      company: updates.company,
      job_type: updates.job_type,
      facebook_url: updates.facebook_url,
      linkedin_url: updates.linkedin_url,
      instagram_url: updates.instagram_url
    };

    const { error: userUpdateError } = await supabase
      .from('users')
      .update(userUpdates)
      .eq('id', userData.id);

    if (userUpdateError) throw userUpdateError;

    console.log("✅ Profile updated successfully!", userUpdates);
    return true;
  } catch (error) {
    console.error('❌ Error updating user profile:', error);
    throw error;
  }
}
