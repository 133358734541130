import { supabase } from '../supabase';

interface FeedbackData {
  type: 'feature_request' | 'improvement_suggestion' | 'bug_report' | 'general_feedback';
  rating: number;
  content: string;
}

export async function submitFeedback(data: FeedbackData) {
  try {
    // Get current user
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Submit feedback
    const { error } = await supabase
      .from('feedback')
      .insert({
        user_id: user.id,
        type: data.type,
        rating: data.rating,
        content: data.content
      });

    if (error) throw error;

    return { success: true };
  } catch (error) {
    console.error('Error submitting feedback:', error);
    throw error instanceof Error 
      ? error 
      : new Error('Failed to submit feedback');
  }
}

export async function getFeedback() {
  try {
    const { data, error } = await supabase
      .from('feedback')
      .select(`
        id,
        type,
        rating,
        content,
        created_at,
        users (
          id,
          full_name,
          avatar_url
        )
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error fetching feedback:', error);
    throw error instanceof Error 
      ? error 
      : new Error('Failed to fetch feedback');
  }
}