import React, { useState, useEffect } from "react";
import { Linkedin, Facebook, Instagram, Globe, Save, Edit2 } from "lucide-react";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { supabase } from "../../../lib/supabase";

function cn(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export const SocialLinksCard: React.FC = () => {
  const [socialLinks, setSocialLinks] = useState({
    linkedinUrl: "",
    facebookUrl: "",
    instagramUrl: "",
  });

  const [profile, setProfile] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  // 🔹 Fetch authenticated user ID
  useEffect(() => {
    const fetchUserId = async () => {
      const { data: authData, error: authError } = await supabase.auth.getUser();
      if (authError) {
        console.error("Error fetching user:", authError);
        return;
      }
      if (authData?.user?.id) {
        console.log("Authenticated User ID:", authData.user.id);
        setUserId(authData.user.id);
      }
    };
    fetchUserId();
  }, []);

  // 🔹 Function to fetch user profile data
  const fetchProfile = async () => {
  try {
    console.log("Fetching authenticated user...");
    const { data: authData, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;

    const authUserId = authData.user?.id;
    console.log("Authenticated User ID:", authUserId);

    if (!authUserId) return;

    // 🔹 Get the correct internal `id` from the users table
    const { data: userData, error: userError } = await supabase
      .from("users")
      .select("id")
      .eq("auth_id", authUserId)
      .single();

    if (userError) throw userError;
    if (!userData) throw new Error("User not found in users table");

    const internalUserId = userData.id; // ✅ Now we have the correct user_id

    console.log("Internal User ID:", internalUserId);

    // 🔹 Fetch the profile from `user_profiles` using the correct `user_id`
    const { data: profileData, error } = await supabase
      .from("user_profiles")
      .select("preferences")
      .eq("user_id", internalUserId)  // ✅ Fix applied here
      .single();

    if (error) throw error;

    console.log("Fetched Profile Data:", profileData);

    setProfile(profileData);
    setSocialLinks({
      linkedinUrl: profileData?.preferences?.linkedinUrl || "",
      facebookUrl: profileData?.preferences?.facebookUrl || "",
      instagramUrl: profileData?.preferences?.instagramUrl || "",
    });
  } catch (error) {
    console.error("Error fetching profile:", error);
  }
};


  // 🔹 Fetch profile when userId is available
  useEffect(() => {
    if (userId) {
      fetchProfile(userId);
    }
  }, [userId]); // 🔹 Runs only when userId is available

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError(null);

    try {
      if (!userId) {
        throw new Error("No authenticated user found");
      }

      console.log("Updating social links for user:", userId);

      // Call RPC function to update social links
     const { error } = await supabase.rpc("update_social_links", {
  user_id_param: userId,  // ✅ Make sure this matches the function parameter!
  linkedin_url: socialLinks.linkedinUrl.trim() || null,
  facebook_url: socialLinks.facebookUrl.trim() || null,
  instagram_url: socialLinks.instagramUrl.trim() || null,
});

if (error) {
  console.error("Save Error:", error);
} else {
  console.log("✅ Social links updated successfully.");
}


      // Refetch profile data after save
      await fetchProfile(userId);

      setIsEditing(false);
    } catch (error) {
      console.error("❌ Save Error:", error);
      setSaveError(error instanceof Error ? error.message : "Failed to save social links");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-white">Social Links</h2>
          <p className="text-dark-800 mt-1">Connect your social media accounts</p>
        </div>
        {!isEditing ? (
          <Button onClick={() => setIsEditing(true)} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            <Edit2 className="h-4 w-4 mr-2" />
            Edit Links
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Button onClick={() => setIsEditing(false)} variant="outline" className="border-dark-400">
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
              {isSaving ? "Saving..." : <>
                <Save className="h-4 w-4 mr-2" />
                Save Links
              </>}
            </Button>
          </div>
        )}
      </div>

      {saveError && (
        <div className="mb-4 p-3 bg-red-500/10 text-red-500 text-sm rounded-lg border border-red-500/20">
          {saveError}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Linkedin className="h-4 w-4 mr-2" />
            LinkedIn Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.linkedinUrl}
              placeholder={isEditing ? "https://linkedin.com/in/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, linkedinUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.linkedinUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Facebook className="h-4 w-4 mr-2" />
            Facebook Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.facebookUrl}
              placeholder={isEditing ? "https://facebook.com/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, facebookUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.facebookUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Instagram className="h-4 w-4 mr-2" />
            Instagram Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.instagramUrl}
              placeholder={isEditing ? "https://instagram.com/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, instagramUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.instagramUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
