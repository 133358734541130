import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Notification } from '../types/notification';
import {
  getNotifications,
  markAsRead,
  markAllAsRead as markAllAsReadInDb,
  subscribeToNotifications,
} from '../lib/supabase/notifications';

interface NotificationStore {
  notifications: Notification[];
  unreadCount: number;
  isLoading: boolean;
  error: string | null;
  addNotification: (notification: Notification) => void;
  markAsRead: (id: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  removeNotification: (id: string) => void;
  fetchNotifications: () => Promise<void>;
  initializeSubscription: () => () => void;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: null,

  addNotification: (notification) => {
    set((state) => {
      const notifications = [notification, ...state.notifications];
      const unreadCount = notifications.filter((n) => !n.read).length;
      console.log('📩 Added Notification:', notification);
      return { notifications, unreadCount };
    });
  },

  markAsRead: async (id) => {
    try {
      await markAsRead(id);
      set((state) => ({
        notifications: state.notifications.map((n) =>
          n.id === id ? { ...n, read: true } : n
        ),
        unreadCount: state.notifications.filter((n) => !n.read && n.id !== id)
          .length,
      }));
      console.log(`✅ Marked notification ${id} as read`);
    } catch (error) {
      console.error('❌ Failed to mark notification as read:', error);
    }
  },

  markAllAsRead: async () => {
    try {
      await markAllAsReadInDb();
      set((state) => ({
        notifications: state.notifications.map((n) => ({ ...n, read: true })),
        unreadCount: 0,
      }));
      console.log('✅ Marked all notifications as read');
    } catch (error) {
      console.error('❌ Failed to mark all notifications as read:', error);
    }
  },

  removeNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
      unreadCount: state.notifications.filter((n) => !n.read && n.id !== id)
        .length,
    }));
    console.log(`🗑 Removed notification ${id}`);
  },

  fetchNotifications: async () => {
    set({ isLoading: true, error: null });
    console.log('📡 Fetching notifications...');

    const startTime = Date.now();

    try {
      const fetchWithRetry = async (retries = 3) => {
        try {
          // Verify active session before proceeding
          const {
            data: { session },
            error: sessionError,
          } = await supabase.auth.getSession();
          if (sessionError || !session) {
            console.warn(
              '⚠️ No active session found - Skipping notification fetch.'
            );
            return { notifications: [], unreadCount: 0 };
          }

          const notifications = await getNotifications();
          return {
            notifications,
            unreadCount: notifications.filter((n) => !n.read).length,
          };
        } catch (error) {
          if (
            retries > 0 &&
            error instanceof Error &&
            error.message.includes('Failed to fetch')
          ) {
            console.warn(
              `⚠️ Network issue fetching notifications. Retrying... (${retries} left)`
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return fetchWithRetry(retries - 1);
          }
          throw error;
        }
      };

      const result = await fetchWithRetry();
      set({
        notifications: result.notifications,
        unreadCount: result.unreadCount,
        isLoading: false,
      });

      console.log(`✅ Notifications fetched in ${Date.now() - startTime}ms`);
    } catch (error) {
      console.error('❌ Failed to fetch notifications:', error);
      set((state) => ({
        ...state,
        isLoading: false,
        error: 'Failed to fetch notifications',
      }));
    }
  },

  initializeSubscription: () => {
    console.log('🔄 Initializing notification subscription...');

    // Fetch notifications first
    get()
      .fetchNotifications()
      .catch((error) => {
        console.error('❌ Failed to fetch initial notifications:', error);
      });

    try {
      const unsubscribe = subscribeToNotifications((notification) => {
        console.log('📩 New Notification Received:', notification);
        get().addNotification(notification);
      });

      console.log('✅ Notification subscription active');

      return () => {
        console.log('🔌 Unsubscribing from notifications...');
        unsubscribe();
      };
    } catch (error) {
      console.error('❌ Error initializing subscription:', error);
    }
  },
}));
