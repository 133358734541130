import React from 'react';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface SportsGridProps {
  sports: string[];
}

const SportsGrid: React.FC<SportsGridProps> = ({ sports }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex flex-wrap justify-center sm:justify-start gap-2 mt-4">
      {sports.map((sport, idx) => {
        return (
          <div
            key={`${sport}-${idx}`}
            className={cn(
              "flex items-center space-x-2 px-4 py-2.5 rounded-full",
              "transition-all duration-200 cursor-default group",
              "transform hover:scale-105",
              "shadow-md hover:shadow-lg",
              isDarkMode 
                ? "bg-dark-300/90 hover:bg-dark-400 border border-dark-400/50" 
                : "bg-gray-100 hover:bg-gray-200 border border-gray-200/50",
              "backdrop-blur-sm"
            )}
          >
            <span className={cn(
              "text-base font-medium transition-colors",
              isDarkMode 
                ? "text-dark-800 group-hover:text-white" 
                : "text-gray-600 group-hover:text-gray-900"
            )}>
              {sport}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SportsGrid;