import React from 'react';
import { MapPin, Briefcase } from 'lucide-react';
import { getCurrentTitle } from '../../../data/points';
import { sportEmojis } from '../../../lib/constants';
import { Member } from '../../../types/member';
import ProfileImage from './ProfileImage';
import { useTheme } from '@/contexts/ThemeContext';
import SocialLinks from '../../social/SocialLinks';

interface ProfileHeaderProps {
  member: Member;
  cityImage: string;
  onImageChange?: (file: File) => void;
  profile?: any;
  socialLinks?: {
    linkedinUrl?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;
  };
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  member,
  cityImage,
  onImageChange,
  profile,
  socialLinks, // ✅ Ensure this is used
}) => {
  const { isDarkMode } = useTheme();

  //console.log("🟡 Debugging ProfileHeader Component:");
  //console.log("👉 profile:", profile);
  //console.log("👉 profile.user_sports:", profile?.user_sports);
  //console.log("👉 member:", member);
  //console.log("👉 member.sports:", member?.sports);
  //console.log("🔍 Full Profile Object:", profile);
  //console.log("✅ Social Links Passed to ProfileHeader:", socialLinks);

  // **🏆 Memoized Current Title Based on Points**
  const currentTitle = React.useMemo(() => {
    return getCurrentTitle(member.points.total);
  }, [member.points.total]);

  // **🚀 Memoized Job Info**
  const jobInfo = React.useMemo(() => ({
    jobTitle: profile?.job_type || member?.jobTitle || 'Role',
    company: profile?.company || member?.company || 'Company'
  }), [profile?.job_type, profile?.company, member?.jobTitle, member?.company]);

  // **🏅 Memoized Sports Transformation**
  const userSports = React.useMemo(() => {
    // Ensure `profile.user_sports` is an array; fallback to an empty array if undefined
    const userSportsArray = Array.isArray(profile?.user_sports) ? profile.user_sports : [];
    
    if (userSportsArray.length) {
      return userSportsArray
        .filter((us: any) => us?.sports?.name) // Ensure structure is valid
        .map((us: any) => ({
          name: us.sports.name,
          emoji: us.sports.emoji || '🎯' // Use the emoji directly from the database
        }));
    }
  
    // Fallback to member.sports if profile.user_sports is empty
    const memberSportsArray = Array.isArray(member?.sports) ? member.sports : [];
  
    return memberSportsArray.map((sport: string) => ({
      name: sport,
      emoji: sportEmojis[sport.toLowerCase()] || '🎯' // Use fallback mapping if needed
    }));
  }, [profile?.user_sports, member?.sports]);

  return (
    <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
      <div className="relative h-64">
        <img
          src={cityImage}
          alt={`${member.city} cityscape`}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
      </div>

      <div className="relative px-6 pb-8">
        <div className="flex flex-col items-center -mt-16">
          <div className="relative">
            <ProfileImage
              src={profile?.avatar_url || member.image}
              alt={member.name}
              size={128}
              onImageChange={onImageChange}
              className="shadow-xl ring-4 ring-dark-200"
            />
          </div>
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-white">
              {profile?.full_name || member?.name}
            </h2>
            {userSports?.length > 0 && (
              <div className="flex flex-wrap justify-center gap-1.5 mt-2 mb-3">
                {userSports.map((sport, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-1 bg-dark-300 rounded-full text-sm text-white hover:bg-dark-400 transition-colors"
                  >
                    {sport.emoji} {sport.name}
                  </span>
                ))}
              </div>
            )}
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <MapPin className="h-4 w-4 mr-1" />
              {profile?.city || member.city}
            </div>
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <Briefcase className="h-4 w-4 mr-1" />
              {`${jobInfo.jobTitle} at ${jobInfo.company}`}
            </div>

            {/* ✅ Use `socialLinks` Prop Instead of Extracting Again */}
            <SocialLinks
              linkedinUrl={socialLinks?.linkedinUrl}
              facebookUrl={socialLinks?.facebookUrl}
              instagramUrl={socialLinks?.instagramUrl}
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
