import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '../../ui/dialog';
import { Button } from '../../ui/button';
import { Activity } from '../../../types/activity';
import ManageActivityForm from './form/ManageActivityForm';
import SuccessMessage from './SuccessMessage';

interface ManageActivityModalProps {
  activity: Activity;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (activity: Activity, updates: Partial<Activity>) => void;
  onDelete: (reason: string) => void;
}

const ManageActivityModal: React.FC<ManageActivityModalProps> = ({
  activity,
  isOpen,
  onClose,
  onUpdate,
  onDelete
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (activity: Activity, updates: Partial<Activity>) => {
    setIsSubmitting(true);
    try {
      await onUpdate(activity, updates);
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to update activity:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = (reason: string) => {
    if (onDelete) {
      onDelete(reason);
    }
    onClose();
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setShowSuccess(false);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300 max-h-[90vh] overflow-y-auto">
        <DialogTitle className="sr-only">Manage Activity</DialogTitle>
        {showSuccess ? (
          <SuccessMessage onClose={handleClose} />
        ) : (
          <ManageActivityForm
            activity={activity}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            onCancel={handleClose}
            isSubmitting={isSubmitting}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ManageActivityModal;