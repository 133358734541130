import React, { useRef, useEffect, useMemo } from 'react';
import MessageGroup from './MessageGroup';
import TypingIndicator from './TypingIndicator';
import type { Message } from '../../types/chat';
import { format } from 'date-fns';

interface MessageListProps {
  messages: Message[];
  isTyping: boolean;
  typingName: string;
  onDelete?: (messageId: string) => void;
  onEdit?: (messageId: string, newContent: string) => void;
  onReply?: (message: Message) => void;
  editingMessage?: string | null;
}

const groupMessagesByDate = (messages: Message[] = []): Record<string, Message[]> => {
  const groups: Record<string, Message[]> = {};
  
  if (!Array.isArray(messages)) {
    console.warn('Messages is not an array:', messages);
    return {};
  }

  messages.forEach(message => {
    // Format the date as a string key (e.g. "Today", "Yesterday", or "Jan 15, 2024")
    // Handle both string and Date objects
    const messageDate = typeof message.time === 'string' ? new Date(message.time) : message.time;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    let dateKey;
    if (messageDate.toDateString() === today.toDateString()) {
      dateKey = 'Today';
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      dateKey = 'Yesterday';
    } else {
      dateKey = format(messageDate, 'MMM d, yyyy');
    }

    // Add console log here to confirm the transformation
    console.log('groupMessagesByDate - date transformation:', {
      messageId: message.id,
      rawTime: message.time,
      parsedDate: messageDate.toISOString(),
      dateKey
    });

    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(message);
  });
  
  return groups;
};

const MessageList: React.FC<MessageListProps> = ({
  messages,
  isTyping,
  typingName,
  onDelete,
  onEdit,
  onReply,
  editingMessage
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // Add console log when messages prop changes
  React.useEffect(() => {
    console.log('MessageList received messages:', {
      componentName: 'MessageList',
      messagesLength: messages?.length || 0,
      messages: messages?.map(m => ({
        id: m.id,
        content: m.content,
        time: m.time,
        isSender: m.isSender,
        status: m.status
      }))
    });
  }, [messages]);

  const messageGroups = useMemo(() => {
    // Ensure messages is an array and log the grouping process
    const validMessages = Array.isArray(messages) ? messages : [];
    
    if (!Array.isArray(messages)) {
      console.warn('Messages prop is not an array:', messages);
    }
    
    const groups = groupMessagesByDate(validMessages);
    
    // Log the grouped messages
    console.log('MessageList:', {
      componentName: 'MessageList',
      totalMessages: validMessages.length,
      groupedMessages: groups
    });
    
    return groups;
  }, [messages]);


  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Scroll immediately for first load
    if (Array.isArray(messages) && messages.length > 0) {
      scrollToBottom();
    }

    // Use requestAnimationFrame for smooth scrolling on updates
    const timeoutId = setTimeout(() => {
      requestAnimationFrame(scrollToBottom);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [messages]);

  return (
    <div className="flex-1 overflow-y-auto px-4 py-6">
      {Object.entries(messageGroups).map(([date, messages]) => (
        <MessageGroup
          key={date}
          date={date}
          messages={messages}
          onDelete={onDelete}
          onEdit={onEdit}
          onReply={onReply}
          editingId={editingMessage}
        />
      ))}
      <div ref={messagesEndRef} className="h-0" />
      {isTyping && <TypingIndicator name={typingName} />}
    </div>
  );
};

export default MessageList;