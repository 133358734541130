import React from 'react';
import { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; 
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';

const TEXT_COLLAPSE_LENGTH = 280; // Similar to Twitter's limit for preview

interface PostContentProps {
  content: string;
  formattedContent?: React.ReactNode;
  media?: string;
  endorsements?: string[];
}

const PostContent: React.FC<PostContentProps> = ({
  content,
  formattedContent,
  media,
  endorsements
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const shouldCollapse = useMemo(() => 
    content.length > TEXT_COLLAPSE_LENGTH, 
    [content]
  );
  
  const displayContent = useMemo(() => {
    if (!shouldCollapse || isExpanded) {
      return formattedContent || content;
    }
    return (content.slice(0, TEXT_COLLAPSE_LENGTH) + '...');
  }, [content, formattedContent, shouldCollapse, isExpanded]);
  return (
    <div className="space-y-4">
      <div className="relative">
        <p className="text-white text-lg leading-relaxed">
          {displayContent}
        </p>
        
        {shouldCollapse && (
          <motion.button
            onClick={() => setIsExpanded(!isExpanded)}
            className={cn(
              "flex items-center gap-1 mt-2",
              "text-dark-800 hover:text-gold-400",
              "transition-colors duration-200"
            )}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <span className="text-sm">
              {isExpanded ? 'Show less' : 'Read more'}
            </span>
            {isExpanded ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </motion.button>
        )}
      </div>

      {media && (
        <motion.div 
          className="relative rounded-xl overflow-hidden bg-dark-300"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.2 }}
        >
          <img
            src={media}
            alt="Post media"
            className="w-full max-h-[400px] object-cover hover:opacity-95 transition-opacity"
            loading="lazy"
          />
        </motion.div>
      )}

      {endorsements && endorsements.length > 0 && (
        <div className={cn(
          "flex flex-wrap gap-2 mt-3",
          "bg-dark-300 rounded-xl p-4"
        )}>
          {endorsements.map((endorsement, index) => (
            <span
              key={index}
              className="px-3 py-1.5 bg-dark-400 rounded-full text-sm text-white"
            >
              {endorsement}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostContent;