// Default location (Valencia, Spain)
export const DEFAULT_LOCATION = {
  latitude: 39.4699,
  longitude: -0.3763
};

/**
 * Function to get the user's geolocation.
 * Returns a Promise resolving to an object with latitude and longitude.
 */
export async function getUserLocation(): Promise<{ latitude: number; longitude: number }> {
  return new Promise(async (resolve) => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      resolve(DEFAULT_LOCATION); // ✅ Ensure fallback if geolocation is unavailable
      return;
    }

    // Check permission status first
    const permissionStatus = await navigator.permissions.query({ name: "geolocation" });

    if (permissionStatus.state === "denied") {
      console.warn("Geolocation permission denied by user.");
      resolve(DEFAULT_LOCATION); // ✅ Use default location if denied
      return;
    }

    // Request geolocation only if permission is granted or promptable
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      (error) => {
        console.error("Error getting user location:", error);
        resolve(DEFAULT_LOCATION); // ✅ Fallback on error
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 300000 // Cache for 5 minutes
      }
    );
  });
}
