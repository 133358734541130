import { create } from 'zustand';
import { BlockedMember } from '@/types/member';
import { blockConnection, unblockConnection } from '@/lib/supabase/connections';
import { supabase } from '@/lib/supabase';

// Define BlockStore interface
interface BlockStore {
  blockedMembers: BlockedMember[];
  fetchBlockedMembers: () => Promise<void>;
  blockMember: (connectionId: string) => Promise<void>;
  unblockMember: (connectionId: string) => Promise<void>;
  isBlocked: (memberId: string) => boolean;
}

// Zustand store
export const useBlockStore = create<BlockStore>((set, get) => ({
  blockedMembers: [],

  // Fetch blocked members from the database
  fetchBlockedMembers: async () => {
    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();
      if (authError || !user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('connections')
        .select(
          `
          id,
          status,
          other_user:receiver_id(id, auth_id, full_name, avatar_url, city)
        `
        )
        .eq('status', 'blocked')
        .or(`initiator_id.eq.${user.id},receiver_id.eq.${user.id}`);

      if (error) throw error;

      // Transform data to match BlockedMember type
      const blockedList: BlockedMember[] = data.map((connection) => ({
        id: connection.id,
        auth_id: connection.other_user.auth_id,
        full_name: connection.other_user.full_name,
        avatar_url: connection.other_user.avatar_url,
        city: connection.other_user.city,
        blocked_at: new Date().toISOString(),
      }));

      set({ blockedMembers: blockedList });
    } catch (error) {
      console.error('Error fetching blocked members:', error);
    }
  },

  // Block a user (update connection status in DB)
  blockMember: async (connectionId: string) => {
    try {
      const success = await blockConnection(connectionId);
      if (success) {
        set((state) => ({
          blockedMembers: [
            ...state.blockedMembers,
            { id: connectionId, blocked_at: new Date().toISOString() },
          ],
        }));
      }
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  },

  // Unblock a user (update connection status in DB)
  unblockMember: async (connectionId: string) => {
    try {
      const success = await unblockConnection(connectionId);
      if (success) {
        set((state) => ({
          blockedMembers: state.blockedMembers.filter(
            (member) => member.id !== connectionId
          ),
        }));
      }
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  },

  // Check if a user is blocked
  isBlocked: (memberId: string) => {
    return get().blockedMembers.some((member) => member.auth_id === memberId);
  },
}));
