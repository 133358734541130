import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import ConnectionsList from './ConnectionsList';

interface ConnectionsTabProps {
  connections: any[];
  isLoading: boolean;
  error: string | null;
}

const ConnectionsTab: React.FC<ConnectionsTabProps> = ({
  connections,
  isLoading,
  error,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'name' | 'distance' | 'mutual'>('name');

  const filteredConnections = useMemo(() => {
    if (!Array.isArray(connections)) {
      console.error('Invalid connections:', connections);
      return [];
    }

    return connections
      .filter((conn) => conn.status === 'accepted')
      .filter((conn) => {
        if (!searchTerm) return true;
        const otherUserName = conn.otherUser?.full_name || '';
        const otherUserCity = conn.otherUser?.city || '';
        return (
          otherUserName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          otherUserCity.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'distance':
            return (a.otherUser?.distance || 0) - (b.otherUser?.distance || 0);
          default:
            return (a.otherUser?.full_name || '').localeCompare(
              b.otherUser?.full_name || ''
            );
        }
      });
  }, [connections, searchTerm, sortBy]);

  return (
    <div className="space-y-6">
      {error && (
        <p className="text-red-500">Error loading connections: {error}</p>
      )}
      <ConnectionsList
        connections={filteredConnections}
        isLoading={isLoading}
        onShowMutuals={() => {}}
        onMessageClick={() => {}}
        onAccept={() => Promise.resolve()}
        onDecline={() => Promise.resolve()}
      />
    </div>
  );
};

export default ConnectionsTab;
