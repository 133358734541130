import React, { useState } from 'react';
import { Navigation2 } from 'lucide-react';
import { navigationApps } from '../../lib/navigation';
import { getUserLocation } from '../../lib/utils/location';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { cn } from '@/lib/utils';

interface NavigationOptionsProps {
  venueName: string;
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const NavigationOptions: React.FC<NavigationOptionsProps> = ({ venueName, address, coordinates }) => {
  const [showModal, setShowModal] = useState(false);

  const handleNavigate = async (app: typeof navigationApps[number]) => {
    const url = app.getUrl(coordinates, venueName, address);
    window.open(url, '_blank');
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={cn(
          "flex items-center justify-center space-x-2 w-full",
          "px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white",
          "rounded-xl transition-colors"
        )}
      >
        <Navigation2 className="h-4 w-4 text-gold-400" />
        <span>Navigate</span>
      </button>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 border-dark-300">
          <DialogHeader>
            <DialogTitle className="text-white">Navigate to {venueName}</DialogTitle>
          </DialogHeader>

          <div className="space-y-3 py-4">
            {navigationApps.map((app) => (
              <a
                key={app.name}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate(app);
                  setShowModal(false);
                }}
                className={cn(
                  "flex items-center justify-center space-x-3 w-full",
                  "px-4 py-3 bg-dark-300 hover:bg-dark-400",
                  "text-white rounded-xl transition-colors"
                )}
              >
                <span className="text-xl">{app.icon}</span>
                <span>Open in {app.name}</span>
              </a>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NavigationOptions;