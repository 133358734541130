import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Member } from '../../types/member';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';
import ConnectionStats from './stats/ConnectionStats';
import { useProfile } from '../../hooks/useProfile';
import { useAuth } from '../../contexts/AuthContext';
import CitiesPlayedModal from '../profile/modals/CitiesPlayedModal';

interface ProfileStatsProps {
  member: Member;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowConnections,
  onShowReviews
}) => {
  const { user } = useAuth();
  const [connectionCount, setConnectionCount] = useState(member.connections);
  const [isLoadingConnections, setIsLoadingConnections] = useState(false);
  const [isLoadingMatches, setIsLoadingMatches] = useState(false);
  const [localMatchesPlayed, setLocalMatchesPlayed] = useState(member.matchesPlayed);
  const [citiesPlayed, setCitiesPlayed] = useState<number>(member.citiesPlayed.length);
  const [citiesList, setCitiesList] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;
  const { matchesPlayed: profileMatchesPlayed } = useProfile();
  const navigate = useNavigate();

  const handleMatchesClick = () => {
    navigate('/dashboard/activities', {
      state: { 
        activeTab: 'past',
        selectedStatus: 'confirmed'
      }
    });
  };

  useEffect(() => {
    const fetchConnectionCount = async () => {
      try {
        const targetAuthId = member.id === 'current-user' ? user?.id : member.id;
        if (!targetAuthId) return;

        setIsLoadingConnections(true);
        
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', targetAuthId)
          .single();

        if (userError) throw userError;

        const { count, error: countError } = await supabase
          .from('connections')
          .select('*', { count: 'exact', head: true })
          .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
          .eq('status', 'accepted');

        if (countError) throw countError;

        if (count !== null) setConnectionCount(count);
      } catch (error) {
        console.error('Error fetching connection count:', error);
      } finally {
        setIsLoadingConnections(false);
      }
    };

    fetchConnectionCount();
  }, [member.id]);

  useEffect(() => {
    const fetchMatchesPlayed = async () => {
      try {
        const targetAuthId = member.id === 'current-user' ? user?.id : member.id;
        if (!targetAuthId) return;

        setIsLoadingMatches(true);
        let retries = MAX_RETRIES;

        while (retries > 0) {
          try {
            const { data: userData, error: userError } = await supabase
              .from('users')
              .select('id, full_name')
              .eq('auth_id', targetAuthId)
              .single();

            if (userError) throw userError;

            const today = new Date().toISOString().split('T')[0];
            const { data, error: countError } = await supabase
              .from('activities')
              .select('id, venue_city')
              .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
              .lt('date', today)
              .eq('status', 'confirmed');

            if (countError) throw countError;

            console.log('Confirmed past matches:', data?.length);

            if (data) {
              setLocalMatchesPlayed(data.length);

              const uniqueCities = new Set<string>();
              data.forEach(activity => {
                if (activity.venue_city) {
                  const cityName = activity.venue_city.split(',')[0].trim();
                  uniqueCities.add(cityName);
                }
              });

              console.log('Unique Cities Played:', Array.from(uniqueCities));
              setCitiesPlayed(uniqueCities.size);
              setCitiesList(Array.from(uniqueCities));
            }
            break;
          } catch (err) {
            if (err instanceof Error && err.message.includes('Failed to fetch')) {
              retries--;
              if (retries > 0) {
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                continue;
              }
            }
            throw err;
          }
        }
      } catch (error) {
        console.error('Error fetching matches played:', error);
        setLocalMatchesPlayed(prev => prev);
      } finally {
        setIsLoadingMatches(false);
      }
    };

    fetchMatchesPlayed();
  }, [member.id, member.matchesPlayed, user?.id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <button
        onClick={handleMatchesClick}
        className={cn(
          "rounded-xl p-4 text-center",
          isLoadingMatches ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400",
          "transition-colors"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {member.id === 'current-user' ? profileMatchesPlayed || localMatchesPlayed : localMatchesPlayed}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Matches Played
        </div>
      </button>

      <button
        onClick={() => setIsModalOpen(true)}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {citiesPlayed}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Cities Played
        </div>
      </button>

      <CitiesPlayedModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cities={citiesList}
        memberName={member.name} // Ensure the full name appears in the modal
      />

      <button
        onClick={onShowConnections}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          isLoadingConnections ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {connectionCount}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Connections
        </div>
      </button>

      {/* ✅ Restored Rating Stats with Rating Number */}
      <button 
        onClick={onShowReviews}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className="flex items-center justify-center text-2xl font-bold text-gold-600 dark:text-gold-400">
          {member.rating}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className="text-sm mt-1 text-light-600 dark:text-dark-800">
          Rating
        </div>
      </button>
    </div>
  );
};

export default ProfileStats;
