import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import PostsTab from '../components/members/tabs/PostsTab';
import NetworkTab from '../components/members/tabs/NetworkTab';
import MemberConnections from '../components/members/MemberConnections';
import CitiesPlayedModal from '../components/members/CitiesPlayedModal';
import ReviewsSection from '../components/members/ReviewsSection';
import { ExclusiveMembership } from '../components/membership/ExclusiveMembership';
import ProfileHeader from '../components/members/profile/ProfileHeader';
import ProfileStats from '../components/members/profile/ProfileStats';
import ProfileActions from '../components/members/profile/ProfileActions';
import { supabase } from '../lib/supabase';
import { Member } from '../types/member';
import { Post } from '../types/post';
import MemberProfileSkeleton from '../components/members/MemberProfileSkeleton';
import { useConnectionStore } from '../store/connectionStore';
import RequestMatchModal from '../components/matchmaking/RequestMatchModal';

// Retry configuration
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

const MemberProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<'about' | 'posts' | 'ratings'>(
    (location.state as any)?.activeTab || 'posts'
  );
  const [showConnections, setShowConnections] = useState(false);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [memberPosts, setMemberPosts] = useState<Post[]>([]);
  const [member, setMember] = useState<Member | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cityImage, setCityImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [profile, setProfile] = useState<any>(null);
  const { connections, fetchConnections } = useConnectionStore();

  // Fetch connections when component mounts
  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        let retries = MAX_RETRIES;

        // Get current user session
        const { data: { session }, error: sessionError } = await fetchWithRetry(() => 
          supabase.auth.getSession()
        );

        if (sessionError) throw sessionError;
        if (!session?.user) throw new Error('Not authenticated');

        // Fetch member profile
       const { data: userData, error: userError } = await fetchWithRetry(() => supabase
  .from('users')
  .select(`
    id,
    auth_id,
    full_name,
    avatar_url,
    city,
    skill_level,
    company,
    job_type,
    facebook_url,
    linkedin_url,
    instagram_url,
    user_sports (
      sports (
        name,
        emoji
      )
    )
  `)
  .eq('auth_id', id)
  .single());


        if (userError) throw userError;
        if (!userData) throw new Error('Member not found');
        
        // Store profile data separately
        setProfile(userData);

        // Get connection count
        const { count, error: countError } = await fetchWithRetry(() => supabase
          .from('connections')
          .select('*', { count: 'exact', head: true })
          .eq('status', 'accepted')
          .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`));

        if (countError) throw countError;

        // Transform user data to Member type
        const transformedMember: Member = {
          id: userData.auth_id,
          name: userData.full_name,
          image: userData.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          city: userData.city,
          sports: userData.user_sports?.map(us => us.sports.name) || [],
          jobTitle: userData.job_type || 'Role',
          company: userData.company || 'Company',
          connections: count || 0,
          matchesPlayed: 0,
          citiesPlayed: [],
          achievements: [],
          rating: userData.user_profiles?.[0]?.preferences?.rating || 4.5,
          skillLevel: userData.skill_level || 'intermediate',
          availability: ['morning', 'afternoon', 'evening'],
          age: userData.user_profiles?.[0]?.preferences?.age || 0,
          industry: userData.user_profiles?.[0]?.preferences?.industry || 'Finance',
          gender: userData.user_profiles?.[0]?.preferences?.gender || 'rather_not_say',
          isConnected: false, // You might want to implement connections feature
          joinDate: userData.created_at || new Date().toISOString(),
          points: {
            matches: 0,
            training: 0,
            connections: 0,
            cities: 0,
            events: 0,
            achievements: 0,
            total: 0
          }
        };

        setMember(transformedMember);

        // Fetch member's posts
        const { data: postsData, error: postsError } = await fetchWithRetry(() => supabase
          .from('posts')
          .select(`
            id,
            content,
            media_url,
            likes_count,
            comments_count,
            shares_count,
            created_at,
            users (
              id,
              auth_id,
              full_name,
              avatar_url,
              city
            )
          `)
          .eq('user_id', userData.id)
          .order('created_at', { ascending: false }));

        if (postsError) throw postsError;

        const transformedPosts: Post[] = postsData.map(post => ({
          id: post.id,
          author: {
            id: post.users.auth_id,
            name: post.users.full_name,
            image: post.users.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
            location: post.users.city
          },
          content: post.content,
          media: post.media_url,
          likes: post.likes_count,
          comments: post.comments_count,
          shares: post.shares_count,
          timeAgo: post.created_at
        }));

        setMemberPosts(transformedPosts);
      } catch (error) {
        console.error('Error fetching member data:', error);
        setError(error instanceof Error ? error.message : 'Failed to load member profile');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchMemberData();
    }
  }, [id]);

  useEffect(() => {
    if ((location.state as any)?.activeTab) {
      setActiveTab((location.state as any).activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id]);

  // Fetch city image from Pexels
  useEffect(() => {
    if (member?.city) {
      const fetchCityImage = async () => {
        // Keywords to ensure we get cityscapes
        const CITYSCAPE_KEYWORDS = [
          'cityscape',
          'skyline',
          'architecture',
          'landmarks'
        ].join(' ');

        // Keywords to exclude from results
        const EXCLUDE_KEYWORDS = [
          'person',
          'people',
          'crowd',
          'portrait',
          'selfie'
        ].join(' -');

        const NUM_IMAGES = 5;
        const RESULTS_PER_PAGE = 15; // Get more results to ensure we have enough good cityscapes

        try {
          const response = await fetchWithRetry(() =>
            fetch(
              `https://api.pexels.com/v1/search?query=${encodeURIComponent(member.city)} ${CITYSCAPE_KEYWORDS} -${EXCLUDE_KEYWORDS}&per_page=${RESULTS_PER_PAGE}&orientation=landscape&size=large`,
              {
                headers: { 
                  Authorization: import.meta.env.VITE_PEXELS_API_KEY || '',
                  'Content-Type': 'application/json'
                }
              }
            )
          );

          if (!response.ok) throw new Error('Failed to fetch image');
          
          const data = await response.json();
          if (data.photos && data.photos.length > 0) {
            // Filter photos to find all good cityscapes
            const cityscapes = data.photos.filter(photo => {
              const alt = photo.alt?.toLowerCase() || '';
              const hasGoodKeywords = CITYSCAPE_KEYWORDS.split(' ')
                .some(keyword => alt.includes(keyword));
              const hasBadKeywords = EXCLUDE_KEYWORDS.split(' -')
                .some(keyword => alt.includes(keyword));
              
              return hasGoodKeywords && !hasBadKeywords;
            });
            
            // Get up to 5 unique cityscape images
            const selectedPhotos = cityscapes.length > 0 ? cityscapes : data.photos;
            const uniquePhotos = Array.from(new Set(selectedPhotos.map(p => p.src.large2x)));
            const validPhotos = uniquePhotos.slice(0, NUM_IMAGES);

            // Randomly select one image
            const randomIndex = Math.floor(Math.random() * validPhotos.length);
            const imageUrl = validPhotos[randomIndex];
            
            // Verify URL is valid
            if (imageUrl && imageUrl.startsWith('https://')) {
              setCityImage(imageUrl);
            } else {
              throw new Error('Invalid image URL received');
            }
          } else {
            // Default to a beautiful cityscape from Pexels if no city-specific image is found
            const fallbackImages = [
              'https://images.pexels.com/photos/2346216/pexels-photo-2346216.jpeg?auto=format&fit=crop&w=1600&q=80', // Night cityscape
              'https://images.pexels.com/photos/1519088/pexels-photo-1519088.jpeg?auto=format&fit=crop&w=1600&q=80', // City architecture
              'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=format&fit=crop&w=1600&q=80', // Aerial city view
              'https://images.pexels.com/photos/1538177/pexels-photo-1538177.jpeg?auto=format&fit=crop&w=1600&q=80', // Modern skyline
              'https://images.pexels.com/photos/1434580/pexels-photo-1434580.jpeg?auto=format&fit=crop&w=1600&q=80'  // Urban landscape
            ];
            const randomFallback = fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
            setCityImage(randomFallback);
          }
        } catch (error) {
          if (error instanceof Error && error.message.includes('Failed to fetch')) {
            console.warn('Network error fetching city image, using fallback');
          } else {
            console.error('Error fetching city image:', error);
          }
          // Use random fallback image on error
          const fallbackImages = [
            'https://images.pexels.com/photos/2346216/pexels-photo-2346216.jpeg?auto=format&fit=crop&w=1600&q=80', // Night cityscape
            'https://images.pexels.com/photos/1519088/pexels-photo-1519088.jpeg?auto=format&fit=crop&w=1600&q=80', // City architecture
            'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=format&fit=crop&w=1600&q=80', // Aerial city view
            'https://images.pexels.com/photos/1538177/pexels-photo-1538177.jpeg?auto=format&fit=crop&w=1600&q=80', // Modern skyline
            'https://images.pexels.com/photos/1434580/pexels-photo-1434580.jpeg?auto=format&fit=crop&w=1600&q=80'  // Urban landscape
          ];
          const randomFallback = fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
          setCityImage(randomFallback);
        }
      };

      fetchCityImage();
    }
  }, [member?.city]);

  if (isLoading) {
    return (
      <DashboardLayout>
        <MemberProfileSkeleton />
      </DashboardLayout>
    );
  }

  if (error || !member) {
    return (
      <DashboardLayout>
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
          <p>{error || 'Member not found'}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      </DashboardLayout>
    );
  }

  const handleMessageClick = () => {
    navigate('/dashboard/messages', {
      state: {
        activeChat: member.id,
        initialMessage: `Hi ${member.name}! I'd like to connect with you on Playclub.`,
        showMobileList: false
      }
    });
  };

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-dark-800 hover:text-white transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        {/* Profile Header */}
        <ProfileHeader 
          member={member} 
          profile={profile}
          cityImage={cityImage || 'https://images.pexels.com/photos/466685/pexels-photo-466685.jpeg?auto=compress&cs=tinysrgb&w=1600'} 
        />

        {/* Stats */}
        <ProfileStats
          member={member}
          onShowCities={() => setShowCitiesModal(true)}
          onShowConnections={() => setShowConnections(true)}
          onShowReviews={() => setActiveTab('ratings')}
        />

        {/* Actions */}
        <ProfileActions
          member={member}
          onRequestMatch={() => setShowMatchModal(true)}
          onMessage={handleMessageClick}
        />

        {/* Tabs */}
        <div className="flex items-center space-x-4 border-b border-dark-300 overflow-x-auto">
          <button
            onClick={() => setActiveTab('posts')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'posts'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Moments
          </button>
          <button
            onClick={() => setActiveTab('network')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'network'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Connections
          </button>
          <button
            onClick={() => setActiveTab('ratings')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'ratings'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Ratings
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'posts' && (
          <PostsTab 
            posts={memberPosts} 
            memberName={member.name}
            memberId={member.id}
          />
        )}

        {activeTab === 'network' && member && (
          <NetworkTab memberId={member.id} />
        )}

        {activeTab === 'ratings' && (
          <ReviewsSection
            memberId={member.id}
            memberName={member.name}
            sports={member.sports}
            rating={member.rating}
            reviewCount={member.isTrainer ? member.trainerInfo?.reviews || 0 : 0}
          />
        )}

        {/* Modals */}
        {showConnections && (
          <MemberConnections
            memberId={member.id}
            onClose={() => setShowConnections(false)}
          />
        )}

        {showCitiesModal && (
          <CitiesPlayedModal
            isOpen={true}
            cities={member.citiesPlayed}
            memberName={member.name}
            onClose={() => setShowCitiesModal(false)}
          />
        )}

        {showMatchModal && (
          <RequestMatchModal
            isOpen={showMatchModal}
            onClose={() => setShowMatchModal(false)}
            member={{
              id: member.id,
              name: member.name,
              city: member.city,
              sports: member.sports
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default MemberProfile;