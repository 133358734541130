// City coordinates lookup
const cityCoordinates: Record<string, { lat: number; lng: number }> = {
  'Valencia': { lat: 39.4699, lng: -0.3763 },
  'Monte Carlo': { lat: 43.7384, lng: 7.4246 },
  'Nice': { lat: 43.7102, lng: 7.2620 },
  'Paris': { lat: 48.8566, lng: 2.3522 },
  'London': { lat: 51.5074, lng: -0.1278 },
  'Singapore': { lat: 1.3521, lng: 103.8198 },
  'Dubai': { lat: 25.2048, lng: 55.2708 },
  'Rome': { lat: 41.9028, lng: 12.4964 },
  'Barcelona': { lat: 41.3851, lng: 2.1734 },
  'Madrid': { lat: 40.4168, lng: -3.7038 },
  'Luxembourg': { lat: 49.6116, lng: 6.1319 },
  'Washington DC': { lat: 38.8977, lng: -77.0365 },
  'Vienna': { lat: 48.2082, lng: 16.3738 },
  'Zurich': { lat: 47.3769, lng: 8.5417 },
  'Geneva': { lat: 46.2044, lng: 6.1432 },
  'Frankfurt': { lat: 50.1109, lng: 8.6821 },
  'Milan': { lat: 45.4642, lng: 9.1900 },
  'Amsterdam': { lat: 52.3676, lng: 4.9041 },
  'Benicassim': { lat: 40.0575, lng: 0.0642 },
  'Tel Aviv': { lat: 32.0853, lng: 34.7818 }
};

export const calculateDistance = {
  // Get coordinates for a city
  getCityCoordinates: (city: string) => {
    return cityCoordinates[city];
  },

  // Calculate distance between two points using Haversine formula
  haversine: (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return Math.round(R * c); // Round to nearest kilometer
  }
};

export const getVenueCoordinates = (city: string) => {
  return cityCoordinates[city] || { lat: 0, lng: 0 };
};

// Calculate distance between two cities
export function calculateCityDistance(city1: string, city2: string): number {
  // Normalize city names for comparison
  const normalizeCity = (city: string) => {
    // Take only the part before the first comma and trim
    const cityName = city.split(',')[0].trim().toLowerCase();
    // Handle common variations
    if (cityName === 'tel aviv-yafo') return 'tel aviv';
    return cityName;
  };

  const city1Normalized = normalizeCity(city1);
  const city2Normalized = normalizeCity(city2);

  // If cities are the same, return 0 (same city)
  if (city1Normalized === city2Normalized) {
    return 0;
  }

  // Find coordinates, trying both normalized and original names
  const coords1 = cityCoordinates[city1Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city1Normalized)];
  const coords2 = cityCoordinates[city2Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city2Normalized)];

  if (!coords1 || !coords2) {
    console.warn(`Could not find coordinates for cities: ${city1Normalized} (${!!coords1}) and/or ${city2Normalized} (${!!coords2})`);
    return -1; // Return -1 to indicate distance calculation was not possible
  }

  return calculateDistance.haversine(
    coords1.lat,
    coords1.lng,
    coords2.lat,
    coords2.lng
  );
}