import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message, Conversation } from '../../../types/chat';
import Sidebar from './Sidebar';
import ChatWindow from '../ChatWindow';

interface ChatLayoutProps {
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  messageInput: string;
  setMessageInput: (value: string) => void;
  isTyping: boolean;
  onSendMessage: (receiverId: string) => void;
  onMarkAsRead: (messageId: string) => void;
  showMobileList: boolean;
  conversations: Conversation[];
  createConversation?: (connectionId: string) => Promise<{ id: string } | null>;
}

const ChatLayout: React.FC<ChatLayoutProps> = ({
  activeChat,
  onChatSelect,
  messages,
  isLoading,
  error,
  messageInput,
  setMessageInput,
  isTyping,
  onSendMessage,
  onMarkAsRead,
  showMobileList: propShowMobileList,
  conversations,
  createConversation
}) => {
  const navigate = useNavigate();
  // We maintain showMobileList internally (depending on window size)
  const [showMobileList, setShowMobileList] = useState(propShowMobileList);

  // Use activeChat from props
  const activeConversation = conversations?.find(c => c.id === activeChat);

  // Debug logging
  useEffect(() => {
    console.log('ChatLayout:', {
      componentName: 'ChatLayout',
      messages,
      activeChat,
      activeConversation,
      isLoading,
      error
    });
  }, [messages, activeChat, activeConversation, isLoading, error]);

  // Adjust showMobileList based on screen size when activeChat changes
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setShowMobileList(isMobile && !activeChat);
  }, [activeChat]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      if (isMobile && !activeChat) {
        setShowMobileList(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeChat]);

  const handleChatSelect = useCallback(
    (chatId: string) => {
      onChatSelect(chatId);
      if (window.innerWidth < 768) {
        setShowMobileList(false);
      }
    },
    [onChatSelect]
  );

  if (error) {
    return (
      <div className="fixed inset-x-0 bottom-16 top-16 lg:left-64 flex items-center justify-center">
        <div className="text-red-400 bg-dark-200 rounded-xl p-6 border border-dark-300">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-x-0 bottom-16 top-16 lg:left-64 flex">
      {/* Left Sidebar */}
      <div
        className={`w-full md:w-[380px] md:flex-shrink-0 flex flex-col border-r border-dark-300 ${
          !showMobileList ? 'hidden md:flex' : ''
        }`}
      >
        <Sidebar
          activeChat={activeChat}
          onChatSelect={handleChatSelect}
          conversations={conversations}
          createConversation={createConversation}
        />
      </div>

      {/* Right Chat Area */}
      <div className={`flex-1 ${showMobileList ? 'hidden md:block' : ''}`}>
        {activeChat ? (
          <ChatWindow
            conversation={activeConversation}
            messages={messages}
            isLoading={isLoading}
            error={error}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            isTyping={isTyping}
            onSendMessage={onSendMessage}
            onMarkAsRead={onMarkAsRead}
            onBack={() => setShowMobileList(true)}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-dark-800">
            Select a conversation to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatLayout;
