import { ProfileFormData } from '../types';

export interface ValidationErrors {
  fullName?: string;
  email?: string;
  company?: string;
  jobTitle?: string;
  dateOfBirth?: string;
  gender?: string;
  nationality?: string;
  city?: string;
  phone?: string;
}

export function validateProfileForm(data: ProfileFormData): ValidationErrors {
  const errors: ValidationErrors = {};

  // Required fields
  if (!data.fullName?.trim()) {
    errors.fullName = 'Full name is required';
  }

  if (!data.email?.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!data.company?.trim()) {
    errors.company = 'Company is required';
  }

  if (!data.jobTitle?.trim()) {
    errors.jobTitle = 'Role is required';
  }

  if (!data.company?.trim()) {
    errors.company = 'Company is required';
  }

  if (!data.jobTitle?.trim()) {
    errors.jobTitle = 'Job title is required';
  }

  if (!data.dateOfBirth) {
    errors.dateOfBirth = 'Date of birth is required';
  } else {
    const age = new Date().getFullYear() - new Date(data.dateOfBirth).getFullYear();
    if (age < 18) {
      errors.dateOfBirth = 'You must be at least 18 years old';
    }
  }

  if (!data.gender) {
    errors.gender = 'Gender is required';
  }

  if (!data.nationality) {
    errors.nationality = 'Nationality is required';
  }

  if (!data.city) {
    errors.city = 'City is required';
  }

  if (!data.phone) {
    errors.phone = 'Phone number is required';
  }

  return errors;
}