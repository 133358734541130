import { supabase } from '../supabase';
import { Activity } from '../../types/activity';
import { activitiesCache } from '../cache/instances';
import { clearActivitiesCache } from '../cache/activities';
import { isValidUrl } from '../utils/url';
import { avatarCache } from '../utils/avatarCache';

// Export functions individually
export { getActivities };
export { createActivity };
export { updateActivityStatus };
export { updateActivityVenue };
export { acceptActivity };
export { declineActivity };
export { subscribeToActivities };

// Performance monitoring
const PERFORMANCE_DEBUG = true;
const logPerformance = (label: string, startTime: number) => {
  if (PERFORMANCE_DEBUG) {
    const duration = Date.now() - startTime;
    console.log(`🕒 ${label}: ${duration}ms`);
  }
};

// Cache configuration
const CACHE_DURATION = 60 * 1000; // 1 minute
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const BATCH_SIZE = 10;
const INITIAL_BATCH_SIZE = 5;
const STALE_WHILE_REVALIDATE = true; 
const MAX_NETWORK_RETRIES = 3;
const NETWORK_TIMEOUT = 5000;

// Minimal fields for initial fast load
const INITIAL_FIELDS = `
  id,
  sport,
  date,
  time,
  venue_name,
  venue_address,
  venue_city,
  status,
  created_at,
  player_1:player_1_id(
    id,
    auth_id,
    full_name
  ),
  player_2:player_2_id(
    id,
    auth_id,
    full_name
  )
`;

// Full fields for subsequent loads
const FULL_FIELDS = `
  *,
  player_1:player_1_id(
    id,
    auth_id,
    full_name,
    avatar_url
  ),
  player_2:player_2_id(
    id,
    auth_id,
    full_name,
    avatar_url
  )
`;

// Memory cache for activities
const activitiesCache = new Map<string, {
  data: Activity[];
  timestamp: number;
  isStale: boolean;
}>();

// Cache management
function getCachedActivities(userId: string): Activity[] | null {
  const cached = activitiesCache.get(userId);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  if (STALE_WHILE_REVALIDATE && age < CACHE_DURATION * 2) {
    cached.isStale = true;
    return cached.data;
  }

  activitiesCache.delete(userId);
  return null;
}

function setCachedActivities(userId: string, data: Activity[]) {
  activitiesCache.set(userId, {
    data,
    timestamp: Date.now(),
    isStale: false
  });
}

// Utility functions
function isValidTime(time: string): boolean {
  return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1,
  timeout = NETWORK_TIMEOUT
): Promise<T> {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    const result = await Promise.race([
      operation(),
      new Promise<never>((_, reject) => {
        controller.signal.addEventListener('abort', () => {
          reject(new Error('Request timeout'));
        });
      })
    ]);

    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    const isNetworkError = error instanceof Error && 
      (error.message.toLowerCase().includes('failed to fetch') ||
       error.message.toLowerCase().includes('network') ||
       error.message.toLowerCase().includes('timeout'));
    
    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      console.log(`Network error, retrying in ${delay}ms... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }

    // Return cached data if available on network error
    if (isNetworkError) {
      console.log('Network error, using cached data if available');
      return null as T;
    }

    throw error;
  }
}

// Transform activities helper function
async function transformActivities(activities: any[], userId: string): Promise<Activity[]> {
  if (!Array.isArray(activities)) {
    console.error('Invalid activities data:', activities);
    return [];
  }

  const transformedActivities = await Promise.all(activities.map(async activity => {
    try {
      // Validate required fields
      if (!activity?.id || !activity?.sport || !activity?.date || !activity?.time) {
        console.error('Missing required activity data:', activity);
        return null; 
      }

      // Get player data
      const player1 = activity.player_1 || {};
      const player2 = activity.player_2 || {};
      
      if (!player1.id || !player2.id) {
        console.error('Missing player IDs:', { player1, player2 });
        return null;
      }

      // Determine current player and opponent
      const isPlayer1 = activity.player_1.id === userId;
      const opponent = isPlayer1 ? activity.player_2 : activity.player_1;
      
      // Get opponent details
      const opponentId = opponent.auth_id;
      const opponentName = opponent.full_name?.trim();

      if (!opponentId || !opponentName) {
        console.error('Missing opponent details:', opponent);
        return null;
      }

      // Get opponent's avatar URL
      let avatarUrl = avatarCache.getDefaultAvatar();
      try {
        // Check opponent data first
        if (opponent.avatar_url && isValidUrl(opponent.avatar_url)) {
          avatarUrl = opponent.avatar_url;
          avatarCache.setAvatar(opponentId, avatarUrl);
        } else {
          // Try to get from cache
          const cachedUrl = avatarCache.getAvatar(opponentId);
          if (cachedUrl !== avatarCache.getDefaultAvatar()) {
            avatarUrl = cachedUrl;
          } else {
            // Try to fetch from profile as last resort
            const { data: userData } = await supabase
              .from('users')
              .select('avatar_url')
              .eq('auth_id', opponentId)
              .single();

            if (userData?.avatar_url && isValidUrl(userData.avatar_url)) {
              avatarUrl = userData.avatar_url;
              avatarCache.setAvatar(opponentId, avatarUrl);
            }
          }
        }
      } catch (error) {
        console.warn('Error handling avatar URL:', error);
        avatarUrl = avatarCache.getDefaultAvatar();
      }
      
      return {
        id: activity.id,
        player1Id: activity.player_1.auth_id,
        rating_overall: parseFloat(activity.rating_overall) || undefined,
        rating_punctuality: activity.rating_punctuality,
        rating_sportsmanship: activity.rating_sportsmanship,
        rating_skill_match: activity.rating_skill_match,
        rating_communication: activity.rating_communication,
        rating_venue: activity.rating_venue,
        rating_feedback: activity.rating_feedback,
        rating_categories: activity.rating_categories,
        sport: activity.sport,
        opponent: {
          id: opponent.auth_id,
          name: opponent.full_name.trim(),
          image: avatarUrl
        },
        date: activity.date,
        time: activity.time,
        venue: {
          name: activity.venue_name,
          address: activity.venue_address,
          city: activity.venue_city
        },
        status: activity.status === 'pending' ? 'new_request' : activity.status,
        direction: isPlayer1 ? 'sent' : 'received'
      };
    } catch (error) {
      console.error('Error transforming activity:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        activity
      });
      return null;
    }
  }));

  return transformedActivities.filter((activity): activity is Activity => activity !== null);
}

// Transform raw database activity data into Activity type
async function transformActivity(data: any): Promise<Activity> {
  try {
    // For updates, we only need minimal activity data
    return {
      id: data.id,
      sport: data.sport,
      date: data.date,
      time: data.time,
      venue: {
        name: data.venue_name,
        address: data.venue_address,
        city: data.venue_city
      },
      status: data.status
    };
  } catch (error) {
    console.error('Error transforming activity:', error);
    throw error;
  }
}

// Activity management functions
async function createActivity(activity: Omit<Activity, 'id'>) {
  // Get current user
  const { data: { user }, error: userError } = await supabase.auth.getUser();
  if (userError) throw userError;
  if (!user) throw new Error('Not authenticated');

  // Validate time format
  if (!isValidTime(activity.time)) {
    throw new Error('Invalid time format. Please use HH:mm format (e.g., 14:00)');
  }

  // Get opponent's user record
  const { data: opponentData, error: opponentError } = await supabase
    .from('users')
    .select('id, auth_id, full_name')
    .eq('auth_id', activity.opponent.id)
    .single();

  if (opponentError) throw opponentError;
  if (!opponentData) throw new Error('Opponent not found');

  // Get current user's record
  const { data: currentUserData, error: currentUserError } = await supabase
    .from('users')
    .select('id, auth_id, full_name')
    .eq('auth_id', user.id)
    .single();

  if (currentUserError) throw currentUserError;
  if (!currentUserData) throw new Error('User not found');

  // Create activity
  const { data, error } = await supabase.from('activities')
    .insert([{
      player_1_id: currentUserData.id,
      player_2_id: opponentData.id,
      sport: activity.sport,
      date: activity.date,
      time: activity.time === 'any' ? '09:00' : activity.time,
      venue_name: activity.venue.name,
      venue_address: activity.venue.address,
      venue_city: activity.venue.city,
      status: 'new_request'
    }])
    .select()
    .single();

  if (error) throw error;

  // Clear activities cache after successful creation
  clearActivitiesCache();
  
  try {
    // Broadcast cache invalidation to other tabs
    window.localStorage.setItem('activities-cache-invalidated', Date.now().toString());
  } catch (err) {
    // Ignore localStorage errors
    console.warn('Failed to broadcast cache invalidation:', err);
  }

  return data;
}

async function acceptActivity(activityId: string) {
  let retries = MAX_RETRIES;
  
  try {
    // Get current user session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();

    if (sessionError || !session?.user) {
      console.error('Session error:', sessionError);
      return null;
    }

    // Get user's database ID
    const { data: userData, error: userDataError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id')
        .eq('auth_id', session.user.id)
        .single()
    );

    if (userDataError || !userData) {
      console.error('User data error:', userDataError);
      return null;
    }

    // Verify user is player 2 (receiver)
    const { data: activity, error: activityError } = await fetchWithRetry(() =>
      supabase
        .from('activities')
        .select('*')
        .eq('id', activityId)
        .single()
    );

    if (activityError || !activity) {
      console.error('Activity error:', activityError);
      return null;
    }

    // Verify user is the receiver
    if (activity.player_2_id !== userData.id) {
      console.error('User not authorized to accept activity');
      return null;
    }

    // Update activity status
    const { data, error } = await fetchWithRetry(() =>
      supabase
        .from('activities')
        .update({ 
          status: 'confirmed',
          direction: null // Clear direction after confirmation
        })
        .eq('id', activityId)
        .select()
        .single()
    );

    if (error) {
      console.error('Update error:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error accepting activity:', error);
    return null;
  }
}

async function declineActivity(activityId: string, reason?: string) {
  const { data: { user }, error: userError } = await supabase.auth.getUser();
  if (userError) throw userError;
  if (!user) throw new Error('Not authenticated');

  const updateData: { status: string; decline_reason?: string } = {
    status: 'declined'
  };

  if (reason) {
    updateData.decline_reason = reason;
  }

  const { data, error } = await supabase
    .from('activities')
    .update(updateData)
    .eq('id', activityId)
    .select()
    .single();

  if (error) throw error;
  return data;
}

async function updateActivityStatus(activityId: string, updates: Partial<Activity>): Promise<Activity> {
  const { data: { user }, error: userError } = await supabase.auth.getUser();
  if (userError) throw userError;
  if (!user) throw new Error('Not authenticated');

  // Transform updates to database format
  const dbUpdates: Record<string, any> = {};

  if (updates.status) {
    dbUpdates.status = updates.status;
  }

  if (updates.date) {
    dbUpdates.date = updates.date;
  }

  if (updates.time) {
    dbUpdates.time = updates.time;
  }

  if (updates.venue) {
    dbUpdates.venue_name = updates.venue.name;
    dbUpdates.venue_address = updates.venue.address;
    dbUpdates.venue_city = updates.venue.city;
  }

  const { data, error } = await supabase
    .from('activities')
    .update(dbUpdates)
    .eq('id', activityId)
    .select()
    .single();

  if (error) throw error;
  if (!data) throw new Error('Failed to update activity');

  // Clear cache after successful update
  clearActivitiesCache();

  // Return transformed Activity object
  return await transformActivity(data);
}

async function updateActivityVenue(activityId: string, venue: { name: string; address: string; city: string }) {
  const { data: { user }, error: userError } = await supabase.auth.getUser();
  if (userError) throw userError;
  if (!user) throw new Error('Not authenticated');

  const { data, error } = await supabase
    .from('activities')
    .update({
      venue_name: venue.name,
      venue_address: venue.address,
      venue_city: venue.city
    })
    .eq('id', activityId)
    .select()
    .single();

  if (error) throw error;
  return data;
}

async function getActivities(): Promise<Activity[]> {
  const totalStartTime = Date.now();
  const handleError = (error: unknown, context: string) => {
    console.error(`${context}:`, error);
    
    // Only return empty array for network errors
    if (error instanceof Error && (
      error.message === 'Failed to fetch'
    )) {
      return [];
    }
    
    throw error instanceof Error ? error : new Error('An unexpected error occurred');
  };

  try {
    // Get session first
    const sessionStartTime = Date.now();

    const { data: { session }, error: sessionError } = await fetchWithRetry(
      async () => {
        const result = await supabase.auth.getSession();
        if (!result.data.session) {
          throw new Error('No active session');
        }
        return result;
      },
      3
    );
    logPerformance('Get session', sessionStartTime);

    if (sessionError) {
      return handleError(sessionError, 'Session error');
    }

    if (!session.user) {
      console.log('No authenticated user');
      return [];
    }

    // Get user's database record
    const userStartTime = Date.now();
    const { data: userData, error: userDataError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id')
        .eq('auth_id', session.user.id)
        .single()
    );
    logPerformance('Get user data', userStartTime);

    if (userDataError || !userData) {
      console.log('User data error:', userDataError || 'User not found');
      return [];
    }

    // Initial fast load with minimal fields
    const initialBatchStartTime = Date.now();
    const { data: initialData, error: initialError } = await fetchWithRetry(() => supabase
      .from('activities')
      .select(`
        id,
        sport,
        date,
        time,
        venue_name,
        venue_address,
        venue_city,
        status,
        created_at,
        rating_overall,
        is_rated,
        player_1:player_1_id(
          id,
          auth_id,
          full_name
        ),
        player_2:player_2_id(
          id,
          auth_id,
          full_name
        )
      `)
      .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
      .order('created_at', { ascending: false })
      .limit(50) // Load more activities initially
    );
    logPerformance('Initial batch fetch', initialBatchStartTime);

    if (initialError) {
      return handleError(initialError, 'Initial activities fetch error');
    }

    // Transform initial data for immediate display
    const initialActivities = await transformActivities(initialData, userData.id);

    // Fallback to initial data if full fetch fails
    return initialActivities;

  } catch (error) {
    return handleError(error, 'Error in getActivities');
  }
}

export async function rateActivity(
  activityId: string,
  ratings: {
    overall: number;
    punctuality: number;
    sportsmanship: number;
    skillLevelMatch: number;
    communication: number;
    venue: number;
  },
  feedback?: string,
  categories?: {
    punctuality: boolean;
    sportsmanship: boolean;
    skillLevelMatch: boolean;
    communication: boolean;
  }
) {
  try {
    // Validate rating values
    Object.values(ratings).forEach(rating => {
      if (rating < 1 || rating > 5) {
        throw new Error('All ratings must be between 1 and 5');
      }
    });

    // Validate feedback length if provided
    if (feedback && feedback.length > 200) {
      throw new Error('Feedback must be less than 200 characters');
    }

    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Check if activity exists and is not already rated
    const { data: activity, error: activityError } = await supabase
      .from('activities')
      .select('is_rated, status')
      .eq('id', activityId)
      .single();

    if (activityError) throw activityError;
    if (!activity) throw new Error('Activity not found');
    if (activity.is_rated) throw new Error('Activity already rated');
    if (activity.status !== 'confirmed') {
      throw new Error('Only confirmed activities can be rated');
    }

    // Call the RPC function
    const { error } = await supabase.rpc('handle_activity_rating', {
      activity_id: activityId,
      rating_data: {
        ...ratings,
        feedback: feedback || null,
        categories: categories || null
      }
    });

    if (error) throw error;

    // Clear activities cache after rating
    clearActivitiesCache();

  } catch (error) {
    console.error('Failed to rate activity:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to rate activity: ${error.message}`);
    }
    throw error;
  }
}