import { supabase } from '../supabase';
import { Invite, InviteFormData } from '../../types/invite';

// Constants
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// Helper function for retrying failed requests
async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (
      retries > 0 &&
      error instanceof Error &&
      error.message.includes('Failed to fetch')
    ) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

async function getRemainingInvites(): Promise<number> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    const { data, error } = await supabase
      .from('invite_allocations')
      .select('remaining_invites')
      .eq('user_id', userData.id)
      .single();

    if (error) throw error;
    return data?.remaining_invites || 0;
  } catch (error) {
    console.error('Error getting remaining invites:', error);
    throw error;
  }
}

async function sendInvite(inviteData: InviteFormData[]): Promise<Invite[]> {
  try {
    if (!inviteData?.length) {
      throw new Error('No invite data provided');
    }

    // Validate all invites first
    inviteData.forEach((invite) => {
      if (!invite.name?.trim()) {
        throw new Error('Name is required for all invites');
      }
      if (!invite.email?.trim()) {
        throw new Error('Email is required for all invites');
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(invite.email)) {
        throw new Error(`Invalid email format: ${invite.email}`);
      }
    });

    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID and full name
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id, full_name')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    // Use the inviter's full name for the invite record
    const inviterName = userData.full_name;

    // Create all invites in a single transaction
    const { data, error } = await supabase
      .from('user_invites')
      .insert(
        inviteData.map((invite) => ({
          inviter_id: userData.id,
          inviter_full_name: userData.full_name, // Store the inviter's name
          name: invite.name,
          invitee_email: invite.inviteMethod === 'email' ? invite.email : null,
          phone_number: invite.inviteMethod === 'whatsapp' ? invite.phoneNumber : null,
          country_code: invite.inviteMethod === 'whatsapp' ? invite.countryCode : null,
          invite_method: invite.inviteMethod,
        }))
      )
      .select()
      .order('created_at', { ascending: false });

    if (error) {
      if (error.message.includes('remaining_invites')) {
        throw new Error('No remaining invites available');
      } else if (error.message.includes('duplicate key')) {
        throw new Error('One or more emails have already been invited');
      } else if (error.message.includes('violates check constraint')) {
        throw new Error('Invalid invite data provided');
      }
      throw error;
    }

    if (!data?.length) {
      throw new Error('Failed to create invites');
    }

    // Map each inserted invite correctly
    return data.map((invite) => ({
      id: invite.id,
      inviterId: invite.inviter_id,
      name: invite.name,
      inviteeEmail: invite.invitee_email,
      status: invite.status,
      createdAt: invite.created_at,
      expiresAt: invite.expires_at,
      acceptedAt: invite.accepted_at,
    }));
  } catch (error) {
    const message =
      error instanceof Error ? error.message : 'Failed to send invites';
    console.error('Error sending invites:', { error: message });
    throw error;
  }
}

async function getInvites(): Promise<Invite[]> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    const { data, error } = await supabase
      .from('user_invites')
      .select('*')
      .eq('inviter_id', userData.id)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map((invite) => ({
      id: invite.id,
      inviterId: invite.inviter_id,
      inviteeEmail: invite.invitee_email,
      phoneNumber: invite.phone_number,
      countryCode: invite.country_code,
      inviteMethod: invite.invite_method,
      status: invite.status,
      createdAt: invite.created_at,
      expiresAt: invite.expires_at,
      acceptedAt: invite.accepted_at,
    }));
  } catch (error) {
    console.error('Error getting invites:', error);
    throw error;
  }
}

function subscribeToInvites(callback: (invite: Invite) => void) {
  const subscription = supabase
    .channel('invite_changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'user_invites',
      },
      async (payload) => {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) return;

          // Get user's database ID
          const { data: userData } = await supabase
            .from('users')
            .select('id')
            .eq('auth_id', user.id)
            .single();

          if (!userData) return;

          // Only process invites where user is the inviter
          if (payload.new.inviter_id === userData.id) {
            callback({
              id: payload.new.id,
              inviterId: payload.new.inviter_id,
              inviteeEmail: payload.new.invitee_email,
              phoneNumber: payload.new.phone_number,
              countryCode: payload.new.country_code,
              inviteMethod: payload.new.invite_method,
              status: payload.new.status,
              createdAt: payload.new.created_at,
              expiresAt: payload.new.expires_at,
              acceptedAt: payload.new.accepted_at,
            });
          }
        } catch (error) {
          console.error('Error processing invite update:', error);
        }
      }
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}

// Export all functions
export {
  getRemainingInvites,
  sendInvite,
  getInvites,
  subscribeToInvites,
  fetchWithRetry,
};
