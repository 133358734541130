import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Connection, ConnectionStatus } from '@/types/connection';
import { supabase } from '@/lib/supabase';
import {
  getConnections,
  sendConnectionRequest,
  acceptConnectionRequest,
  declineConnectionRequest,
  cancelConnectionRequest,
  blockConnection,
  unblockConnection,
} from '@/lib/supabase/connections';

interface ConnectionStore {
  connections: Connection[];
  pendingRequests: Connection[];
  isLoading: boolean;
  error: string | null;

  fetchConnections: () => Promise<void>;
  addConnection: (connection: Connection) => void;
  updateConnectionStatus: (
    connectionId: string,
    status: ConnectionStatus
  ) => void;
  removeConnection: (connectionId: string) => void;
  sendRequest: (receiverId: string) => Promise<boolean>;
  acceptRequest: (connectionId: string) => Promise<boolean>;
  declineRequest: (connectionId: string) => Promise<boolean>;
  cancelRequest: (connectionId: string) => Promise<boolean>;
  blockUser: (connectionId: string) => Promise<boolean>;
  unblockUser: (connectionId: string) => Promise<boolean>;

  isConnected: (userId: string) => boolean;
}

export const useConnectionStore = create<ConnectionStore>()(
  devtools((set, get) => ({
    connections: [],
    pendingRequests: [],
    isLoading: false,
    error: null,

    fetchConnections: async () => {
      set({ isLoading: true, error: null });
      try {
        const data = await getConnections();
        set({
          connections: data,
          pendingRequests: data.filter((conn) => conn.status === 'pending'),
          isLoading: false,
        });
      } catch (error) {
        set({
          error:
            error instanceof Error
              ? error.message
              : 'Failed to fetch connections',
          isLoading: false,
        });
      }
    },

    addConnection: (connection) => {
      set((state) => ({
        connections: [...state.connections, connection],
      }));
    },

    updateConnectionStatus: (connectionId, status) => {
      set((state) => ({
        connections: state.connections.map((conn) =>
          conn.connection_id === connectionId ? { ...conn, status } : conn
        ),
      }));
    },

    removeConnection: (connectionId) => {
      set((state) => ({
        connections: state.connections.filter(
          (conn) => conn.connection_id !== connectionId
        ),
      }));
    },

    sendRequest: async (receiverId) => {
      try {
        const connection = await sendConnectionRequest(receiverId);
        if (connection) {
          get().addConnection(connection);
          return true;
        }
      } catch (error) {
        console.error('Error sending request:', error);
      }
      return false;
    },

    acceptRequest: async (connectionId) => {
      try {
        const success = await acceptConnectionRequest(connectionId);
        if (success) {
          // Update connection status locally
          get().updateConnectionStatus(connectionId, 'accepted');

          // Retrieve the connection record from local state
          const connection = get().connections.find(
            (conn) => conn.connection_id === connectionId
          );
          if (!connection) {
            console.error('Connection not found for id:', connectionId);
            return false;
          }

          // Use the RPC function to get or create a conversation between the two users
          const { data, error } = await supabase.rpc('get_or_create_conversation', {
            user1_id: connection.initiator_id,
            user2_id: connection.receiver_id,
          });

          if (error) {
            console.error('Error creating conversation via RPC:', error);
            return false;
          }

          console.log('Conversation created or retrieved successfully:', data);
          return true;
        }
      } catch (error) {
        console.error('Error accepting request:', error);
      }
      return false;
    },

    declineRequest: async (connectionId) => {
      try {
        const success = await declineConnectionRequest(connectionId);
        if (success) {
          get().removeConnection(connectionId);
          return true;
        }
      } catch (error) {
        console.error('Error declining request:', error);
      }
      return false;
    },

    cancelRequest: async (connectionId) => {
      try {
        const success = await cancelConnectionRequest(connectionId);
        if (success) {
          get().removeConnection(connectionId);
          return true;
        }
      } catch (error) {
        console.error('Error canceling request:', error);
      }
      return false;
    },

    blockUser: async (connectionId) => {
      try {
        const success = await blockConnection(connectionId);
        if (success) {
          get().updateConnectionStatus(connectionId, 'blocked');
          return true;
        }
      } catch (error) {
        console.error('Error blocking user:', error);
      }
      return false;
    },

    unblockUser: async (connectionId) => {
      try {
        const success = await unblockConnection(connectionId);
        if (success) {
          get().updateConnectionStatus(connectionId, 'accepted');
          return true;
        }
      } catch (error) {
        console.error('Error unblocking user:', error);
      }
      return false;
    },

    isConnected: (userId) => {
      return get().connections.some(
        (conn) => conn.initiator_id === userId || conn.receiver_id === userId
      );
    },
  }))
);
