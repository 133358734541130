import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ChatLayout from '../components/chat/layout/ChatLayout';
import { useChat } from '../hooks/useChat';

const Messages = () => {
  const location = useLocation();
  const locationState = location.state as { 
    activeChat?: string;
    initialMessage?: string;
    showMobileList?: boolean;
  };

  // Manage active conversation at the top level
  const [activeChat, setActiveChat] = useState<string | null>(
    locationState?.activeChat || null
  );

  const { 
    messages,
    isLoading,
    error,
    messageInput,
    setMessageInput,
    isTyping,
    handleSendMessage,
    handleMarkAsRead,
    conversations,
    createConversation,
    isInitialized
  } = useChat(activeChat);

  // Set initial message if provided
  useEffect(() => {
    if (locationState?.initialMessage) {
      setMessageInput(locationState.initialMessage);
    }
  }, [locationState?.initialMessage, setMessageInput]);

  // Handle sending message (pass conversation id from activeChat)
  const handleSend = useCallback(
    (conversationId: string) => {
      if (conversationId) {
        console.log('Sending message in conversation:', conversationId);
        handleSendMessage(conversationId);
      }
    },
    [handleSendMessage]
  );

  return (
    <DashboardLayout>
      <ChatLayout
        activeChat={activeChat}
        onChatSelect={setActiveChat}
        messages={messages}
        isLoading={isLoading}
        error={error}
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        isTyping={isTyping}
        onSendMessage={handleSend}
        onMarkAsRead={handleMarkAsRead}
        showMobileList={locationState?.showMobileList || false}
        conversations={conversations}
        createConversation={isInitialized ? createConversation : undefined}
      />
    </DashboardLayout>
  );
};

export default Messages;
