import { useState, useEffect } from "react";
import { VenueApiResult } from "../../../../types/venue";
import { getUserLocation, DEFAULT_LOCATION } from "../../../../lib/utils/location";
import { calculateDistance } from "../../../../lib/utils/coordinates";

export function useVenueDistance(
  venues: VenueApiResult[],
  referencePoint: { lat: number; lng: number } | null = null
) {
  const [distances, setDistances] = useState<Record<string, number>>({});

  useEffect(() => {
    let mounted = true;

    const calculateDistances = async () => {
      try {
        let userLat, userLng;

        if (referencePoint) {
          // Use provided reference point if available
          userLat = referencePoint.lat;
          userLng = referencePoint.lng;
        } else {
          // Check geolocation permission status first
          const permissionStatus = await navigator.permissions.query({ name: "geolocation" });

          if (permissionStatus.state === "granted") {
            // Only request location if permission is already granted
            const location = await getUserLocation();
            userLat = location.latitude;
            userLng = location.longitude;
          } else {
            // If permission is not granted, use default location
            userLat = DEFAULT_LOCATION.latitude;
            userLng = DEFAULT_LOCATION.longitude;
          }
        }

        if (!mounted) return;

        const newDistances: Record<string, number> = {};
        venues.forEach((venue) => {
          if (venue.coordinates?.lat && venue.coordinates?.lng) {
            const distance = calculateDistance(userLat, userLng, venue.coordinates.lat, venue.coordinates.lng);
            newDistances[venue.id] = distance;
          } else {
            newDistances[venue.id] = -1;
          }
        });

        setDistances(newDistances);
      } catch (error) {
        console.error("Error calculating distances:", error);
        const fallbackDistances = venues.reduce((acc, venue) => {
          acc[venue.id] = -1;
          return acc;
        }, {} as Record<string, number>);
        setDistances(fallbackDistances);
      }
    };

    calculateDistances();

    return () => {
      mounted = false;
    };
  }, [venues, referencePoint]);

  return distances;
}
