import React from 'react';
import { Avatar } from '../ui/avatar';
import { Star } from 'lucide-react';
import { Activity } from '../../types/activity';
import { cn } from '@/lib/utils';

interface ActivityHeaderProps {
  activity: Activity;
  isOwnProfile: boolean;
  onProfileClick: () => void;
}

const ActivityHeader: React.FC<ActivityHeaderProps> = ({
  activity,
  isOwnProfile,
  onProfileClick,
}) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center space-x-4">
      <div className="relative group">
        <div 
          onClick={onProfileClick}
          className="cursor-pointer"
        >
          <Avatar
            src={activity.opponent.image}
            alt={activity.opponent.name}
            size={96}
            className={cn(
              "border-2 border-dark-400 group-hover:border-gold-400",
              "transition-all duration-200"
            )}
          />
        </div>
        <div className="absolute -bottom-1 -right-1 w-8 h-8 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center text-xl">
          {activity.sport.split(' ')[0]}
        </div>
      </div>
      <div className="text-left">
        <button
          onClick={onProfileClick}
          className="text-white font-medium hover:text-gold-400 transition-colors text-left"
        >
          {activity.sport.split(' ')[1]} with {activity.opponent.name}
        </button>
      </div>
    </div>
    {activity.status === 'played' && activity.rating_overall && (
      <div className="flex items-center text-gold-400">
        <span className="text-lg font-medium mr-1">{activity.rating_overall.toFixed(1)}</span>
        <Star className="h-5 w-5 fill-current" />
      </div>
    )}
  </div>
);

export default ActivityHeader;