import React from 'react';
import { Star } from 'lucide-react';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface PlayerAvatarProps {
  image: string;
  name: string;
  rating: number;
  onClick: () => void;
}

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({ image, name, rating, onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex flex-col items-center gap-2">
      <button onClick={onClick} className="relative">
        <img
          src={image}
          alt={name}
          className={cn(
            "w-28 h-28 sm:w-32 sm:h-32 rounded-full object-cover border-2 transition-colors",
            "transform hover:scale-105 active:scale-95",
            "shadow-lg hover:shadow-xl",
            isDarkMode 
              ? "border-dark-400 hover:border-gold-400 shadow-dark-900/20" 
              : "border-gray-200 hover:border-gold-600 shadow-gray-900/10"
          )}
        />
      </button>
      <div className={cn(
        "flex items-center px-4 py-2 rounded-full transition-colors",
        "transform hover:scale-105",
        "shadow-md hover:shadow-lg mx-auto",
        isDarkMode 
          ? "bg-dark-300 hover:bg-dark-400" 
          : "bg-gray-100 hover:bg-gray-200"
      )}>
        <Star className="h-5 w-5 text-gold-400 mr-2" />
        <span className={isDarkMode ? "text-white" : "text-gray-900"}>
          {rating}
        </span>
      </div>
    </div>
  );
};

export default PlayerAvatar;