interface NavigationApp {
  name: string;
  getUrl: (coordinates: { lat: number; lng: number }, name: string, address: string) => string;
  icon: string;
}

export const navigationApps: NavigationApp[] = [
  {
    name: 'Google Maps',
    icon: '🗺️',
    getUrl: (coordinates, name, address) => 
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&destination_name=${encodeURIComponent(name)}`
  },
  {
    name: 'Apple Maps',
    icon: '🍎',
    getUrl: (coordinates, name, address) => 
      `maps://maps.apple.com/?daddr=${encodeURIComponent(address)}&dname=${encodeURIComponent(name)}`
  },
  {
    name: 'Waze',
    icon: '🚗',
    getUrl: (coordinates, name, address) => 
      `https://www.waze.com/ul?q=${encodeURIComponent(address)}&navigate=yes`
  }
];
