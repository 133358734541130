import React from 'react';
import type { Conversation } from '../../../types/chat';
import ConversationItem from './ConversationItem';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface ConversationListProps {
  conversations: Conversation[];
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
}

const ConversationList: React.FC<ConversationListProps> = ({
  conversations = [], // Provide default empty array
  activeChat,
  onChatSelect
}) => {
  const { isDarkMode } = useTheme();
  if (!Array.isArray(conversations)) {
    console.error('Conversations prop must be an array');
    return (
      <div className={cn(
        "text-center py-12",
        "rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-light-100 border-light-200"
      )}>
        <p className={cn(
          isDarkMode ? "text-dark-800" : "text-light-600"
        )}>
          Error loading conversations
        </p>
      </div>
    );
  }

  if (conversations.length === 0) {
    return (
      <div className={cn(
        "text-center py-12",
        "rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-light-100 border-light-200"
      )}>
        <p className={cn(
          isDarkMode ? "text-dark-800" : "text-light-600"
        )}>
          No conversations found
        </p>
      </div>
    );
  }

  return (
    <div className={cn(
      "divide-y",
      isDarkMode ? "divide-dark-300" : "divide-light-200",
      "divide-opacity-50"      // Reduce opacity for both modes
    )}>
      {conversations.map((conversation) => (
        <ConversationItem
          key={conversation.id}
          conversation={conversation}
          isActive={activeChat === conversation.id}
          onClick={() => onChatSelect(conversation.id)}
        />
      ))}
    </div>
  );
};

export default ConversationList;