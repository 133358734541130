import React, { useState, useEffect } from 'react';
import { Activity } from '../../../../types/activity';
import { DateSelector, TimeSelector, VenueSelector } from './index';
import { Button } from '../../../ui/button';
import { AlertCircle } from 'lucide-react';

interface ManageActivityFormProps {
  activity: Activity;
  onSubmit: (activity: Activity) => void;
  onCancel: () => void;
  onDelete: (reason: string) => void;
  isSubmitting?: boolean;
}

// Helper function to format time
const formatInitialTime = (time: string) => {
  if (!time) return '';
  // If time includes seconds, remove them
  if (time.includes(':')) {
    const [hours, minutes] = time.split(':');
    const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    return formattedTime;
  }
  return time;
};

interface FormData {
  date: string;
  time: string;
  venue: {
    name: string;
    address: string;
    city: string;
  };
}

const ManageActivityForm: React.FC<ManageActivityFormProps> = ({
  activity,
  onSubmit,
  onCancel,
  onDelete,
  isSubmitting = false
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    date: activity.date || '',
    time: formatInitialTime(activity.time) || '',
    venue: activity.venue
  });
  const [hasChanges, setHasChanges] = useState(false);

  // Track form changes
  useEffect(() => {
    const hasDateChanged = formData.date !== activity.date;
    const hasTimeChanged = formData.time !== activity.time;
    const hasVenueChanged = 
      formData.venue.name !== activity.venue.name ||
      formData.venue.address !== activity.venue.address ||
      formData.venue.city !== activity.venue.city;
    
    setHasChanges(hasDateChanged || hasTimeChanged || hasVenueChanged);
  }, [formData, activity]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Only send changed fields
    const updates: Partial<Activity> = {};
    
    if (formData.date !== activity.date) {
      updates.date = formData.date;
    }
    
    if (formData.time !== activity.time) {
      updates.time = formData.time;
    }
    
    if (formData.venue.name !== activity.venue.name ||
        formData.venue.address !== activity.venue.address ||
        formData.venue.city !== activity.venue.city) {
      updates.venue = formData.venue;
    }
    
    onSubmit(activity, updates);
  };

  const handleDeleteSubmit = () => {
    if (!deleteReason.trim()) {
      setDeleteError('Please provide a reason for deleting this activity');
      return;
    }
    onDelete(deleteReason.trim());
  };

  if (showDeleteConfirm) {
    return (
      <div className="space-y-6">
        <div className="flex items-center space-x-4 mb-6">
          <img
            src={activity.opponent.image}
            alt={activity.opponent.name}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h3 className="text-lg font-medium text-white">
              Delete Activity
            </h3>
            <p className="text-dark-800">
              {activity.sport} with {activity.opponent.name}
            </p>
          </div>
        </div>

        <div className="bg-red-500/10 p-4 rounded-lg border border-red-500/20">
          <p className="text-white mb-4">
            Are you sure you want to delete this activity? This action cannot be undone.
          </p>
          
          <div className="space-y-2">
            <label className="block text-dark-800">
              Reason for deletion (required)
            </label>
            <textarea
              value={deleteReason}
              onChange={(e) => {
                setDeleteReason(e.target.value);
                setDeleteError(null);
              }}
              className="w-full h-24 bg-dark-300 border border-dark-400 rounded-lg px-4 py-2 text-white placeholder-dark-800 resize-none focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder="Please explain why you're deleting this activity..."
            />
            {deleteError && (
              <div className="flex items-center text-red-500 text-sm">
                <AlertCircle className="h-4 w-4 mr-1" />
                {deleteError}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-3 pt-4">
          <Button
            type="button"
            onClick={() => setShowDeleteConfirm(false)}
            variant="outline"
            className="border-dark-400 text-white hover:bg-dark-400"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleDeleteSubmit}
            className="bg-red-500 hover:bg-red-600 text-white"
          >
            Confirm Delete
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Header with opponent info */}
      <div className="flex items-center space-x-4 mb-6">
        <img
          src={activity.opponent.image}
          alt={activity.opponent.name}
          className="w-12 h-12 rounded-full object-cover"
        />
        <h3 className="text-lg font-medium text-white">
          {activity.sport} with {activity.opponent.name}
        </h3>
      </div>

      {/* Form Fields */}
      <div className="space-y-4">
        {/* Date and Time Row */}
        <div className="grid grid-cols-2 gap-4">
          <DateSelector
            selectedDate={formData.date}
            onDateChange={(date) => setFormData(prev => ({ ...prev, date: date }))}
          />
          <TimeSelector
            selectedTime={formData.time}
            onTimeChange={(time) => setFormData(prev => ({ ...prev, time: time }))}
          />
        </div>

        {/* Venue */}
        <VenueSelector
          venue={formData.venue}
          onVenueChange={(venue) => setFormData(prev => ({ ...prev, venue }))}
          city={activity.venue.city}
        />
      </div>

      {/* Action Buttons */}
      <div className="flex items-center justify-between pt-4">
        <Button
          type="button"
          onClick={() => setShowDeleteConfirm(true)}
          className="bg-red-500 hover:bg-red-600 text-white"
          disabled={isSubmitting}
        >
          Delete Activity
        </Button>
        <div className="flex space-x-3">
          <Button
            type="button"
            onClick={onCancel}
            variant="outline"
            className="border-dark-400 text-white hover:bg-dark-400"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            disabled={isSubmitting || !hasChanges}
          >
            {isSubmitting ? 'Sending...' : 'Send Update'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ManageActivityForm;