import React, { useState, useEffect, useCallback } from 'react';
import { Search, X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  className?: string;
  inputClassName?: string;
  showClearButton?: boolean;
}

const DEBOUNCE_DELAY = 300; // 300ms delay for debounce

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, onChange, onClear, className, inputClassName, showClearButton = true, ...props }, ref) => {
    const [inputValue, setInputValue] = useState(value);

    // Debounce logic: update inputValue immediately, but call onChange with a delay
    const debouncedChange = useCallback(
      (val: string) => {
        const handler = setTimeout(() => {
          onChange(val);
        }, DEBOUNCE_DELAY);

        return () => clearTimeout(handler);
      },
      [onChange]
    );

    useEffect(() => {
      const debouncer = debouncedChange(inputValue);
      return () => debouncer(); // Cleanup on unmount or change
    }, [inputValue, debouncedChange]);

    return (
      <div className={cn('relative', className)}>
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
        <input
          ref={ref}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={cn(
            'w-full pl-10 pr-10 py-3 bg-transparent',
            'text-white placeholder-dark-800',
            'focus:outline-none focus:ring-2 focus:ring-gold-400',
            inputClassName
          )}
          {...props}
        />
        {showClearButton && inputValue && (
          <button
            type="button"
            onClick={() => {
              setInputValue('');
              onClear();
            }}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1 text-dark-800 hover:text-white rounded-full hover:bg-dark-400 transition-colors"
            aria-label="Clear search"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
