import React, { useRef } from 'react';
import { Camera } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ProfileImageProps {
  src: string;
  alt: string;
  size?: number;
  className?: string;
  onImageChange?: (file: File) => void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  src,
  alt,
  size = 128,
  className,
  onImageChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && onImageChange) {
      onImageChange(file);
    }
  };

  return (
    <div className="relative group">
      <img
        src={src}
        alt={alt}
        style={{ width: size, height: size }}
        className={cn(
          'rounded-full object-cover border-4 border-white',
          'group-hover:border-gold-400 transition-all duration-200',
          className
        )}
      />

      {onImageChange && (
        <>
          <label
            htmlFor="profile-image-upload"
            className={cn(
              'absolute bottom-0 right-0 p-2 rounded-full',
              'bg-gold-400 hover:bg-gold-500 cursor-pointer',
              'opacity-0 group-hover:opacity-100 transition-opacity',
              'transform translate-x-1/4 translate-y-1/4',
              'shadow-lg'
            )}
          >
            <Camera className="h-4 w-4 text-dark-50" />
          </label>

          <input
            ref={fileInputRef}
            id="profile-image-upload"
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleFileChange}
          />
        </>
      )}
    </div>
  );
};

export default ProfileImage;
