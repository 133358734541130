// Cache class definition
class Cache<T> {
  private cache: Map<string, { data: T; timestamp: number }>;
  private name: string;
  private readonly expiryTime = 24 * 60 * 60 * 1000; // 24 hours
  private readonly maxCacheSize = 100; // Maximum number of items to cache
  private readonly maxStorageSize = 2 * 1024 * 1024; // 2MB storage limit

  constructor(name: string) {
    this.cache = new Map();
    this.name = name;
    this.loadFromStorage();
  }

  get(key: string): T | null {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const now = Date.now();
    const age = now - cached.timestamp;

    if (age < this.expiryTime) {
      return cached.data;
    }

    this.cache.delete(key);
    return null;
  }

  set(key: string, data: T) {
    // Enforce cache size limit
    if (this.cache.size >= this.maxCacheSize) {
      const oldestKey = Array.from(this.cache.entries())
        .sort(([, a], [, b]) => a.timestamp - b.timestamp)[0][0];
      this.cache.delete(oldestKey);
    }

    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });

    this.saveToStorage();
  }

  delete(key: string) {
    this.cache.delete(key);
    this.saveToStorage();
  }

  clear() {
    this.cache.clear();
    try {
      localStorage.removeItem(this.storageKey);
    } catch (error) {
      console.warn('Failed to clear cache from storage:', error);
    }
  }

  private get storageKey() {
    return `playclub_${this.name}_cache`;
  }

  private loadFromStorage() {
    try {
      const stored = localStorage.getItem(this.storageKey);
      if (stored && stored.length < this.maxStorageSize) {
        const parsed = JSON.parse(stored);
        Object.entries(parsed).forEach(([key, value]) => {
          const entry = value as { data: T; timestamp: number };
          if (Date.now() - entry.timestamp < this.expiryTime) {
            this.cache.set(key, entry);
          }
        });
      }
    } catch (error) {
      console.warn('Failed to load cache from storage:', error);
    }
  }

  private saveToStorage() {
    try {
      const data = Object.fromEntries(this.cache.entries());
      const serialized = JSON.stringify(data);
      if (serialized.length < this.maxStorageSize) {
        localStorage.setItem(this.storageKey, serialized);
      }
    } catch (error) {
      console.warn('Failed to save cache to storage:', error);
    }
  }
}

// Cache instances
export const postsCache = new Cache<any[]>('posts');
export const playersCache = new Cache<any[]>('players');
export const activitiesCache = new Cache<any[]>('activities');
export const profileCache = new Cache<any>('profile');

// Cache configuration
export const CACHE_CONFIG = {
  duration: 60 * 1000, // 1 minute
  staleWhileRevalidate: true,
  retryAttempts: 3,
  retryDelay: 1000,
  networkTimeout: 5000
};

export { Cache }