import React from 'react';
import { CommandGroup, CommandItem } from '../ui/command';
import { Loader2 } from 'lucide-react';
import { SearchResults as SearchResultsType } from '../../types/search';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import SearchSuggestions from './SearchSuggestions';
import { useSearchStore } from '../../store/searchStore';
import SearchResultItem from './SearchResultItem';

interface SearchResultsProps {
  isLoading: boolean;
  query: string;
  results: SearchResultsType;
  hasSearched: boolean;
  onSelect: (type: string, id: string) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  isLoading,
  query,
  results,
  hasSearched,
  onSelect,
}) => {
  const { isDarkMode } = useTheme();
  const { handleSuggestionSelect } = useSearchStore();
  const hasResults = Object.values(results).some((arr) => arr.length > 0);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-6">
        <Loader2
          className={cn(
            'h-6 w-6 animate-spin',
            isDarkMode ? 'text-dark-800' : 'text-gray-400'
          )}
        />
      </div>
    );
  }

  if (!query) {
    return <SearchSuggestions onSelect={handleSuggestionSelect} />;
  }

  if (hasSearched && !hasResults) {
    return (
      <div
        className={cn(
          'py-6 text-center',
          isDarkMode ? 'text-dark-800' : 'text-gray-500'
        )}
      >
        No results found for "{query}"
      </div>
    );
  }

  return (
    <>
      {results.players.length > 0 && (
        <CommandGroup heading="Players">
          {results.players.map((player, index) => (
            <SearchResultItem
              key={player.id || `player-${index}`}
              item={player}
              type="player"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}

      {results.posts.length > 0 && (
        <CommandGroup heading="Posts">
          {results.posts.map((post, index) => (
            <SearchResultItem
              key={post.id || `post-${index}`}
              item={post}
              type="post"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}
    </>
  );
};
